import { Directive, HostListener, OnDestroy } from '@angular/core';
import { scrollToId } from '@shared/common';
import { AnchorType, pages } from '@shared/models/common';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { Subscription } from 'rxjs/internal/Subscription';

const {
  results, booking, confirmation,
  search, reservation, verification
} = pages;

@Directive()
export abstract class ComponentBase implements OnDestroy {
  constructor(protected readonly services: ComponentBaseService) {
    this._setPage();
  }

  page?: string;

  isOnSearchPage!: boolean;
  isOnSearchPageV1!: boolean;
  isOnSearchPageV2!: boolean;

  isOnBookingPage!: boolean;
  isOnBookingPageV1!: boolean;
  isOnBookingPageV2!: boolean;

  isOnConfirmationPage!: boolean;
  isOnConfirmationPageV1!: boolean;
  isOnConfirmationPageV2!: boolean;

  onDestroyHandler?: () => void;

  protected subscriptions: Subscription[] = [];

  get isBrowser() {
    return this.services.ssrHelper.isBrowser;
  }

  @HostListener('window:popstate', ['$event'])
  protected _onPopState() {
    this._setPage();
  }

  private _setPage() {
    const nav = this.services.navigation;
    this.page = nav.getCurrentPage();

    this.isOnSearchPageV1 = nav.isPage(search);
    this.isOnSearchPageV2 = nav.isPage(results);
    this.isOnSearchPage = this.isOnSearchPageV1 || this.isOnSearchPageV2;

    this.isOnBookingPageV1 = this.services.navigation.isPage(reservation)
    this.isOnBookingPageV2 = this.services.navigation.isPage(booking)
    this.isOnBookingPage = this.isOnBookingPageV1 || this.isOnBookingPageV2;

    this.isOnConfirmationPageV1 = this.services.navigation.isPage(verification);
    this.isOnConfirmationPageV2 = this.services.navigation.isPage(confirmation);
    this.isOnConfirmationPage = this.isOnConfirmationPageV1 || this.isOnConfirmationPageV2;
  }

  protected pushSub(...subs: (Subscription | undefined)[]) {
    this.subscriptions = [...this.subscriptions, ...subs.filter(sub => sub !== undefined) as Subscription[]];
  }

  protected getAnchorId(postfix: AnchorType, ...index: (number | string | undefined)[]) {
    return ['anchor', postfix, ...index]
      .filter(item => item !== null && item !== undefined && item !== '')
      .join('-');
  }

  protected scrollTo(postfix: AnchorType, ...index: (number | string | undefined)[]) {
    scrollToId({ anchorId: this.getAnchorId(postfix, ...index) });
  }

  protected scrollToWithDelay(delayMs: number, postfix: AnchorType, ...index: (number | string | undefined)[]) {
    setTimeout(() => this.scrollTo(postfix, ...index), delayMs);
  }

  protected executeTest(testAction: () => void, timeout = 2000) {
    if (this.services.tenant.isTestTenant && this.isBrowser && this.services.navigation.isTest) {
      return setTimeout(() => testAction(), timeout);
    }

    return undefined;
  }

  ngOnDestroy(): void {
    if (this.subscriptions?.length) {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    if (this.onDestroyHandler) {
      this.onDestroyHandler();
    }
  }
}
