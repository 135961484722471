import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { SharedModule } from '@shared/shared.module';
import { MaterialModule } from '../material/material.module';

import { MemberService } from './member.service';
import { MemberAuthService } from './member.auth.service';
import { MemberInterceptor } from './member.interceptor';

import { BookingEngineClient, MembershipClient } from '@shared/api/be-api.generated';

import { MemberInfoV2Component } from './components-v2/member-info/member-info.component';
import { MemberBasicV2Component } from './components-v2/profile/member-basic/member-basic.component';
import { MemberStartV2Component } from './components-v2/start/member-start/member-start.component';
import { MemberSignInV2Component } from './components-v2/start/member-signin/member-signin.component';
import { MemberLayoutV2Component } from './components-v2/member-layout/member-layout.component';
import { MemberContactV2Component } from './components-v2/profile/member-contact/member-contact.component';
import { MemberProfileV2Component } from './components-v2/profile/member-profile/member-profile.component';
import { MemberSupportV2Component } from './components-v2/member-support/member-support.component';
import { MemberRewardsV2Component } from './components-v2/member-rewards/member-rewards.component';
import { MemberBookingsV2Component } from './components-v2/member-bookings/member-bookings.component';
import { MemberRegisterV2Component } from './components-v2/start/member-register/member-register.component';
import { MemberActivityV2Component } from './components-v2/member-activity/member-activity.component';
import { TravellerProfileV2Component } from './components-v2/profile/traveller-profile/traveller-profile.component';

const components = [
  MemberInfoV2Component,
  MemberBasicV2Component,
  MemberStartV2Component,
  MemberSignInV2Component,
  MemberLayoutV2Component,
  MemberProfileV2Component,
  MemberContactV2Component,
  MemberSupportV2Component,
  MemberRewardsV2Component,
  MemberBookingsV2Component,
  MemberRegisterV2Component,
  MemberActivityV2Component,
  TravellerProfileV2Component,
];

@NgModule({
  declarations: components,
  imports: [
    SharedModule,
    MaterialModule
  ],
  providers: [
    MembershipClient,
    BookingEngineClient,
    MemberAuthService,
    MemberService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MemberInterceptor,
      multi: true
    }
  ],
  exports: components
})
export class MemberModule { }
