<ng-container *transloco="let t">
  @if(isSelectedPackage){
  <div class="cart-summary">
    <app-two-columns>
      <ng-template #leftColumn>
        @if (header){
        <ng-container [ngTemplateOutlet]="header"></ng-container>
        }
        <app-cart-viewer [hideHeader]="!!header"></app-cart-viewer>
        <div class="padding-less margin-top-minus hidden-tablet-min">
          <!--Fix design for tablet space between-->
        </div>
        <app-cart-total class="hidden-tablet-min"></app-cart-total>
        <app-booking-button [profile]="profile"
          class="hidden-tablet-min justify-center">
        </app-booking-button>
      </ng-template>
      <ng-template #rightColumn>
        <div class="flex-column gap">
          <app-cart-total></app-cart-total>
          <app-booking-button [profile]="profile"></app-booking-button>
        </div>
      </ng-template>
    </app-two-columns>
  </div>
  }
</ng-container>