<div class="root">
  <div class="circle"
    (touchmove)="handleTouchMove($event)"
    (mousemove)="handleMouseMove($event)"
    (touchstart)="disableAnimatedPointer()"
    (mousedown)="disableAnimatedPointer()"
    (touchend)="handleTouchEnd($event)"
    (mouseup)="enableAnimatedPointer()"
    (click)="handleClick($event)">
    <div class="pointer-container"
      [ngClass]="{
        'small-pointer': mode === '24h' && viewType === 'hours' && (formattedValue === 0 || formattedValue > 12),
        'animated-pointer': !touching
      }"
      [style.transform]="'rotate(' + angle + 'deg)'">
      <button mat-mini-fab [color]="color" class="inner-dot"></button>
      <mat-toolbar [color]="color" class="pointer">
        <button mat-mini-fab [color]="color" class="outer-dot"
          [ngClass]="{
            'outer-dot-odd': viewType === 'minutes' && formattedValue % 5 !== 0
          }">
          @if(viewType === 'minutes' && formattedValue % 5 !== 0){
          .
          }
        </button>
      </mat-toolbar>
    </div>

    @for(digit of minuteDots; track $index){
    <button class="number minute-dot"
      [ngClass]="{
          'selected': formattedValue === digit.display || (digit.display === 0 && formattedValue === 0),
          'disabled': !isAvailable(digit.display === 60 ? 0 : digit.display)
        }"
      [style.transform]="'translate(' + digit.translateX + 'px, ' + digit.translateY + 'px)'">
      @if(digit.display % 5 !== 0){
      .
      }
    </button>
    }

    @for(digit of numbers; track $index){
    <button class="number"
      [ngClass]="{
        'selected': formattedValue === digit.display || (digit.display === 60 && formattedValue === 0),
        'disabled': !isAvailable(digit.display === 60 ? 0 : digit.display)
      }"
      [style.transform]="'translate(' + digit.translateX + 'px, ' + digit.translateY + 'px)'">
      @if(viewType === 'minutes'){
      {{ digit.display === 60 ? '00' : digit.display }}
      }
      @else{
      {{ digit.display }}
      }
    </button>
    }

    @for(digit of secondaryNumbers; track $index){
    <button class="number small-number"
      [ngClass]="{
        'selected': formattedValue === digit.display || (digit.display === 24 && formattedValue === 0),
        'disabled': !isAvailable(digit.display === 24 ? 0 : digit.display)
      }"
      [style.transform]="'translate(' + digit.translateX + 'px, ' + digit.translateY + 'px)'">
      {{ digit.display === 24 ? '00' : digit.display }}
    </button>
    }
  </div>