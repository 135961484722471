<ng-container *transloco="let t">
  <div [ngClass]="['notification', classNames, reduceSpace ? 'gap-quarter' : 'gap']">
    <div class="notification-badge"
      [class.padding-left-half]="!reduceSpace"
      *ngIf="badge || iconName">
      <app-icon *ngIf="!badge && iconName"
        [circle]="iconCircle"
        [name]="iconName"
        [size]="iconSize"
        [stroked]="stroked"
        [filled]="filled">
      </app-icon>
      <ng-container *ngTemplateOutlet="badge"></ng-container>
    </div>
    <div class="notification-text"
      [class.three-dots-container]="isThreeDots">
      <ng-container *ngTemplateOutlet="content"></ng-container>
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>