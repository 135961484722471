<ng-container *transloco="let t">
  <ng-template #expandedToggle let-isSpaceBotton="isSpaceBotton">
    <div class="group-item"
      (click)="toggleIsItemsExpand()"
      *ngIf="!areAllAvailable">
      <div class="link"
        [class.space-bottom]="isSpaceBotton">
        {{ t(isItemsExpanded ? 'button.readLess': 'button.moreDetails') }}
      </div>
    </div>
  </ng-template>
  <div class="filter-group">
    <div class="group-header"
      [class.first]="isFirst">
      <div class="color-iconStrokeDefault">
        {{t([translateKeyPrefix, scope, 'header'] | join)}}
      </div>
      <div class="color-selected selected-item"
        [class.capitalized]="selectedItem.capitalized"
        *ngIf="selectedItem">
        &nbsp;
        {{t([translateKeyPrefix, scope, 'selected'] | join, { value: selectedItem.caption })}}
      </div>
    </div>
    <div class="group-items"
      *ngIf="!isCheckbox">
      <mat-radio-group
        [(ngModel)]="selectedItem"
        (change)="selectItem($event.value)">
        <mat-radio-button
          class="group-item"
          *ngFor="let item of visibleItems; trackBy: 'value' | createTrackBy"
          [value]="item">
          <div class="group-item-caption"
            [class.capitalized]="item.capitalized">
            {{item.caption}}
          </div>
        </mat-radio-button>
        <ng-container [ngTemplateOutlet]="expandedToggle"></ng-container>
      </mat-radio-group>
    </div>
    <div class="group-items"
      *ngIf="isCheckbox">
      <div class="group-item checkbox"
        (click)="selectItem(item)"
        *ngFor="let item of visibleItems; trackBy: 'captionKey' | createTrackBy">
        <app-icon
          name="checked"
          size="default"
          [class.active]="selectedItems?.includes(item)"
          class="select-button select-icon bc-none">
        </app-icon>
        <div class="group-item-caption"
          [class.capitalized]="item.capitalized">
          {{item.caption}}
        </div>
      </div>
      <ng-container [ngTemplateOutlet]="expandedToggle"></ng-container>
    </div>
  </div>
</ng-container>