import { ExistingFlights as ExistingFlights2, FlightsToBuy as FlightsToBuy2, GuestDetails, PaymentPolicy, ReservationRequest, Origin as ReservationRequestBookingOrigin, ScheduleItem } from '@shared/api/be-api.generated';
import { AddOnService } from '@shared/services/add-on.service';
import { PackageService } from '@shared/services/package.service';
import { SearchService } from '@shared/services/search/search.service';
import { TravelAgentService } from '@shared/services/travel-agent.service';

export interface LoyaltyInfo {
  loyaltyId: string;
  email: string;
  phone: string;
  marketingConsent: boolean;
}

export interface BuildReservationInput {
  travelAgent: TravelAgentService;
  addOn: AddOnService;
  packageService: PackageService;
  search: SearchService;
  adultsCount: number;
  childrenCount: number;
  membershipProvider?: string
}

export class ReservationInput {
  readonly bookingOrigin: ReservationRequestBookingOrigin = 0; // means reservation by BE
  activePolicy?: PaymentPolicy;
  adultDetails: Record<number, GuestDetails> = {};
  childDetails: Record<number, GuestDetails> = {};
  scheduleItems: ScheduleItem[] = [];
  customerNotes?: string;
  loyalty?: Partial<LoyaltyInfo>;
  existingFlights?: ExistingFlights2;

  setAdultDetails(adultNumber: number, newDetails: GuestDetails) {
    this.adultDetails[adultNumber] = new GuestDetails({
      ...this.adultDetails[adultNumber],
      ...newDetails
    });
  }

  setChildDetails(childNumber: number, newDetails: GuestDetails) {
    this.childDetails[childNumber] = new GuestDetails({
      ...this.childDetails[childNumber],
      ...newDetails
    });
  }

  setPaymentPolicy(activePolicy?: PaymentPolicy) {
    this.activePolicy = activePolicy;
  }

  setSchedule(scheduleItems: ScheduleItem[]) {
    this.scheduleItems = scheduleItems || []
  }

  setLoyalty(email?: string, loyaltyId?: string) {
    this.loyalty = { ...(this.loyalty || {}), email, loyaltyId };
  }

  setExistingFlight(input?: ExistingFlights2) {
    this.existingFlights = input && (input.inboundFlight?.airportTransferId || input.outboundFlight?.airportTransferId)
      ? input
      : undefined;
  }

  buildReservation({ addOn, packageService, search, travelAgent, adultsCount, childrenCount, membershipProvider }: BuildReservationInput, skipCheck = false) {
    const { adultDetails, childDetails, bookingOrigin, customerNotes, existingFlights } = this;
    const { paymentPolicyId } = this.activePolicy || {};
    const { loyaltyId, email, marketingConsent } = this.loyalty || {};

    const { travelAgentDetails } = travelAgent;
    const { toPurchase: addOns } = addOn;

    const { ratePlanType: { ratePlanTypeId = '' } = {}, resort: { resortId = '' } = {} } = packageService;
    const { roomType: { roomTypeId = '' } = {}, specialOffer: { specialOfferId } = {} } = packageService;
    const {
      flight: { itinerary } = {},
      inboundAirportTransfer: { id: inboundAirportTransferId } = {},
      outboundAirportTransfer: { id: outboundAirportTransferId } = {}
    } = packageService;

    const { state: { context: { fromDate, toDate, promoCode } }, isFlightSearchAllowed } = search;

    const flightsToBuy = isFlightSearchAllowed
      ? new FlightsToBuy2({
        itinerary,
        inboundAirportTransferId,
        outboundAirportTransferId
      })
      : undefined;

    const adults = Array.from({ length: adultsCount }, (_, index) => adultDetails[index + 1]);
    const children = Array.from({ length: childrenCount }, (_, index) => childDetails[index + 1]);
    let guests = [...adults, ...children];

    const isDateRangeSelected = fromDate && toDate;
    const isFlightVerified = !isFlightSearchAllowed || !!itinerary;
    const isPackageSelected = ratePlanTypeId && resortId && roomTypeId;
    const isPolicy = !!paymentPolicyId;

    const isValid = !guests.some(item => !item) && isDateRangeSelected && isFlightVerified && isPackageSelected && isPolicy;
    if (isValid || skipCheck) {
      // 1 here it's adult number (not index);
      const { firstName, middleName, lastName, phone } = adultDetails[1] || {};
      const name = [firstName, middleName, lastName].filter(item => item).join(' ');

      if (email) {
        guests.forEach(guest => {
          if (guest) {
            guest.email = email;
            guest.phone = phone;
          }
        });

        guests = guests.map(item => item || new GuestDetails());

        return new ReservationRequest({
          specialOccasions: undefined, // check this prop

          email, name, phone,
          guests, customerNotes, bookingOrigin,
          addOns, flightsToBuy, fromDate, toDate,
          ratePlanTypeId, resortId, roomTypeId, specialOfferId, paymentPolicyId,
          travelAgentDetails, promoCode, loyaltyId, marketingConsent, existingFlights,
          membershipProvider
        });
      } else if (!skipCheck) {
        throw new Error('Email is required, but it is missed');
      }
    }

    return undefined;
  }
}

export const verificationSections = ['guestDetails', 'flightInfo', 'protectVacation', 'paymentPlan', 'manageBooking'] as const;
export type ReservationVerificationSection = typeof verificationSections[number];
export interface ReservationVerificationStatus {
  isValid: boolean;
  isChecked: boolean;
  anchorId: string;
}

export type ReservationVerificationRecords = Record<ReservationVerificationSection, Partial<ReservationVerificationStatus> | undefined>;

export class ReservationVerification {
  records: ReservationVerificationRecords = {
    guestDetails: undefined,
    flightInfo: undefined,
    protectVacation: undefined,
    paymentPlan: undefined,
    manageBooking: undefined
  };

  get areAllChecked() {
    return Object.values(this.records)
      .reduce((result, data) => result && !!data?.isChecked, true)
  }

  get areAllValid() {
    return Object.values(this.records)
      .reduce((result, data) => result && !!data?.isValid, true)
  }

  getTopInvalidSection() {
    const sectionKey = verificationSections.find(sectionKey => !this.records[sectionKey]?.isValid && this.records[sectionKey]?.anchorId);
    return sectionKey ? this.records[sectionKey] : undefined;
  }
}
