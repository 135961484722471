import { SsrHelperService } from './ssr-helper.service';
import { NavigationService } from './navigation.service';
import { Injectable } from '@angular/core';
import { TenantService } from './tenant.service';

@Injectable()
export class ComponentBaseService {
  constructor(
    public readonly navigation: NavigationService,
    public readonly ssrHelper: SsrHelperService,
    public readonly tenant: TenantService,
  ) {
  }
}
