import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { BlockOverlayService } from '@shared/services/block-overlay.service';
import { SidebarService } from '@shared/services/sidebar/sidebar.service';
import { LoaderSection, SidebarPanelWidth as SidebarWidth, SidebarPosition } from '@shared/models/common';
import { DataService } from '@shared/services/data.service';
import { SearchService } from '@shared/services/search/search.service';
import { PackageService } from '@shared/services/package.service';
import { ssrAllowed } from '@shared/consts/process-variables';
import { merge } from 'rxjs';
import { ApiException } from '@shared/api/be-api.generated';
import { ComponentBase } from '@shared/base/component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { AddOnService } from '@shared/services/add-on.service';
import { scrollToId } from '@shared/common';
import { NavigationEnd, Router } from '@angular/router';
import { HTMLTagService } from '@shared/services/html-tag.service';
import { TranslocoService } from '@jsverse/transloco';
import { TenantService } from '@shared/services/tenant.service';
import { TrackingService } from '@shared/services/tracking.service';
import { Color } from '@shared/models/common';
import { MemberAuthService } from '@member/member.auth.service';

@Component({
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent extends ComponentBase implements AfterViewInit {
  @ViewChild('drawer') private _drawer!: MatDrawer;
  @ViewChild('account') account?: TemplateRef<unknown>;
  @ViewChild('cart') cart!: TemplateRef<unknown>;
  @ViewChild('hotelFilter') hotelFilter!: TemplateRef<unknown>;
  @ViewChild('member') member!: TemplateRef<unknown>;

  isPackagesInitialized = this._package.isInitialized;
  isLoading!: boolean;
  errorString? = '';

  position?: SidebarPosition;
  template?: TemplateRef<unknown>;
  sidebarWidth: SidebarWidth = 'default';
  sidebarBackgroundColor: Color = 'default';

  isAirportTransfer = false;
  isAddOns = false;

  cartHeaderPostfix: 'all' | 'addOns' | 'airportTransfer' | 'none' = 'none';

  currentRoute = '';
  isScrollRequired = false;

  get currentCartHeaderPostfix(): typeof this.cartHeaderPostfix {
    return this.isAddOns && this.isAirportTransfer
      ? 'all' : !this.isAddOns && this.isAirportTransfer
        ? 'airportTransfer' : this.isAddOns && !this.isAirportTransfer
          ? 'addOns'
          : 'none';
  }

  get isFlightSearchAllowed() {
    return this._search.isFlightSearchAllowed;
  }

  get loaderSection(): LoaderSection {
    return this.isOnSearchPage
      ? this._search.isFlightSearchAllowed
        ? 'flights'
        : 'accommodation'
      : this.isOnBookingPage
        ? 'booking'
        : this.isOnConfirmationPage
          ? 'confirmation'
          : 'accommodation';
  }

  resortLogo?: string;

  private _isTrackingSetuped = false;
  private _trackingSetupTimeout!: number;

  constructor(
    private readonly _htmlTag: HTMLTagService,
    private readonly _sidebar: SidebarService,
    private readonly _blockOverlay: BlockOverlayService,
    private readonly _data: DataService,
    private readonly _search: SearchService,
    private readonly _package: PackageService,
    private readonly _router: Router,
    private readonly _transloco: TranslocoService,
    private readonly _tracking: TrackingService,
    auth: MemberAuthService,
    tenant: TenantService,
    addOn: AddOnService,
    services: ComponentBaseService
  ) {
    super(services);

    this._setup();
    this.resortLogo = tenant.settings.logo;

    this.pushSub(
      _sidebar.context$.subscribe(context => {
        this.position = context.position;
        this.template = context.template;
        this.sidebarWidth = context.width || 'default';
        this.sidebarBackgroundColor = context.backgroundColor || 'default';
      }),
      _package.setWhenDataChanged(({ isAirportTransferSelected }) => {
        this.isAirportTransfer = isAirportTransferSelected;
        this.cartHeaderPostfix = this.currentCartHeaderPostfix;
        // this.resortLogo = resort?.logo?.thumbnailUrl;

        this._setupTracking();
      }),
      addOn.setWhenDataChanged(({ toPurchase }) => {
        this.isAddOns = !!toPurchase.length;
        this.cartHeaderPostfix = this.currentCartHeaderPostfix;
      }),
      auth.authChanged.subscribe(() => {
        if (this._search.isInitialized) {
          this._search.setMember(auth.profile?.memberId);
        } else {
          this._setupInitialization();
        }
      })
    );

    this._scrollToTopOnPageChanged();
  }

  ngAfterViewInit() {
    if (this.isBrowser) {
      this._setSidebar();
    }
  }

  private _setupTracking() {
    if (this.isBrowser && !this._isTrackingSetuped) {
      if (this._trackingSetupTimeout) {
        window.clearTimeout(this._trackingSetupTimeout);
      }

      if (!this._tracking.isInitialized) {
        this._trackingSetupTimeout = window.setTimeout(() => {
          this._tracking.setup();
          this._isTrackingSetuped = true;
        }, 1000);
      }
    }
  }

  private _setSidebar() {
    if (this._transloco.getTranslation().size) {
      const { cart, hotelFilter, account, member } = this;
      this._sidebar.setTemplate({ cart, hotelFilter, account, member });
      this._blockOverlay.setDrawer(() => this._drawer, () => this._sidebar.closeOnClickBackdrop());
    } else {
      setTimeout(() => this._setSidebar());
    }
  }

  private _setup() {
    this._setupInitialization();
    this._setupLoading();
    this._setupErrors();
  }

  private _setupInitialization() {
    if (this.services.ssrHelper.isBrowserOr(ssrAllowed.data)) {
      this.pushSub(
        this._data.initialize().subscribe(() => {
          if (this.services.ssrHelper.isBrowserOr(ssrAllowed.search)) {
            this._search.runInititalization();
            this._setFaviconLogo();
          }
        }),
        this._package.initialized.subscribe(result => {
          this.isPackagesInitialized = result;
          this.isLoading = false;
        })
      );
    }
  }

  private _setFaviconLogo() {
    if (this._data.values?.settings?.faviconUrl) {
      this._htmlTag.append({
        allowInSSR: true,
        element: 'link',
        attributes: {
          'rel': 'icon',
          'type': 'image/x-icon',
          'href': this._data.values.settings.faviconUrl
        }
      });
    }
  }

  private _setupLoading() {
    // need loading if we not on the server and data is not initialized
    // first case browser, data not initialized
    // second case is server and search not allowed
    this.isLoading = (this.isBrowser && !this.isPackagesInitialized)
      || (!this.isBrowser && !ssrAllowed.search());

    this.pushSub(
      this._search.loading$.subscribe(isLoading => {
        this.isLoading = isLoading || !this.isPackagesInitialized;
        this._tryScrollToTop();
      })
    );
  }

  private _setupErrors() {
    this.pushSub(
      merge(this._data.initilazeError, this._search.searchError)
        .subscribe(error => this._setError(error))
    );
  }

  private _setError(error: ApiException) {
    this.errorString = error ? JSON.stringify(error, undefined, 2) : undefined;
  }

  private _scrollToTopOnPageChanged() {
    this._router.events
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.isScrollRequired = !this.isLoading && this.currentRoute !== event.url.split('?')[0];
          this._tryScrollToTop();
          this.currentRoute = event.url.split('?')[0];
        }
      });
  }

  private _tryScrollToTop() {
    if (this.isScrollRequired && this.services.ssrHelper.isBrowser) {
      scrollToId({ anchorId: 'anchor-layoutTop', timeout: 0, behavior: 'auto' });
      this.isScrollRequired = false;
    }
  }
}
