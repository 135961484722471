import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { OverlayModule } from '@angular/cdk/overlay';

import { AppComponent } from './app/app.component';
import { LayoutComponent } from './layout/layout.component';
import { AccountModule } from '../account/account.module';
import { MemberModule } from '@member/member.module';
import { MaterialModule } from '../material/material.module';
import { GlobalBookingButtonComponent } from './global-booking-button/global-booking-button.component';

@NgModule({
  declarations: [
    LayoutComponent,
    AppComponent,
    GlobalBookingButtonComponent
  ],
  imports: [
    RouterModule,
    SharedModule,
    MemberModule,
    MaterialModule,
    OverlayModule,
    AccountModule
  ]
})
export class ComponentsModule { }
