import { Component, ElementRef, ViewChild } from '@angular/core';
import { SidebarLayoutMemberSubScope } from '@shared/components/layout/sidebar-layout/sidebar-layout.component';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { MemberService } from '@member/member.service';
import { SidebarService } from '@shared/services/sidebar/sidebar.service';
import { BlockOverlayService } from '@shared/services/block-overlay.service';
import { Color, pages, Size } from '@shared/models/common';
import { arrayToRecord } from '@shared/common';
import { memberMenuItemChange } from '@shared/consts/animations';
import { AnimationComponentBase } from '@shared/base/animation-component.base';
import { NavigationService } from '@shared/services/navigation.service';

const menu = ['profile', 'bookings', 'rewards', 'help'] as const;
export type MemberMenuItem = typeof menu[number];

const otherPages = ['activity'] as const;
export type OtherPageItem = typeof otherPages[number];

@Component({
  selector: 'app-member-layout-v2',
  templateUrl: './member-layout.component.html',
  styleUrls: ['./member-layout.component.scss'],
  animations: [memberMenuItemChange]
})
export class MemberLayoutV2Component extends AnimationComponentBase {
  constructor(
    private readonly _sidebar: SidebarService,
    private readonly _blockOverlay: BlockOverlayService,
    private readonly _member: MemberService,
    private readonly _navigation: NavigationService,
    service: ComponentBaseService,
  ) {
    super(service);

    this.initProfile();
    this.pushSub(
      _member.memberChanged.subscribe(() => this.initProfile())
    );
  }

  readonly memberMenu = menu;
  readonly memberMenuItems = arrayToRecord<MemberMenuItem>(menu);
  readonly otherPageItems = arrayToRecord<OtherPageItem>(otherPages);

  readonly iconSize: Size = 'big';
  readonly iconColor: Color = 'formControl';

  sidebarSubScope: SidebarLayoutMemberSubScope = 'login';
  selectedMenuItem?: MemberMenuItem | OtherPageItem;
  profile = this._member.profile;

  @ViewChild('backRow') backRow?: ElementRef;

  initProfile() {
    this.profile = this._member.profile;
    this.sidebarSubScope = this.profile ? 'menu' : 'login';
  }

  signOut() {
    this._member.signOut();
    if (!this.isOnSearchPage) {
      this._navigation.open(pages.results);
    }
  }

  setMenuItem(menuItem: MemberMenuItem | OtherPageItem) {
    const wasSelected = !!this.selectedMenuItem;
    if (!wasSelected) {
      this.selectedMenuItem = menuItem;
      this._sidebar.openMember('full', 'end', 'transparent');
      this.scrollToTop();
      this._animate();
    } else {
      setTimeout(() => {
        this._animate({
          type: 'default',
          onAnimatingStopped: () => {
            setTimeout(() => {
              this.selectedMenuItem = menuItem;
              if (!this.isAnimating) {
                this.isAnimating = true;
              }
            });
          }
        });
      });
    }
  }

  close() {
    this._blockOverlay.close();
  }

  scrollToTop() {
    (this.backRow?.nativeElement as HTMLElement)?.scrollIntoView({ behavior: 'smooth' });
  }
}
