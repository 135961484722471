import { createMachine } from 'xstate';
import { machines } from '../state/state.mapping';

type ThemeEvents =
  | { type: 'APPLY_SERVER_THEME', themeCss: string; }
  | { type: 'APPLY_BROWSER_THEME', themeCss: string; }
  | { type: 'CUSTOM_THEME_READY', themeCss: string; }
  | { type: 'THEME_NOT_FOUND'; };

interface ThemeContext {
  selectedTheme?: string;
}

const themeSM = createMachine({
  predictableActionArguments: false,
  id: machines.theme.id,
  tsTypes: {} as import('./theme.sm.typegen').Typegen0,
  schema: {
    events: {} as ThemeEvents,
    context: {} as ThemeContext
  },
  context: {},
  initial: 'idle',
  states: {
    idle: {
      on: {
        APPLY_SERVER_THEME: {
          target: 'applyingServerTheme',
          actions: ['tryLoadServerTheme']
        },
        APPLY_BROWSER_THEME: {
          target: 'loadingBrowserTheme',
          actions: ['tryLoadBrowserTheme']
        }
      }
    },
    applyingServerTheme: {
      on: {
        CUSTOM_THEME_READY: {
          target: 'customTheme',
          actions: ['setServerTheme']
        },
        THEME_NOT_FOUND: {
          target: 'defaultTheme',
          actions: ['setServerTheme']
        }
      }
    },
    loadingBrowserTheme: {
      on: {
        CUSTOM_THEME_READY: {
          target: 'customTheme'
        },
        THEME_NOT_FOUND: {
          target: 'defaultTheme'
        }
      }
    },
    customTheme: { type: 'final' },
    defaultTheme: { type: 'final' }
  }
});

export default themeSM;
