import { Component } from '@angular/core';
import { SearchService } from '@shared/services/search/search.service';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AgeGroup2 as AgeGroup, RatePlanType, Resort, Room, RoomType } from '@shared/api/be-api.generated';
import { PackageService } from '@shared/services/package.service';
import { ComponentBase } from '@shared/base/component.base';
import { defaultAdultsCount } from '@shared/consts/common';
import { SidebarService } from '@shared/services/sidebar/sidebar.service';
import { arrayFrom, getCountChildrenInAgePeriod, getDefaultRoom } from '@shared/common';
import { ComponentBaseService } from '@shared/services/component-base.service';

class RoomViewModel extends Room {
  public isChildrenVisible = false;
  public childrenInAgeGroup: Record<string, number> = {};
}

@Component({
  selector: 'app-hotel-filter',
  templateUrl: './hotel-filter.component.html',
  styleUrls: ['./hotel-filter.component.scss']
})
export class HotelFilterComponent extends ComponentBase {
  resort?: Resort;
  resorts: Resort[] = [];
  ageGroups: AgeGroup[] = [];
  rooms: RoomViewModel[] = [];

  ratePlanType?: RatePlanType;
  minimumAdults = defaultAdultsCount;
  maximumAdults = defaultAdultsCount;
  maximumChildren = 10;

  constructor(
    services: ComponentBaseService,
    private readonly _search: SearchService,
    private readonly _sidebar: SidebarService,
    private readonly _package: PackageService,
  ) {
    super(services);
    this.pushSub(
      this._package.setWhenDataChanged(({ ratePlanType, availableResorts, roomTypes, resort }) => {
        if (ratePlanType?.ratePlanTypeId !== this.ratePlanType?.ratePlanTypeId) {
          this.ratePlanType = ratePlanType;
          this.resorts = availableResorts;
        }

        this._update(resort, roomTypes);
      })
    );
  }

  //#region private
  private _update(resort: Resort | undefined, roomTypes?: RoomType[]) {
    this.resort = resort;
    this.ageGroups = this.resort?.ageGroups || [];
    this._setRoomsViewModel(this._search.state.context.rooms);
    this._setAdultsOccupancy(roomTypes || resort?.roomTypes || []);
  }

  private _setRoomsViewModel(rooms?: Room[]) {
    this.rooms = rooms?.map(room => this.createRoomViewModel(room)) || [];
  }

  private createRoomViewModel(room: Room) {
    const result = new RoomViewModel(room);
    result.isChildrenVisible = !!result.children?.length;
    result.childrenInAgeGroup = this._getChildrenCountByAgeGroups(result.children);

    return result;
  }

  private _getChildrenCountByAgeGroups(children?: number[]) {
    const result: Record<string, number> = {};
    this.ageGroups.forEach(ageGroup => {
      if (ageGroup.id) {
        result[ageGroup.id] = getCountChildrenInAgePeriod(children, ageGroup.agePeriod);
      }
    });

    return result;
  }

  private _setAdultsOccupancy(roomTypes: RoomType[]) {
    this.minimumAdults = Math.min(...roomTypes.map(rt => rt.minimumOccupancy || defaultAdultsCount));
    this.maximumAdults = Math.max(...roomTypes.map(rt => rt.maximumOccupancy || defaultAdultsCount));

    this.rooms.forEach(room => {
      room.adults = room.adults || defaultAdultsCount;
      if (room.adults > this.maximumAdults) {
        room.adults = this.maximumAdults;
      }
      if (room.adults < this.minimumAdults) {
        room.adults = this.minimumAdults;
      }
    });
  }

  private get updatedRooms(): Room[] {
    const ageGroupValues: Record<string, number> = {};
    this.ageGroups.map(ageGroup => {
      if (ageGroup.id) {
        ageGroupValues[ageGroup.id] = ageGroup.agePeriod?.toYear || ageGroup.agePeriod?.fromYear || 0;
      }
    });

    return this.rooms.map(({ childrenInAgeGroup, adults, id, isChildrenVisible }) => new Room({
      id,
      adults,
      children: isChildrenVisible
        ? Object.keys(childrenInAgeGroup)
          .map(ageGroupId => arrayFrom(childrenInAgeGroup[ageGroupId], _ => ageGroupValues[ageGroupId]))
          .reduce((c1, c2) => [...c1, ...c2], [])
        : []
    }));
  }
  //#endregion

  //#region ui handlers
  compareResort(r1: Resort, r2: Resort): boolean {
    return r1.resortId === r2.resortId;
  }

  resortChanged(event: MatSelectChange) {
    this._update(event.value);
  }

  setIsChildren(event: MatSlideToggleChange, room: RoomViewModel) {
    room.isChildrenVisible = event.checked;
    if (!room.isChildrenVisible) {
      room.children = [];
      room.childrenInAgeGroup = this._getChildrenCountByAgeGroups(room.children);
    }
  }

  addRoom() {
    this.rooms = [...(this.rooms || []), this.createRoomViewModel(getDefaultRoom(this.rooms?.length))];
  }

  removeRoom(room: RoomViewModel) {
    this.rooms = this.rooms?.filter(({ id }) => id !== room.id)
  }

  increaseAdults(room: RoomViewModel) {
    if (room.adults && room.adults < this.maximumAdults) {
      room.adults++;
    }
  }

  decreaseAdults(room: RoomViewModel) {
    if (room.adults && room.adults > this.minimumAdults) {
      room.adults--;
    }
  }

  increaseChildren(room: RoomViewModel, ageGroup: AgeGroup) {
    if (ageGroup.id) {
      const newValue = room.childrenInAgeGroup[ageGroup.id] + 1;
      if (newValue <= this.maximumChildren) {
        room.childrenInAgeGroup[ageGroup.id] = newValue;
      }
    }
  }

  decreaseChildren(room: RoomViewModel, ageGroup: AgeGroup) {
    if (ageGroup.id) {
      const newValue = room.childrenInAgeGroup[ageGroup.id] - 1;
      if (newValue >= 0) {
        room.childrenInAgeGroup[ageGroup.id] = newValue;
      }
    }
  }

  apply() {
    this._sidebar.close();
    this.resort && this._search.applyHotelFilter(this.resort, this.updatedRooms);
    !this.isOnSearchPage && this.services.navigation.openSearch();
  }
  //#endregion
}


