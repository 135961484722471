<ng-container *transloco="let t">
  <div class="flex-1">
    <app-sidebar-layout
      [scope]="currentScope">
      <ng-template #header
        *ngIf="isEditMode">
        <div class="flex-between gap padding">
          <div class="flex-start gap link"
            (click)="closeEditMode()">
            <app-icon name="previous"
              size="small"
              stroked="selected">
            </app-icon>
            <span class="capitalized link">{{t('button.back')}}</span>
          </div>
          <app-icon-button
            class="bs-right"
            stroked="iconInactive"
            buttonClass="close-button"
            (click)="closeSidebar()"
            name="close">
          </app-icon-button>
        </div>
      </ng-template>

      <ng-template #content>
        <div class="sidebar-scroll-container">
          <div class="flex-between gap padding-vertical padding-right"
            *ngIf="(isOnBookingPage || isOnConfirmationPage) && !isEditMode">
            <div class="flex-start gap link"
              (click)="backToSearch()">
              <app-icon
                name="previous"
                size="small"
                stroked="selected">
              </app-icon>
              {{t('page.search.open')}}
            </div>
          </div>
          <div class="flex-column full-size"
            [class.gap]="!isTravelAgentEditMode && !isPromoCodeEditMode">
            <app-account-layout-item
              *ngIf="isOnSearchPage || isOnBookingPage"
              [isEditMode]="isTravelAgentEditMode"
              [isHidden]="isEditMode && !isTravelAgentEditMode"
              (viewTextClick)="toggleEditTravelAgent()"
              [viewText]="travelAgent || t('page.account.caption.travelAgent')">
              <ng-template #editor>
                <app-travel-agent (cancelEdit)="toggleEditTravelAgent()"></app-travel-agent>
              </ng-template>
            </app-account-layout-item>
            <app-account-layout-item
              *ngIf="isOnSearchPage"
              [isEditMode]="isPromoCodeEditMode"
              [isHidden]="isEditMode && !isPromoCodeEditMode"
              (viewTextClick)="toggleEditPromoCode()"
              [viewText]="promoCode ? t('promoCode.applied', { value: promoCode }) : t('page.account.caption.promoCode')">
              <ng-template #editor>
                <app-promo-code (cancelEdit)="toggleEditPromoCode()"></app-promo-code>
              </ng-template>
            </app-account-layout-item>
          </div>
        </div>
      </ng-template>
    </app-sidebar-layout>
  </div>
</ng-container>