import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiException, IMembershipProfile, MembershipProfile } from '@shared/api/be-api.generated';
import { FormComponent } from '@shared/components/common/form/form.component';
import { SelectOption } from '@shared/models/common';
import { MemberService } from '@member/member.service';
import { dateFormat } from 'src/app/formats/date-time';

type ProfileField = keyof IMembershipProfile;

@Component({
  selector: 'app-member-basic-v2',
  templateUrl: './member-basic.component.html'
})
export class MemberBasicV2Component implements OnInit {
  constructor(
    private readonly _member: MemberService
  ) {
  }

  @Output() closed = new EventEmitter();
  @ViewChild('form') fc!: FormComponent<ProfileField>;
  dateFields: Partial<Record<ProfileField, boolean>> = { dob: true };
  valueFormats: Partial<Record<ProfileField, string>> = { dob: dateFormat.type1 };
  fieldsOptions: Partial<Record<ProfileField, SelectOption[]>> = {};
  fields: ProfileField[] = ['firstName', 'lastName', 'dob', 'remarks']
  required: Partial<Record<ProfileField, boolean>> = { firstName: true, lastName: true, dob: true };
  profile = this._member.profile;
  profileForm!: UntypedFormGroup;
  errorMessage?: string;
  isLoading = false;

  ngOnInit(): void {
    this.init();
  }

  init() {
    const formControls = this.fields
      .map(key => ({ [key]: this.getProfileFormControl(key) }))
      .reduce((item, all) => ({ ...item, ...all }), {});

    this.profileForm = new UntypedFormGroup(formControls);
  }

  getProfileFormControl(key: ProfileField) {
    const profile = this.profile || new MembershipProfile({});
    return new UntypedFormControl(profile[key], this.required[key] ? Validators.required : [])
  }

  save() {
    this.profileForm.markAllAsTouched();
    if (!this.isLoading && this.profileForm.valid) {
      this.isLoading = true;

      this._member.update(new MembershipProfile({ ...this.profile, ...this.fc.asObject }))
        .subscribe({
          next: () => this.close(),
          error: err => this.handleError(err)
        });
    }
  }

  close() {
    this.closed.emit();
  }

  handleError(err: ApiException): void {
    this.isLoading = false;
    this.errorMessage = this._member.getMembershipError(err);
  }
}
