import { HashMap } from '@jsverse/transloco';
import { AddOnToPurchase, AgeGroup as AgeGroup2, Airport, BookingEngineHtmlContentCode, Flight, FlightSearch, IAgeGroup2, Package, RatePlanType, Resort } from '@shared/api/be-api.generated';
import { Gender as GenderTypeValue, GenderAsArray } from '@shared/api/be-api.generated';
import { IAddOnToPurchase, PackageRequest, TravelAgentDetails2 } from '@shared/api/be-api.generated';
import { DateTime, Duration } from 'luxon';

export type WithId<T> = T & { id: string; }

export type RequireKeys<T extends object, K extends keyof T> = Required<Pick<T, K>> & Omit<T, K>;
export type RequireKeysOnly<T, K extends keyof T> = RequireKeys<Partial<T>, K>;

export type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];
export interface CarouselImage {
  image: string;
  thumbImage: string;
  alt?: string;
}

export interface FlightViewModel extends WithId<Flight> {
  layover?: Duration;
}

export type Color =
  | 'none'
  | 'transparent'

  | 'border'
  | 'default'
  | 'selected'
  | 'background'
  | 'highlighted'
  | 'emphasized'

  | 'header'
  | 'amenitiesBar'
  | 'searchBar'
  | 'formControl'
  | 'ratePlanType'
  | 'successText'
  | 'successBackground'
  | 'successInfoLabel'

  | 'textError'
  | 'textPrimary'
  | 'textInactive'
  | 'textFlightJourney'
  | 'textSuccessPrice'

  | 'iconFill'
  | 'iconStroke'
  | 'iconFlight'
  | 'iconInactive'

  | 'required'
  | 'notFound'

  | 'alertBackground'
  | 'alertBorder'
  | 'alertText'

  | 'warningBackground'
  | 'warningBorder'
  | 'warningText'

  | 'notificationBackground'
  | 'notificationBorder'
  | 'notificationIcon'

  | 'memberBannerBackground'
  | 'memberDefault'

  | 'modalBackground';

export type Size =
  | 'small'
  | 'default'
  | 'middle'
  | 'custom'
  | 'big'
  | 'huge';

export type Position =
  | 'center'
  | 'start'
  | 'end';

export type IconScope =
  | 'icons'
  | 'payments'
  | 'images';

export type ButtonType =
  | 'icon'
  | 'raised'
  | 'minifab';

export type IndentSize =
  | 'default'
  | 'half'
  | 'quarter'
  | 'double'

interface IAddOnToPurchaseViewModel extends IAddOnToPurchase {
  name?: string;
  addOnType?: AddonType;
  pricePerItem: number;
  from?: number;
  to?: number;
  categoryName?: string;
  categoryCode?: string;
}

export class AddOnToPurchaseViewModel extends AddOnToPurchase implements IAddOnToPurchaseViewModel {
  name?: string;
  pricePerItem!: number;
  addOnType?: AddonType;
  from?: number;
  to?: number;
  categoryName?: string;
  categoryCode?: string;

  constructor(input: IAddOnToPurchaseViewModel) {
    super(input);
    this.name = input.name;
    this.pricePerItem = input.pricePerItem;
    this.addOnType = input.addOnType;
    this.from = input.from;
    this.to = input.to;
    this.categoryName = input.categoryName;
    this.categoryCode = input.categoryCode
  }

  get priceTotal() {
    return (this.pricePerItem || 0) * (this.quantity || 0);
  }

  isWithAgeGroups() {
    return this.addOnType === AddonType.PerAgeGroup;
  }
}

interface IAgeGroupViewModel extends IAgeGroup2 {
  quantity: number;
  from: number;
  to: number;
}

export class AgeGroupViewModel extends AgeGroup2 {
  quantity!: number;
  from!: number;
  to!: number;

  constructor(input: IAgeGroupViewModel) {
    super(input);
    this.quantity = input.quantity;
    this.from = input.from;
    this.to = input.to;
  }
}

export interface AddOnAgeGroupQuantityChange {
  ageGroupId: string;
  quantity: number;
}

//per person per night
//per room per night
export const availablePriceFormats = ['total', 'pppn', 'prpn', 'totalRounded'] as const;
export type PriceFormat = typeof availablePriceFormats[number];
export type PriceFormatPlace = 'main' | 'mainFlights' | 'button'
export const defaultPriceFormat: PriceFormat = 'total';

export interface SearchQueryParams {
  request: PackageRequest;
  roomTypeId: string;
  sortType: PackagesSortType;
  specialOfferId?: string;
  flightId?: string;
  inboundAirportTransferId?: string;
  outboundAirportTransferId?: string;
  travelAgent?: TravelAgentDetails2;
  priceFormat?: PriceFormat;
  isTest?: boolean;
  addOns?: IQueryStringAddOn[];
  cartEmail?: string;
  accessToken?: string;
}

export interface IQueryStringAddOn {
  addOnId?: string;
  ageGroupId?: string;
  quantity: number;
}

export type AnchorType =
  | 'flight'
  | 'section'
  | 'guestDetailsError'
  | 'protectVacationError'
  | 'paymentPlanError'
  | 'manageBookingError'
  | 'bookingButton'
  | 'reviewBooking'
  | 'flightInfo'
  | 'flightInfoButton'
  | 'layoutTop';

export type Gender = 'male' | 'female';
export const genderValues: Record<Gender, GenderTypeValue> = {
  male: GenderAsArray[0],
  female: GenderAsArray[1]
};

export type SidebarPosition = 'start' | 'end';
export type SidebarPanelWidth = 'default' | 'full' | 'big';

export interface PaymentResultMessage {
  result: boolean
  amount?: number
  flightsIncluded: boolean
  recordLocator?: string
  bookingId: string
  message: string
}

const _pages = [
  'results', 'booking', 'confirmation', // results => booking => confirmation => results
  'search', 'reservation', 'verification' // search => reservation => verification => results
] as const;

export type Page = typeof _pages[number];

export const pages = _pages.reduce((all, current) =>
  ({ ...all, [current]: current }),
  {} as Record<Page, Page>);

export type PackagesSortType = 'asc' | 'desc';
export type PackagesSortOptions = Record<PackagesSortType, (p1: WithId<Package>, p2: WithId<Package>) => number>;

export type SelectOption = { label: string | number | undefined, value: number | string | undefined | DateTime, isDisabled?: boolean, isError?: boolean };

export interface FormAutocompleteOptionSelected<TField, TOption extends SelectOption> {
  field: TField;
  selected: TOption;
}

export interface AirportsBuilderInput {
  ratePlanType?: RatePlanType;
  flightSearch?: FlightSearch;
  airports?: Airport[];
  resort?: Resort;
}

export enum AddonType {
  PerRoom = 0,
  PerPerson = 1,
  PerAgeGroup = 2
}

export const htmlContentCodes = {
  safetyProtocols: 1 as BookingEngineHtmlContentCode,
  hotelierMessage: 2 as BookingEngineHtmlContentCode,
  topNotification: 3 as BookingEngineHtmlContentCode,
};

export type TranslocoInput = { key?: string, params?: HashMap };

export type LoaderSection
  = 'accommodation' // search without flights
  | 'flights' // search with flights
  | 'booking' // load booking page
  | 'reservation' // started reservation
  | 'confirmation' // loading confirmation page;

export enum ResortAmenityTypeEnum {
  Primary = 1,
  Secondary = 2
}

const screenWidthsArray = ['mobile', 'ipod', 'tablet', 'desktop'] as const;
export type ScreenWidth = typeof screenWidthsArray[number];
export const screenWidthsValues: Record<ScreenWidth, number> = { mobile: 440, ipod: 640, tablet: 768, desktop: 1280 };


export const screenWidths: Record<ScreenWidth, ScreenWidth> =
  screenWidthsArray.reduce((all, current) =>
    ({ ...all, [current]: current }),
    {} as Record<ScreenWidth, ScreenWidth>);


export const minWidth = (width: ScreenWidth, additional = 0) => `(min-width: ${screenWidthsValues[width] + additional}px)`;

export type UnavailabilityReasonCode = 'NoFlights' | 'NoAccommodation' | 'MinStayPolicy';
export type UnavailabilityReason = { code: UnavailabilityReasonCode; value?: string }
