<ng-container *transloco="let t">
  <div class="booking-summary flex">
    <div class="content-container">
      <app-confirmation-section
        #section
        [hasHeaderMargin]="false"
        scope="bookingSummary">
        <app-confirmation-section-record
          field="confirmationNumber"
          [scope]="section.scope"
          [isDivider]="{ top: true, bottom: true }">
          <div class="link-underlined">
            {{confirmationNumber}}
          </div>
        </app-confirmation-section-record>
        <app-confirmation-section-record
          field="bookingDate"
          [scope]="section.scope">
          {{bookingDate | appDate: 'type3'}}
        </app-confirmation-section-record>

        @if(loyaltyId){
        <app-confirmation-section-record
          field="membership"
          [scope]="section.scope">
          {{ loyaltyId }}
          @if(memberData?.memberLevel){
          <i>{{' - ' + (memberData?.memberLevel || '')}}</i>
          }
        </app-confirmation-section-record>
        }

        <app-confirmation-section-record
          #record
          [scope]="section.scope"
          *ngFor="let guest of guests; let i = index; trackBy: 'index' | createTrackBy">
          <div [ngClass]="record.htmlClasses.label"
            [style.min-width]="record.labelWidth">
            <ng-container *ngIf="guest.isChild">
              {{t([section.scope, 'child'] | join, { value: guest.childNumber, age: guest.age })}}
            </ng-container>
            <ng-container *ngIf="!guest.isChild">
              {{t([section.scope, (i === 0 ? 'mainAdult' : 'otherAdult')] | join, { value: guest.adultNumber })}}
            </ng-container>

          </div>
          <div [ngClass]="record.htmlClasses.value">
            <div class="flex-column gap">
              <span>{{guest.name}}</span>
              <a class="link-underlined"
                *ngIf="guest.email"
                [href]="'mailto: ' + guest.email">
                {{guest.email}}
              </a>
              <span *ngIf="i === 0">{{guest.phone}}</span>
            </div>
          </div>
        </app-confirmation-section-record>
        <app-confirmation-section-record
          field="ratePlanType"
          [scope]="section.scope">
          <app-info-label
            [text]="ratePlanType?.name"
            type="ratePlanType">
          </app-info-label>
        </app-confirmation-section-record>
        <app-confirmation-section-record
          field="tourOperator"
          [scope]="section.scope">
          {{organizationName}}
        </app-confirmation-section-record>
        <app-confirmation-section-record
          field="paymentPlan"
          [scope]="section.scope">
          {{paymentPlan}}
        </app-confirmation-section-record>
      </app-confirmation-section>
    </div>
  </div>
</ng-container>