<ng-container *transloco="let t">
  @if(transfers.length){
  <div class="airport-transfer-select">
    <div class="content-container">
      <div class="flex-column">
        <div class="section-header"
          [hidden]="hideHeader">
          <div class="section-header-text">
            <app-anchor type="section" index="3"></app-anchor>
            3. {{t('airportTransfer.header')}}
          </div>
          <app-section-toggler #toggler
            [isOnlyForTablet]="false">
          </app-section-toggler>
        </div>
        <div class="collapsable"
          [class.collapsed]="toggler.isHidden">
          <div class="section-subheader"
            hidden>
            <div class="section-subheader-text">
              {{t('airportTransfer.subheader')}}
            </div>
          </div>
          @if(isBrowser){
          <cdk-virtual-scroll-viewport
            [appendOnly]="false"
            class="card-options-container"
            orientation="horizontal"
            #options
            [itemSize]="itemSize">
            <ng-container
              *cdkVirtualFor="let selectConfig of selectConfigs; trackBy: 'id' | createTrackBy;">
              <app-select-option-v2
                #option
                [config]="selectConfig"
                [sidebarContext]="{ template, width: 'default', position: 'end' }">
                <ng-template #footer>
                  <app-pricing-details-v2
                    [config]="pricingConfigs[selectConfig.id]"
                    (selectClick)="option.openDetails()">
                    <app-airport-transfer-pricing
                      [current]="sortedTransfers[selectConfig.id]">
                    </app-airport-transfer-pricing>
                  </app-pricing-details-v2>
                </ng-template>
              </app-select-option-v2>
              <ng-template #template>
                <app-airport-transfer-details
                  [pricingConfig]="pricingConfigs[selectConfig.id]"
                  [current]="sortedTransfers[selectConfig.id]"
                  [images]="selectConfig.images">
                </app-airport-transfer-details>
              </ng-template>
            </ng-container>
          </cdk-virtual-scroll-viewport>
          }
        </div>
      </div>
    </div>
  </div>
  }

</ng-container>