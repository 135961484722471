import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { AnimationComponentBase } from '@shared/base/animation-component.base';
import { airportTransferChange } from '@shared/consts/animations';
import { ComponentBaseService } from '@shared/services/component-base.service';

export class PricingDetailsConfig {
  constructor(
    public id: string,
    public text?: string
  ) {
    this.disabled = false;
    this.active = true;
    this.hideButton = false;
    this.blocked = false;
  }

  disabled: boolean;
  active: boolean;
  hideButton: boolean;
  blocked: boolean;
}

@Component({
  selector: 'app-pricing-details-v2',
  templateUrl: './pricing-details.component.html',
  animations: [airportTransferChange],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricingDetailsV2Component extends AnimationComponentBase {
  @Input() config!: PricingDetailsConfig;
  @Input() size: 'big' | 'default' = 'default';
  @Output() selectClick = new EventEmitter();
  @ContentChild('content') content!: TemplateRef<unknown>;

  constructor(
    services: ComponentBaseService
  ) {
    super(services);
  }

  onSelectClick() {
    !this.config.disabled && this.selectClick.emit();
  }
}
