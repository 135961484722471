<ng-container *transloco="let t">
  <app-sidebar-layout
    scope="termsAndConditions">
    <ng-template #content>
      <div class="sidebar-scroll-container"
        [class.justify-center]="!isVisible">
        @if(isVisible){
        @for (item of items; track $index) {

        <div class="gap margin-vertical"
          [class.block-header]="!sections.includes(item)"
          [class.section-header]="sections.includes(item)">
          <div
            [innerHtml]="custom[item]
                ? custom[item].header
                : t('termsAndConditions.'+ item + '.header', { value: tradingName })"
            [class.block-header-text]="!sections.includes(item)"
            [class.section-header-text]="sections.includes(item)">
          </div>
        </div>
        <div
          *ngIf="!contentLess.includes(item)"
          [innerHtml]="custom[item]
              ? custom[item].content
              : t('termsAndConditions.'+ item + '.content', { value: tradingName })"
          [class.block-content]="!sections.includes(item)"
          [class.section-conent]="sections.includes(item)">
        </div>
        }
        }
        @else {
        <app-loading-icon size="window"></app-loading-icon>
        }
      </div>
    </ng-template>
  </app-sidebar-layout>
</ng-container>