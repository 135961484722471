import { Component, HostBinding } from '@angular/core';
import { ComponentBase } from '@shared/base/component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { MemberService } from '@member/member.service';
import { BookingResponse, IBookingResponse } from '@shared/api/be-api.generated';
import { BreadCrumbsItem } from '@shared/components-v2/common/bread-crumbs/bread-crumbs.component';
import { DateTime } from 'luxon';
import { arrayToRecord, getDiff } from '@shared/common';

class ExtendedBookingResponse extends BookingResponse {
  constructor(data?: IBookingResponse) {
    super(data);

    if (data?.arrivalDate && data.departureDate) {
      this.nights = getDiff({ from: data.departureDate, to: data.arrivalDate }) || 0;
    }
  }

  nights!: number;
}

@Component({
  selector: 'app-member-bookings-v2',
  templateUrl: './member-bookings.component.html'
})
export class MemberBookingsV2Component extends ComponentBase {
  constructor(
    private readonly _member: MemberService,
    service: ComponentBaseService
  ) {
    super(service);
    this.init();
  }

  @HostBinding('class') hostClasses = ['w-100', 'flex-stretch-stretch', 'h-100-min'];
  bookings: ExtendedBookingResponse[] = [];
  isLoading = true;
  errorMessage?: string;
  breadCrumbs: BreadCrumbsItem[] = [{ caption: 'caption' }];
  bookingsPending: Record<string, string> = {};
  isOpened: Record<number, boolean> = {};
  now = DateTime.now();
  selectedIndex = 0;

  init() {
    this.pushSub(
      this._member.getBookings().subscribe({
        next: bookings => {
          this.bookings = bookings.map(item => new ExtendedBookingResponse(item));
          this.bookingsPending = arrayToRecord(this.bookings
            .filter(item => item.departureDate && item.departureDate > this.now)
            .map(item => item.bookingNumber || ''));
          this.bookings.forEach((_, index) => this.isOpened[index] = true);
        },
        error: ex => this._member.getMembershipError(ex),
        complete: () => this.isLoading = false
      })
    );
  }

  toggleOpen(index: number) {
    this.isOpened[index] = !this.isOpened[index];
  }
}
