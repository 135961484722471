<ng-container *transloco="let t">
  <div class="hotel-filter">
    <div class="hotel-filter-main-container">
      <h4>{{t('sidebar.filter.hotel')}}</h4>
      <mat-form-field>
        <mat-select
          [compareWith]="compareResort"
          [value]="resort"
          (selectionChange)="resortChanged($event)">
          <mat-option
            *ngFor="let resort of resorts; trackBy: 'id' | createTrackBy"
            [value]="resort">
            {{resort.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="hotel-filter-rooms-container">
      <div class="hotel-room"
        *ngFor="let room of rooms; let i = index; trackBy: 'id' | createTrackBy">
        <div class="hotel-room-header">
          <div class="hotel-room-header-text">
            {{t('room.name')}} {{i + 1}}
          </div>
          <div *ngIf="rooms.length > 1">
            <app-icon-button
              (click)="removeRoom(room)"
              stroked="iconInactive"
              name="close"
              alt="remove">
            </app-icon-button>
          </div>
        </div>
        <div class="hotel-room-body">
          <div class="hotel-room-body-record">
            <div class="hotel-room-body-record-item">
              {{t('adult.plural')}}
            </div>
            <div class="hotel-room-body-record-counter">
              <app-icon-button
                name="minus"
                filled="default"
                [stroked]="room.adults === minimumAdults ? 'border': 'selected'"
                alt="decrease"
                (click)="decreaseAdults(room)">
              </app-icon-button>
              <span>{{room.adults}}</span>
              <app-icon-button
                name="plus"
                filled="default"
                [stroked]="room.adults === maximumAdults ? 'border': 'selected'"
                alt="increase"
                (click)="increaseAdults(room)">
              </app-icon-button>
            </div>
          </div>
          <div class="hotel-room-body-record">
            <div>{{t('child.plural')}}</div>
            <div class="toggler-container">
              <mat-slide-toggle
                [disabled]="!ageGroups.length"
                (change)="setIsChildren($event, room)"
                [ngModel]="room.isChildrenVisible && ageGroups.length">
              </mat-slide-toggle>
            </div>
          </div>
        </div>
        <ng-container *ngIf="room.isChildrenVisible">
          <div class="hotel-room-body-record children"
            *ngFor="let group of ageGroups; trackBy: 'id' | createTrackBy">
            <ng-container *ngIf="group.id && group.name">
              <div class="hotel-room-body-record-item">
                {{group.name}} ({{t('child.fromTo', { from: group.agePeriod?.fromYear, to: group.agePeriod?.toYear }) }})
              </div>
              <div class="hotel-room-body-record-counter">
                <app-icon-button
                  name="minus"
                  filled="default"
                  [stroked]="room.childrenInAgeGroup[group.id] === 0 ? 'border': 'selected'"
                  alt="decrease"
                  (click)="decreaseChildren(room, group)">
                </app-icon-button>
                <span>{{room.childrenInAgeGroup[group.id]}}</span>
                <app-icon-button
                  name="plus"
                  filled="default"
                  stroked="selected"
                  alt="increase"
                  (click)="increaseChildren(room, group)">
                </app-icon-button>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="buttons-container">
    <!-- <button mat-raised-button
      (click)="addRoom()">
      {{t('room.add')}}
    </button> -->
    <button mat-raised-button
      color="primary"
      (click)="apply()">
      {{t('button.apply')}}
    </button>
  </div>
</ng-container>