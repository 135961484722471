<ng-container *transloco="let t">
  <div [ngClass]="[
    'select-option box-shadow position-relative',
    config.active
      ? recommendation?.backgroundColor
        ? 'border-' + recommendation?.backgroundColor
        : 'border-selected'
      : 'border'
  ]">
    @if (recommendation) {
    <app-recommendation-bar-v2 [settings]="recommendation"></app-recommendation-bar-v2>
    }
    <div class="select-option-photos">
      <app-gallery [images]="config.images"></app-gallery>
    </div>
    <div class="select-option-description">
      <div class="select-option-description-header">
        <div class="select-option-description-header-text"
          [class.color-border]="config.disabled">
          {{config.caption}}
        </div>
        <div class="select-option-description-header-icon bs-right">
          <app-icon-button
            (click)="openDetails()"
            alt="button.info"
            name="info"
            filled="selected">
          </app-icon-button>
        </div>
      </div>
      <div class="select-option-description-details custom-html-content"
        appTargetBlank
        [innerHTML]="config.description | safe: 'html'">
      </div>
      <div class="link"
        (click)="openDetails()">
        {{t('button.moreDetails')}}
      </div>
    </div>
    <div class="select-option-footer">
      @if(footer){
      <ng-container *ngTemplateOutlet="footer"></ng-container>
      }
    </div>
  </div>
</ng-container>