import { Component, ChangeDetectionStrategy, EventEmitter, ViewChild, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TravelAgentDetails2, ITravelAgentDetails2 } from '@shared/api/be-api.generated';
import { createFormControl } from '@shared/common';
import { FormComponent } from '@shared/components/common/form/form.component';
import { TravelAgentService } from '@shared/services/travel-agent.service';

type ITravelAgentDetails2Field = keyof ITravelAgentDetails2;

@Component({
  selector: 'app-travel-agent',
  templateUrl: './travel-agent.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TravelAgentComponent {
  @Output() cancelEdit = new EventEmitter();
  @ViewChild('form') fc!: FormComponent<ITravelAgentDetails2Field>;

  travelAgentForm = new UntypedFormGroup(this._getFormControls());

  constructor(
    private readonly _travelAgency: TravelAgentService
  ) {
  }

  tryApply(field: ITravelAgentDetails2Field) {
    if (field === 'emailAddress') {
      this.apply()
    }
  }

  apply() {
    this.travelAgentForm.markAllAsTouched();

    if (this.travelAgentForm.valid) {
      this._travelAgency.travelAgentDetails = new TravelAgentDetails2(this.fc.asObject);
      this.cancel();
    }
  }

  cancel() {
    this.cancelEdit.emit();
  }

  clearForm() {
    this._travelAgency.travelAgentDetails = undefined;
    this.cancel();
  }

  private _getFormControls(data?: ITravelAgentDetails2) {
    const {
      travelAgencyName,
      iataArcCliaTrueId,
      travelAgentName,
      phoneNumber,
      emailAddress
    } = data || this._travelAgency.travelAgentDetails || {};

    return {
      travelAgencyName: createFormControl(travelAgencyName),
      iataArcCliaTrueId: createFormControl(iataArcCliaTrueId),
      travelAgentName: createFormControl(travelAgentName),
      phoneNumber: createFormControl(phoneNumber),
      emailAddress: createFormControl(emailAddress, Validators.email)
    } as Record<ITravelAgentDetails2Field, UntypedFormControl>;
  }
}
