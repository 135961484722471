import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Airport } from '@shared/api/be-api.generated';
import { AirportSettings } from '@shared/models/airport';
import { ComponentBase } from '@shared/base/component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { DataService } from '@shared/services/data.service';
import { TranslocoService } from '@jsverse/transloco';
import { Size } from '@shared/models/common';

@Component({
  selector: 'app-airport-select',
  templateUrl: './airport-select.component.html',
  styleUrls: ['./airport-select.component.scss']
})
export class AirportSelectComponent extends ComponentBase implements OnChanges {
  @ViewChild('airportAutoComplete') airportAutoComplete!: MatAutocomplete;
  @Input() disabled = false;
  @Output() selectChange = new EventEmitter<Airport | undefined>();
  @Input() airports?: Airport[] = [];
  @Input() airport?: Airport;
  @Input() showFirstByDefault = false;
  @Input() type!: 'departure' | 'arrival';
  @Input() isFullWidthMobile = false;
  @Input() iconSize: Size = 'small';

  airportSettings: AirportSettings = new AirportSettings(this.airport);
  airportsSuggestions: Airport[] = [];

  noFlight?: Airport;

  get placeholderText() {
    const placeholder = this._transloco.translate(['airport', 'placeholder', this.type].join('.'));
    return this.showFirstByDefault
      ? this.airports?.find(() => true)?.displayText || placeholder
      : placeholder;
  }

  constructor(
    private readonly _transloco: TranslocoService,
    private readonly _data: DataService,
    services: ComponentBaseService
  ) {
    super(services);

    if (this._data.noFlightRatePlanType) {
      this.noFlight = new Airport({
        displayText: this._data.noFlightRatePlanType?.name
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['airport']) {
      this.setAirportSettings();
      this.setSuggestions(this.airport?.code ? this.airport.displayText : undefined);
    }
  }

  setAirportSettings() {
    if (!this.airport || !this.airport.code) {
      this.airport = new Airport({ displayText: this.placeholderText });
    }

    this.airportSettings = new AirportSettings(this.airport);
  }

  displayWith<T extends Airport>(airport: T) {
    return airport?.displayText || ''
  }

  setSuggestions(value?: string) {
    this.airportsSuggestions = (!value || value === this.airportSettings.current?.displayText
      ? this.airports
      : this.airports?.filter(({ displayText }) =>
        (displayText ?? '').toLowerCase().indexOf(value.toLowerCase()) > -1)) || [];
  }

  onChange(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.setSuggestions(value);
  }

  initAutoCompleteInput(element: HTMLElement) {
    const input = element as HTMLInputElement;
    if (!this.airport?.code) {
      input.value = '';
    }
    input.focus();
    input.select();
    input.dispatchEvent(new Event('input', { bubbles: true }));
  }

  optionSelected({ option }: MatAutocompleteSelectedEvent) {
    const selectedAirport = option.value as Airport;
    this.airportSettings.inactivate();

    this.airport = selectedAirport;
    this.setAirportSettings();
    this.selectChange.emit(this.airport.code ? this.airport : undefined);
  }

  openAutocomplete() {
    if (this.disabled) {
      return;
    }

    this.airportSettings.activate();
  }

  onBlur(event: Event) {
    const input = (event.target as HTMLInputElement)
    setTimeout(() => input.blur());
  }

  onClear() {
    this.airportSettings.clear();
    this.airportsSuggestions = this.airports || [];
  }
}
