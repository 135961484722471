import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiException, ITravelCompanion, TravelCompanion } from '@shared/api/be-api.generated';
import { FormComponent } from '@shared/components/common/form/form.component';
import { genderValues, SelectOption } from '@shared/models/common';
import { MemberService } from '@member/member.service';
import { dateFormat } from 'src/app/formats/date-time';

type TravellerField = keyof ITravelCompanion;

@Component({
  selector: 'app-traveller-profile-v2',
  templateUrl: './traveller-profile.component.html',
  styleUrls: ['./traveller-profile.component.scss']
})
export class TravellerProfileV2Component implements OnInit {
  constructor(
    private readonly _member: MemberService
  ) {
  }

  @Output() closed = new EventEmitter();
  @Input() profile?: TravelCompanion;
  @ViewChild('form') fc!: FormComponent<TravellerField>;

  travellerForm!: UntypedFormGroup;
  isLoading = false;
  errorMessage?: string;

  valueFormats: Partial<Record<TravellerField, string>> = { dob: dateFormat.type1 };

  fieldsOptions: Partial<Record<TravellerField, SelectOption[]>> = {
    typeCode: [
      { label: 'Spouse', value: 'Spouse' },
      { label: 'Children', value: 'Children' },
      { label: 'Friend', value: 'Friend' },
      { label: 'Other', value: 'Other' }
    ],
    size: [
      { label: 'XXL', value: 'XXL' },
      { label: 'XL', value: 'XL' },
      { label: 'L', value: 'L' },
      { label: 'M', value: 'M' },
      { label: 'S', value: 'S' },
      { label: 'XS', value: 'XS' },
      { label: 'XXS', value: 'XXS' },
    ],
    gender: [
      { label: 'Male', value: genderValues.male.toString() },
      { label: 'Female', value: genderValues.female.toString() }
    ]
  };

  skip: Partial<Record<TravellerField, boolean>> = { id: true };
  required: Partial<Record<TravellerField, boolean>> = { name: true, phone: true, typeCode: true };
  dateFields: Partial<Record<TravellerField, boolean>> = { dob: true };

  fields: TravellerField[] = [
    'name', 'phone', 'typeCode', 'gender', 'dob', 'description', 'size', 'food', 'treat'
  ];

  ngOnInit() {
    if (!this.profile) {
      this.profile = new TravelCompanion({});
    }

    const formControls = this.fields
      .filter(key => !this.skip[key])
      .map(key => ({ [key]: this.getProfileFormControl(key) }))
      .reduce((item, all) => ({ ...item, ...all }), {});

    this.travellerForm = new UntypedFormGroup(formControls);
  }

  getProfileFormControl(key: TravellerField) {
    const profile = this.profile || new TravelCompanion({});
    return new UntypedFormControl(profile[key], this.required[key] ? Validators.required : [])
  }

  save() {
    this.travellerForm.markAllAsTouched()
    if (!this.isLoading && this.travellerForm.valid) {
      this.isLoading = true;
      this.errorMessage = undefined;
      this._member.saveTraveller(new TravelCompanion({ ...this.fc.asObject, id: this.profile?.id }))
        .subscribe({
          next: () => this.close(),
          error: err => this.handleError(err)
        });
    }
  }

  remove() {
    if (!this.isLoading && this.profile) {
      this.isLoading = true;
      this._member.deleteTraveller(this.profile)
        .subscribe({
          next: () => this.close(),
          error: err => this.handleError(err)
        });
    }
  }

  close() {
    this.closed.emit();
  }

  handleError(err: ApiException): void {
    this.isLoading = false;
    this.errorMessage = this._member.getMembershipError(err);
  }
}
