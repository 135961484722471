<ng-container *transloco="let t">
  <div>
    <app-notification
      [isThreeDots]="false"
      position="start"
      iconName="money"
      iconCircle="ratePlanType"
      backgroundColor="default"
      stroked="textPrimary">
      <div class="flex-between-start flex-column-ipod-max gap-half">
        <div class="flex-column gap-half self-align-start hidden-tablet-min">
          <div class="text-1_25-1_25-600">{{t('flight.notification.header')}}</div>
          <div class="text-0_75-0_75-400">{{t('flight.notification.content')}}</div>
        </div>
        <div class="flex-column gap-half self-align-start hidden-tablet-max">
          <div class="text-1_5-1_5-600">{{t('flight.notification.header')}}</div>
          <div class="text-1_25-1_25-400">{{t('flight.notification.content')}}</div>
        </div>
        <div class="flex padding-vertical-quarter padding-right-half margin-left-auto">
          <button mat-raised-button
            color="primary"
            class="select-button padding-horizontal padding-vertical-half"
            (click)="apply()">
            {{t('flight.notification.button')}}
          </button>
        </div>
      </div>
    </app-notification>
  </div>
</ng-container>