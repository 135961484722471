import { NgModule } from '@angular/core';
import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, forkJoin, map, of } from 'rxjs';

import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { provideTransloco, TranslocoModule } from '@jsverse/transloco';

import { TenantService } from '@shared/services/tenant.service';
import { mergeObjects } from '@shared/common';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private _http = inject(HttpClient);

  constructor(
    private readonly tenant: TenantService
  ) { }

  getTranslationPath = (...items: string[]) => `${environment.baseUrl}/assets/i18n/${items.join('/')}.json`;

  getTranslation(lang: string) {
    const common = this._http.get<Translation>(this.getTranslationPath(lang));
    const tenantId = this.tenant.id;

    if (!tenantId) {
      return common;
    }
    const tenant = this._http
      .get<Translation>(this.getTranslationPath(tenantId, lang))
      .pipe(catchError(() => of(undefined)));

    return forkJoin({ common, tenant })
      .pipe(map(({ common, tenant }) => tenant ? mergeObjects(common, tenant) : common));
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: ['en'],
        fallbackLang: 'en',
        defaultLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader
    }),
  ],
})
export class TranslocoRootModule { }
