import { Injectable } from '@angular/core';
import { InitializationServiceBase } from '@shared/base/initialization-service.base';
import { tap } from 'rxjs';
import { BookingEngineClient, IInitializationData, InitializationData, RatePlanType, SettingsResponse } from '../api/be-api.generated';
import { SsrHelperService } from './ssr-helper.service';
import { TenantService } from './tenant.service';

@Injectable()
export class DataService extends InitializationServiceBase<IInitializationData> {
  constructor(
    private readonly _apiClient: BookingEngineClient,
    tenant: TenantService,
    ssrHelper: SsrHelperService
  ) {
    super('dataService', InitializationData.fromJS, tenant, ssrHelper);
  }

  override initialize() {
    if (this.isInitialized) {
      this._updateDefaultRatePlanTypes(this.values.ratePlanTypes);
      this._updateSettings(this.values.settings);
    }

    const initialized = super.initialize(() => ({
      airports: this._apiClient.getAirports(this.tenant.id),
      ratePlanTypes: this._apiClient.getRatePlanTypes(this.tenant.id)
        .pipe(tap(result => this._updateDefaultRatePlanTypes(result))),
      resorts: this._apiClient.getResorts(this.tenant.id),
      cabinTypes: this._apiClient.getCabinTypes(this.tenant.id),
      airlines: this._apiClient.getAirlines(this.tenant.id),
      addOns: this._apiClient.getAddOns(this.tenant.id),
      settings: this._apiClient.getSettings(this.tenant.id)
        .pipe(tap(result => this._updateSettings(result))),
      addOnCategories: this._apiClient.getAddOnCategories(this.tenant.id)
    }));

    return initialized;
  }

  noFlightRatePlanType?: RatePlanType;
  flightRatePlanType?: RatePlanType;
  tradingName?: string;

  get isEnabledAbandonedCart() {
    return this.tenant.settings.abandonedCart || this.values.settings?.isEnabledAbandonedCart;
  }

  private _updateDefaultRatePlanTypes(ratePlanTypes?: RatePlanType[]) {
    this.flightRatePlanType = ratePlanTypes?.find(rtp => rtp.flightsIncluded);
    this.noFlightRatePlanType = ratePlanTypes?.find(rtp => !rtp.flightsIncluded);
  }

  private _updateSettings({ tradingName }: SettingsResponse = new SettingsResponse()) {
    this.tradingName = tradingName;
  }
}
