<ng-container *transloco="let t">
  <div class="room-viewer"
    *ngIf="!roomType">
    <div class="section-subheader wrap flex">
      <app-anchor type="section" index="1"></app-anchor>
      <div class="not-found">
        {{t('room.noRoom')}}
      </div>
    </div>
  </div>
  <div class="room-viewer"
    *ngIf="roomType">
    <div class="content-container">
      <div class="flex-column">
        <div class="section-header" [hidden]="hideHeader">
          <div class="section-header-text">
            <app-anchor type="section" index="1"></app-anchor>
            1. {{t('room.header')}}
          </div>
          <app-info-label
            [text]="ratePlanType?.name"
            type="ratePlanType">
          </app-info-label>
          <app-section-toggler #toggler></app-section-toggler>
        </div>
        <div class="collapsable"
          [class.collapsed]="toggler.isHidden">
          <div class="section-subheader wrap"
            [hidden]="hideHeader">
            <div class="section-subheader-text">
              {{t('room.viewer.subheader.text')}}&nbsp;
            </div>
            <!-- <div class="section-subheader-link"
              (click)="clickSubheaderLink()">
              {{t('room.viewer.subheader.link')}}
            </div> -->
          </div>
          <div class="room-information position-relative"
            [class.border-memberDefault]="isMember && isRecommended"
            [class.border-selected]="!isMember || !isRecommended"
            [class.no-special-offer]="!specialOffer">
            @if(selectedPackage && isRecommended){
            <app-recommendation-bar-v2
              [backgroundColor]="isMember ? 'memberDefault': 'selected'"
              [textColor]="isMember ? 'textPrimary': 'default'"
              textKey="package.bestOffer">
            </app-recommendation-bar-v2>
            }
            <div class="room-photos"
              [class.no-images]="!images.length">
              <app-gallery [images]="images"></app-gallery>
            </div>
            <div class="room-data">
              <div class="room-details">
                <div class="room-details-main">
                  <div class="hotel-name">
                    {{resort?.name}}
                  </div>
                  <div class="room-name"
                    (click)="openPackageDetails()">
                    <div class="room-name-caption">
                      {{roomType.caption}}
                    </div>
                    <app-icon-button
                      class="bs-right"
                      iconSize="big"
                      buttonSize="big"
                      name="info"
                      filled="selected"
                      alt="room.info">
                    </app-icon-button>
                  </div>
                  <div class="room-description">
                    {{roomType.description}}
                  </div>
                  @if (specialOffers?.length) {
                  <div class="flex-start gap">
                    <div class="link"
                      (click)="openPackageDetails()">
                      {{t('button.moreDetails')}}
                    </div>
                    <ng-container *ngTemplateOutlet="memberBonus"></ng-container>
                  </div>
                  }
                </div>
              </div>
              <div class="room-amenities"
                *ngIf="roomType?.amenities?.length && !specialOffers?.length">
                <div class="room-amenities-item"
                  *ngFor="let amenity of roomType?.amenities">
                  {{amenity}}
                </div>
              </div>
              @if(!specialOffers?.length){
              <div class="flex-between gap">
                <div class="link"
                  (click)="openPackageDetails()">
                  {{t('button.moreDetails')}}
                </div>
                <ng-container *ngTemplateOutlet="memberBonus"></ng-container>
              </div>
              }
              <div class="special-offers"
                *ngIf="specialOffer">
                <ng-container>
                  <div class="special-offers-label">
                    {{t('package.select')}}:
                  </div>
                  <mat-radio-group class="special-offers-content"
                    [ngModel]="specialOffer"
                    (change)="selectSpecialOffer($event)">
                    <mat-radio-button
                      *ngFor="let currentSpecialOffer of specialOffers; trackBy: 'specialOfferId' | createTrackBy"
                      [value]="currentSpecialOffer">
                      <app-special-offer-caption
                        [isVisiblePrice]="true"
                        [currentSpecialOffer]="currentSpecialOffer">
                      </app-special-offer-caption>
                    </mat-radio-button>
                  </mat-radio-group>
                </ng-container>
              </div>
              <ng-container *ngTemplateOutlet="pricing"></ng-container>
            </div>
          </div>
          <div class="room-footer">
            <app-room-select></app-room-select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #pricing>
    @if(roomType){
    <app-pricing-details-v2
      [config]="pricingConfig"
      size="big"
      class="big-not-found">
      <app-package-pricing
        size="big"
        [hideWhenUnavailable]="false"
        [currentRoomType]="roomType"
        [currentPackage]="selectedPackage"
        [isActive]="pricingConfig.active"
        [priceFormat]="priceFormat">
      </app-package-pricing>
    </app-pricing-details-v2>
    }
  </ng-template>

  <ng-template #packageDetails>
    <app-sidebar-layout>
      <ng-template #content>
        <app-package-details
          *ngIf="roomType"
          [showBonus]="showBonus"
          [currentRoomType]="roomType"
          [currentPackage]="selectedPackage">
        </app-package-details>
      </ng-template>
      <ng-template #footer>
        <ng-container *ngTemplateOutlet="pricing">
        </ng-container>
      </ng-template>
    </app-sidebar-layout>
  </ng-template>

  <ng-template #hotelierMessage>
    <app-sidebar-layout #sidebarLayout>
      <ng-template #header>
        <div class="sidebar-layout-header">
          <div class="sidebar-layout-header-text">
            <h2>{{t('room.viewer.subheader.link')}}</h2>
            <app-icon-button
              class="bs-right"
              stroked="iconInactive"
              buttonClass="close-button"
              (click)="sidebarLayout.close()"
              name="close">
            </app-icon-button>
          </div>
        </div>
        <div class="sidebar-layout-subheader">
          <p>Some subheader text</p>
        </div>
      </ng-template>
      <ng-template #content>
        <div
          [class.flex-start]="!isLoadingHtmlContent"
          [class.flex]="isLoadingHtmlContent"
          [class.loader]="isLoadingHtmlContent"
          class="margin-vertical">
          <ng-container *ngIf="isLoadingHtmlContent; else hotelierMessageTextTemplate">
            <app-loading-icon></app-loading-icon>
            <h2 class="color-selected">Content is loading...</h2>
          </ng-container>
          <ng-template #hotelierMessageTextTemplate>
            <div *ngIf="hotelierMessageText; else defaultHotelierMessageTextTemplate"
              [innerHtml]="hotelierMessageText"></div>
          </ng-template>
          <ng-template #defaultHotelierMessageTextTemplate>
            Default message
          </ng-template>
        </div>
      </ng-template>
    </app-sidebar-layout>
  </ng-template>

  <ng-template #memberBonus>
    @if(isMemberNonMonetary){
    <app-info-label
      (click)="openPackageDetails(true)"
      class="cursor-pointer"
      [text]="'+ ' + t('member.bonus.plural') + '!'"
      type="memberDefault">
    </app-info-label>
    }
  </ng-template>
</ng-container>