<ng-container *transloco="let t">
  <div class="notification-booking flex">
    <div class="main-container"
      *ngIf="isBookingCancelled">
      <app-notification
        position="center"
        [isThreeDots]="false"
        class="cancelled"
        filled="transparent"
        stroked="alertBorder"
        borderColor="alertBorder"
        borderWidth="thick"
        backgroundColor="alertBackground"
        iconName="warning">
        <div class="color-alertText bold"
          [innerHtml]="t('notification.booking.cancelled', {
            value: activeResortPhone
              ? ': ' + activeResortPhone
              : ''
           })">
        </div>
      </app-notification>
    </div>
    <div class="main-container"
      *ngIf="!isBookingCancelled">
      <app-notification
        position="center"
        [isThreeDots]="false"
        [ngClass]="[bookingId ? 'success' : 'error']"
        [filled]="bookingId ? 'successText': 'transparent'"
        [stroked]="bookingId ? 'transparent': 'textError'"
        [backgroundColor]="bookingId ? 'successBackground' : 'transparent'"
        [iconName]="bookingId ? 'checkmark' : 'close'">
        <div class="color-successText"
          *ngIf="bookingId; else bookingNotFound"
          [innerHtml]="t('notification.booking.success', { value: '<span>' + bookingId + '</span>' })">
        </div>
        <ng-template #bookingNotFound>
          <div class="error color-textError">
            {{t('notification.booking.error')}}
          </div>
        </ng-template>
      </app-notification>
      <div class="flex"
        *ngIf="!bookingId"
        (click)="openSearch()">
        <app-icon-button
          filled="transparent"
          stroked="selected"
          name="search">
        </app-icon-button>
        <div class="link">
          {{t('page.search.open')}}
        </div>
      </div>
    </div>
  </div>
</ng-container>