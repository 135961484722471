import Logger from 'js-logger';
import { EventEmitter, Injectable } from '@angular/core';
import { catchError, debounceTime, map, tap, throwError } from 'rxjs';
import { DateTime } from 'luxon';

import { scrollToId } from '@shared/common';
import { topCountriesTwoLetterCodes as topCountries } from '@shared/consts/common';
import { ReservationInput, ReservationVerification, ReservationVerificationSection } from '@shared/models/reservation';

import { InitializationServiceBase } from '@shared/base/initialization-service.base';

import { ApiException, IBookingData, BookingData, DatePeriod, CancellationPolicy3, PaymentPolicyResponse, ReservationRequest, ReservationResponse, AbandonedCartRequest, AbandonedCartData, AbandonedCartDataAddon } from '@shared/api/be-api.generated';
import { BookingEngineClient, PaymentPolicyBreakdownRequest, IReservationResponse, ContractDetailsResponse } from '@shared/api/be-api.generated';

import { AddOnService } from '@shared/services/add-on.service';
import { PackageService } from '@shared/services/package.service';
import { PricingService } from '@shared/services/pricing.service';
import { SearchService } from '@shared/services/search/search.service';
import { SsrHelperService } from '@shared/services/ssr-helper.service';
import { TravelAgentService } from '@shared/services/travel-agent.service';
import { PriceFormat, TranslocoInput, pages } from '@shared/models/common';
import { getChildrenErrorMessage } from './booking.error-messages';
import { TranslocoService } from '@jsverse/transloco';
import { TenantService } from '@shared/services/tenant.service';
import { QueryParamsService } from '@shared/services/query-params.service';
import { AppCurrencyPipe } from 'src/app/pipes/app-currency.pipe';
import { FormatPricePipe } from 'src/app/pipes/format-price.pipe';
import { DataService } from '@shared/services/data.service';
import { FlightTimePipe } from 'src/app/pipes/flight-time.pipe';
import { dateFormat, formatDateTime } from 'src/app/formats/date-time';
import { NightsPipe } from 'src/app/pipes/nights.pipe';

@Injectable({
  providedIn: 'root'
})
export class BookingService extends InitializationServiceBase<IBookingData> {
  cartRequestString?: string;
  cartRequestEmail?: string;
  cartRequest?: ReservationRequest;
  cartRequestChange: EventEmitter<void> = new EventEmitter();

  lastRequest?: ReservationRequest;
  lastResponse?: ReservationResponse;

  cancellationPolicy?: CancellationPolicy3;
  paymentPolicies?: PaymentPolicyResponse[];

  dataChanged = new EventEmitter();
  startedVerification = new EventEmitter();
  postSectionCheckFailed = new EventEmitter<TranslocoInput>();

  reservationVerification = new ReservationVerification();
  reservationInput = new ReservationInput();
  reservation = {
    started: new EventEmitter(),
    failed: new EventEmitter<ApiException>(),
    success: new EventEmitter<{
      request: ReservationRequest,
      response: IReservationResponse
    }>()
  };

  postChecks = [
    () => getChildrenErrorMessage({
      ageGroups: this._package.resort?.ageGroups,
      children: this._search.getChildren(),
      childDetails: this.reservationInput.childDetails,
      yearText: this._transloco.translate('child.yearOrYears'),
      childrenInGroupText: this._transloco.translate('child.inGroup')
    })
  ];

  get isLastRequestNotChanged() {
    return this.lastRequest && JSON.stringify(this.lastRequest?.toJSON()) === JSON.stringify(this.nextRequest?.toJSON())
  }

  constructor(
    private readonly _addOn: AddOnService,
    private readonly _apiClient: BookingEngineClient,
    private readonly _package: PackageService,
    private readonly _search: SearchService,
    private readonly _pricing: PricingService,
    private readonly _travelAgent: TravelAgentService,
    private readonly _transloco: TranslocoService,
    private readonly _queryParams: QueryParamsService,
    private readonly _appCurrency: AppCurrencyPipe,
    private readonly _formatPrice: FormatPricePipe,
    private readonly _data: DataService,
    private readonly _flightTime: FlightTimePipe,
    private readonly _nights: NightsPipe,
    tenant: TenantService,
    ssrHelper: SsrHelperService,
  ) {
    super('bookingData', BookingData.fromJS, tenant, ssrHelper);

    _package.setWhenDataChanged(() => this.values?.contract && this._setPolicies(this.values.contract));
    this.cartRequestChange
      .pipe(debounceTime(2000))
      .subscribe(() => this._saveCartRequest());
  }

  //#region init
  override initialize() {
    return super.initialize(() => ({
      countries: this._apiClient.getCountries(this.tenant.id)
        .pipe(map(result => ([
          ...result.filter(item => topCountries.includes(item.twoLetterCode)),
          ...result.filter(item => !topCountries.includes(item.twoLetterCode))
        ]))),
      contract: this._apiClient.getContract(this.tenant.id)
        .pipe(tap((result) => this._setPolicies(result)))
    }));
  }

  private _setPolicies({ cancellationPolicy, paymentPolicies }: ContractDetailsResponse) {
    const specialOfferCP = this._package.specialOffer?.cancellationPolicy;
    this.cancellationPolicy = specialOfferCP || cancellationPolicy;
    this.paymentPolicies = this.cancellationPolicy?.isNonRefundable
      ? paymentPolicies?.filter(policy => policy.isFullDeposit)
      : paymentPolicies;
  }

  loadPriceBreakdown() {
    const activePolicyId = this.reservationInput.activePolicy?.paymentPolicyId;
    if (activePolicyId) {
      const { addOnsTotal: addOnsAmount, total: amount, flightTotal: flights } = this._pricing;
      const { fromDate: start, toDate: finish } = this._search.state.context;
      const travelPeriod = new DatePeriod({ start, finish });
      const bookingDate = DateTime.now();
      const request = new PaymentPolicyBreakdownRequest({ addOnsAmount, amount, bookingDate, travelPeriod, flights });

      return this._apiClient
        .getPaymentPolicyBreakdown(this.tenant.id, activePolicyId, request)
        .subscribe(result => this.reservationInput.setSchedule(result))
        .add(() => this.dataChanged.emit());
    }
  }

  clear() {
    this.reservationInput = new ReservationInput();
    this.reservationVerification = new ReservationVerification();
  }
  //#endregion

  //#region data change
  setWhenDataChanged(onDataChanged: (data: BookingService) => void, useWithFirstInit = true) {
    if (this.isInitialized && useWithFirstInit) {
      onDataChanged(this);
    }

    return this.dataChanged.subscribe(() => onDataChanged(this));
  }
  //#endregion

  //#region Verification Process
  restartVerification() {
    // clear previous
    this.reservationVerification = new ReservationVerification();
    this.startedVerification.emit();

    // logic is pretty easy
    // we will start verification all modules
    // and once all will be checked
    // and if all will be valid then reservation will start
    // for this, everybody who will subscribe for startedVerification
    // and updateVerificationStatus() should execute in the end up of subscribe func
    // also, updateVerificationStatus() should be responsible for scroll to error
  }

  updateVerificationStatus(section: ReservationVerificationSection, isValid: boolean, anchorId?: string) {
    const verification = this.reservationVerification;
    verification.records[section] = { isValid, anchorId, isChecked: true };

    if (verification.areAllChecked) {
      if (verification.areAllValid) {
        if (this._getIsPostSectionCheckSuccess()) {
          const request = this.nextRequest;
          if (request) {
            if (this.isLastRequestNotChanged && this.lastResponse) {
              this.reservation.success.emit({ request, response: this.lastResponse });
            } else {
              this.reservation.started.emit();
              this._createReservation(request);
            }
          } else {
            Logger.error('Should never happen. You try to make a reservation request without data.')
          }
        } else {
          // ignore, error message is displayed
        }
      } else {
        scrollToId({
          anchorId: verification.getTopInvalidSection()?.anchorId,
          timeout: 0
        });
      }

      Logger.info(verification.records);
    }
  }

  private _getIsPostSectionCheckSuccess() {
    const postError = this.postChecks
      .map(item => item())
      .find(item => item.key);

    const isValid = !postError?.key;

    if (!isValid) {
      this.postSectionCheckFailed.emit(postError);
    }

    return isValid;
  }
  //#endregion

  //#region reservation
  get nextRequest() {
    return this.reservationInput.buildReservation({
      addOn: this._addOn,
      packageService: this._package,
      search: this._search,
      travelAgent: this._travelAgent,
      adultsCount: this._search.getTotalAdults(),
      childrenCount: this._search.getTotalChildren(),
      membershipProvider: this.tenant.membershipProvider
    });
  }

  private _createReservation(request: ReservationRequest) {
    Logger.info('book started');

    this._apiClient
      .createBooking(this.tenant.id, request)
      .pipe(catchError(error => {
        this.reservation.failed.emit(error as ApiException);
        return throwError(() => error);
      }))
      .subscribe(response => {
        this.lastRequest = request;
        this.lastResponse = response;
        this.reservation.success.emit({ response, request });
      });

    return request;
  }
  //#endregion

  //#region save cart
  saveCartRequest() {
    if (this._queryParams.value.cartEmail) {
      // if cart request exists then it's restored and no need to send abandoned cart one more time
    } else {
      this.cartRequestChange.emit();
    }
  }

  private _saveCartRequest() {
    if (!this._data.isEnabledAbandonedCart) {
      return;
    }

    const nextRequest = this.reservationInput.buildReservation({
      addOn: this._addOn,
      packageService: this._package,
      search: this._search,
      travelAgent: this._travelAgent,
      adultsCount: this._search.getTotalAdults(),
      childrenCount: this._search.getTotalChildren()
    }, true);


    if (nextRequest?.email) {
      if (nextRequest.email !== this.cartRequestEmail) {
        if (this.cartRequestEmail) {
          this._apiClient.removeCart(this.tenant.id, this.cartRequestEmail)
            .subscribe(() => Logger.info('Cart reminder cleared for email: ' + this.cartRequestEmail));
        }

        this.cartRequestEmail = nextRequest.email;
      }

      const nextRequestJson = nextRequest.toJSON();
      if (nextRequestJson['flightsToBuy'] && nextRequestJson['flightsToBuy']['itinerary']) {
        nextRequestJson['flightsToBuy']['itinerary'] = undefined;
      }

      const nextRequestJsonString = JSON.stringify(nextRequestJson);
      if (this.cartRequestString !== nextRequestJsonString) {
        this.cartRequestString = nextRequestJsonString;
        Logger.info(nextRequestJson);

        const cart = new AbandonedCartRequest({
          content: this.cartRequestString,
          email: nextRequest.email,
          url: this._getAbandonedCartLink(nextRequest.email),
          data: this._createAbandonedCartData()
        });

        this.cartRequest = nextRequest;

        this._apiClient.updateCart(this.tenant.id, cart)
          .subscribe(() => Logger.info(this.cartRequestString));
      }
    }
  }

  private _createAbandonedCartData() {
    const getPriceWithCurrency = (value: number | undefined, format: PriceFormat) =>
      this._appCurrency.transform(
        this._formatPrice.getPriceByFormat(
          value || 0,
          this._package.periodNights,
          this._package.guestsCount,
          this._package.roomsCount,
          format
        )
      ) || undefined;

    const context = this._search.state.context;
    const itinerary = this._package.flight?.itinerary;
    const inboundFlight = this.reservationInput.existingFlights?.inboundFlight;
    const outboundFlight = this.reservationInput.existingFlights?.outboundFlight;

    const arrivalAirportCode = context.arrivalAirport?.code || inboundFlight?.arrivalAirportCode;
    const departureAirportCode = context.departureAirport?.code || outboundFlight?.departureAirportCode;

    const inboundTransferId = this._package.inboundAirportTransfer?.id || inboundFlight?.airportTransferId;
    const outboundTransferId = this._package.outboundAirportTransfer?.id || outboundFlight?.airportTransferId;

    const getFlightTime = (value?: DateTime) => this._flightTime.transform(value) || undefined;
    const getAirport = (code?: string) => code
      ? this._data.values.airports.find(item => item.code === code)?.displayText : undefined;
    const getAirportTransfer = (id?: string) => id
      ? this._package.resort?.airportTransfers?.find(item => item.id === id)?.name : undefined;

    const startFlight = itinerary?.inboundJourney?.flights?.find(() => true);
    const endFlight = itinerary?.outboundJourney?.flights?.reverse()?.find(() => true);

    return new AbandonedCartData({
      resortId: this._package.resort?.resortId,
      resortName: this._package.resort?.name,
      addOns: this._addOn.toPurchaseViewModel.map(item => new AbandonedCartDataAddon({
        name: item.name,
        pricePerItem: this._appCurrency.getResult(item.pricePerItem)?.toString() || undefined,
      })),
      airlineMarketingName: this._package.marketingAirlineNames || undefined,
      arrivalAirport: getAirport(arrivalAirportCode),
      departureAirport: getAirport(departureAirportCode),
      arrivalDate: formatDateTime(context.fromDate, dateFormat.type5),
      departureDate: formatDateTime(context.toDate, dateFormat.type5),
      flightArrivalTime: getFlightTime(endFlight?.arrivalTime),
      flightDepartureTime: getFlightTime(startFlight?.departureTime),
      nights: this._nights.transform(this._package.periodNights),
      pricePerRoomPerNight: getPriceWithCurrency(this._package.selectedPackage?.finalPrice, 'prpn'),
      priceTotal: getPriceWithCurrency(this._pricing.total, 'total'),
      roomName: this._package.roomType?.caption,
      specialOfferName: this._package.specialOffer?.name,
      tradingName: this._data.tradingName,
      inboundAirportTransfer: getAirportTransfer(inboundTransferId),
      outboundAirportTransfer: getAirportTransfer(outboundTransferId),
    })
  }

  private _getAbandonedCartLink(email: string) {
    return [
      location.protocol,
      '',
      location.host,
      this.tenant.id,
      pages.results + location.search.replace('&isTest=true', '') + '&cartEmail=' + email
    ].join('/');
  }

  restoreCartRequest(email: string) {
    return this._apiClient.getCart(this.tenant.id, email)
      .pipe(tap(result => {
        if (result.content) {
          this.cartRequestString = result.content;
          this.cartRequest = ReservationRequest.fromJS(JSON.parse(this.cartRequestString));
        }
      }));
  }

  clearCart() {
    this.cartRequest = undefined;
    if (this._queryParams.value.cartEmail) {
      this._queryParams.patchQueryParams({
        cartEmail: undefined
      });
    }
  }
  //#endregion
}
