<ng-container *transloco="let t">

  <div class="member-login">
    <app-select-tab-v2
      scope="member"
      [keys]="avaliableMemberOptionKeys"
      [(selectedKey)]="selectedMemberOptionKey">
      <ng-template #content
        let-selectedKey="selectedKey">
        @switch (selectedKey) {
        @case (memberOptionsKeys.signIn) {
        <app-member-signin-v2 [selectTab]="selectTab">
          <ng-template #rewardBullets>
            <ng-container *ngTemplateOutlet="rewardBulletsTemplate"></ng-container>
          </ng-template>
        </app-member-signin-v2>
        }
        @case (memberOptionsKeys.register) {
        <app-member-register-v2>
          <ng-template #rewardBullets>
            <ng-container *ngTemplateOutlet="rewardBulletsTemplate"></ng-container>
          </ng-template>
        </app-member-register-v2>
        }
        }
      </ng-template>
    </app-select-tab-v2>
  </div>
  <ng-template #rewardBulletsTemplate>
    @for (itemNumber of [1, 2, 3]; track $index) {
    <div class="reward-bullet color-successText flex-start text-0_875-1_25-600">
      <app-icon
        class="margin-left-minus-half"
        size="small"
        name="checkmark"
        filled="successText"
        alt="checked">
      </app-icon>
      {{t('member.reward.bullet.' + itemNumber)}}
    </div>
    }
  </ng-template>

</ng-container>