<ng-container *transloco="let t">
  <div class="notification-signup flex">
    <div class="main-container">
      <app-notification
        [reduceSpace]="true"
        backgroundColor="successBackground"
        position="start"
        filled="successText"
        iconName="clock">
        <div class="color-successText notification-custom-text no-wrap">
          {{t('notification.prices')}}
        </div>
      </app-notification>
    </div>
  </div>
</ng-container>