import { ChangeDetectionStrategy, Component } from '@angular/core';
import { scrollToId } from '@shared/common';
import { ComponentsCommunicationService } from '@shared/services/components-communication.service';

@Component({
  selector: 'app-notification-flight-v2',
  templateUrl: './notification-flight.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationFlightV2Component {
  constructor(
    private readonly _comm: ComponentsCommunicationService
  ) { }

  apply() {
    scrollToId({ anchorId: 'search-bar' });
    setTimeout(() => this._comm.flightsEnabled.emit(true), 1000);
  }
}
