import { EventEmitter, Injectable } from '@angular/core';
import { AirportTransfer } from '@shared/api/be-api.generated';
import { AddOnService } from './add-on.service';
import { NavigationService } from './navigation.service';
import { PackageService } from './package.service';

@Injectable()
export class PricingService {
  total = 0;
  saved = 0;
  memberSaved = 0;
  dataChanged = new EventEmitter();

  constructor(
    private readonly _navigation: NavigationService,
    private readonly _addOn: AddOnService,
    private readonly _package: PackageService
  ) {
    this._package.setWhenDataChanged(() => this._update());
    this._addOn.setWhenDataChanged(() => this._update());
  }

  private _update() {
    if (this.total !== this._total || this.saved !== this._saved || this.memberSaved != this.memberSaved) {
      this.total = this._total;
      this.saved = this._saved;
      this.memberSaved = this._memberSaved;

      if (this.total > 0) {
        this.dataChanged.emit();
      } else {
        this._navigation.openSearch();
      }
    }
  }

  get addOnsTotal() {
    return this._addOn.toPurchaseViewModel
      .reduce((total, addOn) => (total + addOn.priceTotal), 0);
  }


  get flightTotal() {
    return this._package.flight?.itinerary?.price || 0;
  }

  get roomTotal() {
    return Number((this._packageFinal - this.flightTotal).toFixed(2))
  }

  get airportTransferTotal() {
    return this.inboundAirportTransferTotal + this.outboundAirportTransferTotal;
  }

  get inboundAirportTransferTotal() {
    return this._getAirportTransferPrice(this._package.inboundAirportTransfer);
  }

  get outboundAirportTransferTotal() {
    return this._getAirportTransferPrice(this._package.outboundAirportTransfer);
  }

  get _packageFinal() {
    return this._package.selectedPackage?.finalPrice || 0;
  }

  private get _packageBase() {
    return this._package.selectedPackage?.basePrice || 0;
  }

  private get _total() {
    return this._packageFinal + this.addOnsTotal + this.airportTransferTotal;
  }

  private get _saved() {
    return Number((this._packageBase - this._packageFinal).toFixed(2));
  }

  private get _memberSaved() {
    return this._package.selectedPackage?.memberDiscount || 0;
  }

  private _getAirportTransferPrice(airportTransfer?: AirportTransfer) {
    return (airportTransfer?.price || 0) * this._package.guestsCount;
  }

  setWhenDataChanged(onDataChanged: (data: PricingService) => void, useWithFirstInit = true) {
    if (useWithFirstInit) {
      onDataChanged(this);
    }

    return this.dataChanged.subscribe(() => onDataChanged(this));
  }
}
