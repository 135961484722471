import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Output } from '@angular/core';
import { ComponentBase } from '@shared/base/component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { MemberService } from '@member/member.service';
import { SidebarService } from '@shared/services/sidebar/sidebar.service';
import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-global-booking-button',
  templateUrl: './global-booking-button.component.html',
  styleUrls: ['./global-booking-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalBookingButtonComponent extends ComponentBase {
  constructor(
    private readonly _sidebar: SidebarService,
    private readonly _tracking: TrackingService,
    private readonly _member: MemberService,
    services: ComponentBaseService
  ) {
    super(services);

    this.pushSub(
      this._member.onMemberChanged(({ profile }) => this.memberId = profile?.memberId)
    );
  }

  @HostBinding('class.padding-top-less') withoutPadding = true;
  @Output() bookingStarted = new EventEmitter();
  memberId = this._member.profile?.memberId;
  loyaltyEnabled = this.services.tenant.loyaltyEnabled;

  startBooking() {
    this.services.navigation.openNext();
    this._sidebar.close();
    this.bookingStarted.emit();
    this._tracking.beginCheckout();
  }
}
