import { ChangeDetectionStrategy, Component, ContentChild, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { Color, Position, Size } from '@shared/models/common';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnChanges {
  @Input() borderColor: Color = 'transparent';
  @Input() backgroundColor: Color = 'transparent';
  @Input() position: Position = 'center';
  @Input() borderWidth: 'default' | 'double' | 'thick' = 'default';

  @Input() iconCircle?: Color;
  @Input() iconName?: string;
  @Input() iconSize: Size = 'default';
  @Input() iconPosition: Position = 'center';
  @Input() filled: Color = 'transparent';
  @Input() stroked: Color = 'transparent';
  @Input() isThreeDots = true;

  @Input() isBorder = true;
  @Input() reduceSpace = false;

  @ContentChild('badge') badge!: TemplateRef<unknown>;
  @ContentChild('content') content!: TemplateRef<unknown>;

  classNames = '';

  ngOnChanges(_changes: SimpleChanges): void {
    this.classNames = ` ${this.position} bc-${this.backgroundColor} icon-position-${this.iconPosition}`;

    if (this.isBorder) {
      this.classNames += ` border-${this.borderColor} border-width-${this.borderWidth} `;
    }
  }
}
