<ng-container *transloco="let t">
  <div class="need-help-summary flex">
    <div class="content-container">
      <app-confirmation-section
        #section
        [hasHeaderMargin]="false"
        scope="helpSummary">
        <app-confirmation-section-record #record
          [position]="record.positions.full"
          [isSpace]="{ bottom: true }"
          [isDivider]="{ top: true }">
          <div [ngClass]="record.htmlClasses.label">
            {{organizationName}}
          </div>
          <div [ngClass]="record.htmlClasses.value">
            {{resortAddress}}
          </div>
        </app-confirmation-section-record>
        <app-confirmation-section-record #record
          [isDivider]="{}"
          [isSpace]="{ bottom: true }"
          [position]="record.positions.full">
          <div [ngClass]="[record.htmlClasses.row, record.positions.full]">
            <div [ngClass]="record.htmlClasses.value">
              <img width="100%"
                loading="lazy"
                alt="hotel"
                [src]="firstResortImageSrc || ('noImage' | imagePath)" />
            </div>
          </div>
        </app-confirmation-section-record>
        <app-confirmation-section-record #record
          field="callUs"
          *ngIf="phone"
          [scope]="section.scope"
          [isGap]="false"
          [position]="record.positions.full">
          {{phone}}
        </app-confirmation-section-record>
        <app-confirmation-section-record #record
          field="emailUs"
          *ngIf="email"
          [position]="record.positions.full"
          [scope]="section.scope"
          [isGap]="false"
          [position]="record.positions.full"
          [isDivider]="{}">
          <a class="link-underlined"
            [href]="'mailto: ' + email">
            {{email}}
          </a>
        </app-confirmation-section-record>
      </app-confirmation-section>
    </div>
  </div>
</ng-container>