import { Component, HostBinding } from '@angular/core';
import { PackageService } from '@shared/services/package.service';
import { ComponentBase } from '@shared/base/component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { ResortAmenity } from '@shared/api/be-api.generated';
import { DataService } from '@shared/services/data.service';
import { resortAmenitiesInChange, resortAmenitiesOutChange } from '@shared/consts/animations';
import { ResortAmenityTypeEnum } from '@shared/models/common';

@Component({
  selector: 'app-notification-amenities',
  templateUrl: './notification-amenities.component.html',
  styleUrls: ['./notification-amenities.component.scss'],
  animations: [resortAmenitiesInChange, resortAmenitiesOutChange]
})
export class NotificationAmenitiesComponent extends ComponentBase {
  @HostBinding('class.hide-if-empty') hideIfEmpty = true;
  isOpened = false;
  primaryAmenities: ResortAmenity[] = [];
  secondaryAmenities: ResortAmenity[] = [];
  imageUrl?: string;
  totalSavings = 0;
  tradingName?: string;

  constructor(
    private readonly _package: PackageService,
    private readonly _data: DataService,
    services: ComponentBaseService
  ) {
    super(services);
    this.pushSub(
      this._package.setWhenDataChanged(({
        resort: {
          amenitiesData: {
            amenities = [],
            imageUrl = undefined,
            totalSavings = 0
          } = {}
        } = {}
      }) => {
        this.primaryAmenities = amenities.filter(({ amenityType }) =>
          amenityType === ResortAmenityTypeEnum.Primary);

        this.secondaryAmenities = amenities.filter(({ amenityType }) =>
          amenityType === ResortAmenityTypeEnum.Secondary);

        this.imageUrl = imageUrl;
        this.totalSavings = totalSavings;
      }),
      this._data.setWhenInitialized(data => this.tradingName = data.settings.tradingName)
    );
  }

  toggle() {
    this.isOpened = !this.isOpened;
  }
}
