<ng-container *transloco="let t">
  @if(!unavailabilityReason){
  <div class="airport-transfer-pricing default">
    @if(current){
    <div class="header"
      [class.with-price]="!unavailabilityReason">
      <div class="header-default uppercased">
        {{t('airportTransfer.pricingType.perPerson')}}
      </div>
      @if(current.price && current.price > 0){
      <div class="price-final">
        {{current.price | appCurrency}}
      </div>
      }
      @if(current.price === 0){
      <div class="price-final color-textSuccessPrice padding-less w-100">
        {{t('price.complimentary')}}
      </div>
      }
    </div>
    }
    <!-- @if (unavailabilityReason){
    <div class="price">
      <div class="not-found">
        {{t('price.'+ unavailabilityReason.code, { value: unavailabilityReason.value})}}
      </div>
    </div>
    }-->
  </div>
  }

</ng-container>