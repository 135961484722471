<ng-container *transloco="let t">
  <div class="member-profile flex-column bc-default w-100"
    #topRow>
    <app-bread-crumbs-v2
      class="padding-horizontal padding-top"
      [items]="breadCrumbs"
      section="member.profile">
    </app-bread-crumbs-v2>
    @switch (selectedMenuItem) {
    @case (profileMenuItems.traveller) {
    <app-traveller-profile-v2
      [profile]="traveller"
      (closed)="closeTravelerProfile()">
    </app-traveller-profile-v2>
    }
    @case (profileMenuItems.basic) {
    <app-member-basic-v2
      (closed)="toggleEdit(profileMenuItems.basic)">
    </app-member-basic-v2>
    }
    @case (profileMenuItems.contact) {
    <app-member-contact-v2
      (closed)="toggleEdit(profileMenuItems.contact)">
    </app-member-contact-v2>
    }
    @default {
    <ng-container *ngTemplateOutlet="profileView"></ng-container>
    }
    }
    <ng-template #profileView>
      <div class="flex-column">
        <div class="flex-column">
          <div class="flex-column padding-horizontal">
            <h2>{{t('member.profile.basic.header')}}</h2>
            <div class="flex-between-start gap">
              <span>{{t('member.profile.basic.subheader')}}</span>
              <span class="link capitalized"
                (click)="toggleEdit(profileMenuItems.basic)">
                {{t('member.edit')}}
              </span>
            </div>
          </div>
          <div class="padding info-item-container">
            @for (item of basic; track $index) {
            <ng-container *ngTemplateOutlet="infoBlock; context: {
              key: item.label,
              value: item.value,
              prefix: '.basic.'
            }"></ng-container>
            }
          </div>

          <div class="flex-column padding-horizontal">
            <h2>{{t('member.profile.contact.header')}}</h2>
            <div class="flex-between-start gap">
              <span>{{t('member.profile.contact.subheader')}}</span>
              <span class="link capitalized"
                (click)="toggleEdit(profileMenuItems.contact)">
                {{t('member.edit')}}
              </span>
            </div>
          </div>
          <div class="padding info-item-container">
            @for (item of contact; track $index) {
            <ng-container *ngTemplateOutlet="infoBlock; context: {
              key: item.label,
              value: item.value,
              prefix: '.contact.'
            }"></ng-container>
            }
          </div>
        </div>
        <div class="flex-between-start gap padding-horizontal padding-bottom flex-column-tablet-max">
          <!-- <div class="flex-column flex-1">
            <h2>{{t('member.profile.more.header')}}</h2>
            <div class="flex-between-start gap">
              <span>{{t('member.profile.more.subheader')}}</span>
            </div>
            <div class="flex-column padding-top gap">
              <span class="link">{{t('member.profile.more.documents')}}</span>
              <span>{{t('member.profile.more.passport')}}</span>
            </div>
          </div> -->
          <div class="flex-column flex-1">
            <h2>{{t('member.profile.travelers.header')}}</h2>
            <div class="flex-between-start gap">
              <span>{{t('member.profile.travelers.subheader')}}</span>
              <span class="link capitalized"
                (click)="selectTraveller()">
                {{t('member.profile.travelers.add')}}
              </span>
            </div>
            @if (travelers.length){
            <div class="flex-column margin-top gap">
              @for (traveler of travelers; track $index) {
              <span class="link"
                (click)="selectTraveller(traveler)">
                {{traveler.name}}
              </span>
              }
            </div>
            }
            @else if (isLoadTravelers) {
            <div class="flex"><app-loading-icon size="button"></app-loading-icon></div>
            }
            @else if (errorLoadTravellers) {
            <div class="flex color-textError fw-500" [innerHTML]="t('loader.tryAgain', { value: errorLoadTravellers}) | safe: 'html'"></div>
            }
          </div>
        </div>
      </div>
    </ng-template>
  </div>

  <ng-template #infoBlock
    let-prefix="prefix"
    let-key="key"
    let-value="value">
    <div class="flex-column padding-top-half info-item-block">
      <span class="fw-600">{{t('member.profile' + prefix + key)}}</span>
      <span>{{value || t('member.notProvided')}}</span>
    </div>
  </ng-template>
</ng-container>
