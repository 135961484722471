<ng-container *transloco="let t">
  <div class="flex-column full-size">
    <div class="header flex-column bc-default">
      <div class="flex-between">
        <app-info-label
          [text]="ratePlanType?.name"
          type="ratePlanType">
        </app-info-label>
        <app-icon-button
          class="bs-right"
          stroked="iconInactive"
          buttonClass="close-button"
          (click)="closeSidebar()"
          name="close">
        </app-icon-button>
      </div>
      @if (currentRoomType) {
      <div class="flex-between">
        <div class="text-1_5-1_75-600">
          {{currentRoomType.name}}
        </div>
      </div>
      }
    </div>
    <div class="padding-vertical full-size overflow-auto">
      @if (images.length) {
      <app-gallery
        class="photos block"
        [images]="images">
      </app-gallery>
      }
      @if (currentRoomType) {
      <div class="margin-vertical text-1-1_5-400">
        {{currentRoomType.description}}
      </div>
      }
      @for (amenity of amenities; track amenity.id) {
      <div class="padding-left">
        <div class="text-0_875-1_5-600 list-item">
          {{amenity.item}}
        </div>
      </div>
      }
      @if (flight) {
      <div class="flex-column hidden-max-mobile">
        <div class="text-1_25-1_75-600 margin-vertical">
          {{t('flight.selected')}}
        </div>
        <div class="flex-start text-1-1_5-400 padding-bottom">
          <div>
            {{t('flight.doNotLike')}}
            <div class="link inline fw-500"
              (click)="selectFlight()">
              {{t('flight.choose')}}
            </div>
          </div>
        </div>
        <app-flight-details
          class="block"
          [flight]="flight">
        </app-flight-details>
      </div>
      }
      <div>
        <div class="padding-vertical text-1_25-1_75-600">
          {{t('package.whatIncluded')}}
        </div>
        <div class="text-0_875-1_25-400">
          {{resort?.description}}
        </div>
        @if (nonMonetary.length) {
        <div #bonus
          [class.color-selected]="showBonus"
          class="margin-vertical text-1_25-1_75-600">
          {{t('nonMonetary.header')}}
        </div>
        <app-non-monetary-v2
          [nonMonetary]="nonMonetary">
        </app-non-monetary-v2>
        }
      </div>
    </div>
  </div>
</ng-container>