import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { AccountLayoutComponent } from './components/account-layout/account-layout.component';
import { AccountLayoutItemComponent } from './components/account-layout-item/account-layout-item.component';
import { PromoCodeComponent } from './components/promo-code/promo-code.component';
import { TravelAgentComponent } from './components/travel-agent/travel-agent.component';

@NgModule({
  declarations: [
    AccountLayoutComponent,
    AccountLayoutItemComponent,
    PromoCodeComponent,
    TravelAgentComponent
  ],
  imports: [
    SharedModule
  ],
  providers: [
  ],
  exports: [
    AccountLayoutComponent
  ]
})
export class AccountModule { }
