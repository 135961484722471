
import { SearchContext } from '@shared/api/be-api.generated';
import { createMachine } from 'xstate';
import { machines } from '../state/state.mapping';
import { SearchEvents } from './search-context.types';

const searchSM = createMachine({
  predictableActionArguments: false,
  id: machines.search.id,
  tsTypes: {} as import('./search.sm.typegen').Typegen0,
  schema: {
    events: {} as SearchEvents,
    context: {} as SearchContext
  },
  context: {} as SearchContext,
  initial: 'unInitialized',
  states: {
    unInitialized: {
      on: {
        INITIALIZE: { target: 'loading', actions: ['initialize'] }
      }
    },
    idle: {
      entry: ['entryIdle'],
      on: {
        INITIALIZE: { target: 'loading', actions: ['initialize'] },
        SWITCH_RATE_PLAN_TYPE: { target: 'loading', actions: ['switchRatePlanType'] },
        SWAP_AIRPORTS: { target: 'loading', actions: ['swapAirports'] },
        SET_AIRPORTS: { target: 'loading', actions: ['setAirports'] },
        SET_DATE_RANGE: { target: 'loading', actions: ['setDateRange'] },
        SET_PROMOCODE: { target: 'loading', actions: ['setPromocode'] },
        APPLY_HOTEL_FILTER: { target: 'loading', actions: ['applyHotelFilter'] },
        LOAD_MORE: { target: 'loading', actions: ['loadMore'] },
        PATCH_FLIGHT_SEARCH: { target: 'loading', actions: ['patchFlightSearch'] },
        LOAD_PACKAGES_PRICES: { target: 'backgroundLoading', actions: ['loadPackagesPrices'] },
        APPLY_SEARCH: { target: 'loading', actions: ['applySearch'] },
        SET_MEMBER: { target: 'loading', actions: ['setMember'] }
      }
    },
    backgroundLoading: {
      on: {
        SET_PACKAGES_PRICES_RESULT: {
          target: 'idle',
          actions: ['setPackagesPricesResult'],
        }
      }
    },
    loading: {
      invoke: {
        src: 'runSearch',
        onDone: 'idle',
        onError: {
          target: 'error',
          actions: ['setSearchError']
        }
      },
      on: {
        SET_SEARCH_RESULT: {
          actions: ['setSearchResult'],
        },
        APPEND_SEARCH_RESULT: {
          actions: ['appendSearchResult'],
        },
      }
    },
    error: {
      on: {
        INITIALIZE: {
          target: 'loading',
          actions: ['initialize']
        }
      }
    }
  }
});

export default searchSM;
