<ng-container *transloco="let t">
  <div class="notification-amenities flex"
    *ngIf="primaryAmenities.length">
    <div class="main-container">
      <app-notification
        class="padding-less"
        position="start"
        [backgroundColor]="isOpened ? 'default' : 'amenitiesBar'"
        borderColor="amenitiesBar"
        borderWidth="thick">
        <div
          [class.collapsed]="isOpened"
          class="collapsable">
          <div class="notification-amenities-caption fixed-height cursor-pointer"
            (click)="toggle()">
            <div class="hidden-desktop-min">
              {{t('notification.amenities.captionShort', { value: (totalSavings | appCurrency)})}}
            </div>
            <div class="hidden-desktop-max">
              {{t('notification.amenities.captionFull', { value: (totalSavings | appCurrency)})}}
            </div>
            <button
              mat-raised-button
              class="white-button">
              {{t('notification.amenities.showMe')}}
            </button>
          </div>
        </div>

        <div [class.collapsed]="!isOpened"
          class="collapsable">
          <div class="flex-column notification-amenities-header">
            <div class="notification-amenities-caption">
              <span class="link hidden-desktop-min three-dots"
                (click)="toggle()">
                {{t('notification.amenities.captionShort')}}
              </span>
              <span class="link hidden-desktop-max"
                (click)="toggle()"
                *ngIf="tradingName">
                {{t('notification.amenities.exclusiveTo', { value: tradingName })}}
              </span>
              <app-icon-button
                name="up"
                stroked="textPrimary"
                buttonSize="custom"
                (click)="toggle()">
              </app-icon-button>
            </div>
            <div class="hidden-desktop-max divider-dashed"></div>
          </div>
          <div class="notification-amenities-content">
            <div class="notification-amenities-image">
              <img [src]="imageUrl || ('noImage' | imagePath)"
                loading="lazy"
                [alt]="t('notification.amenities.image')">
            </div>
            <div class="notification-amenities-list">
              <div class="notification-amenities-record"
                *ngFor="let amenity of primaryAmenities">
                <div>
                  <b>{{ amenity.name }}</b>
                  <span *ngIf="amenity.description">
                    {{ ' (' + amenity.description + ')' }}
                  </span>
                </div>
                <app-icon
                  name="checkmark"
                  filled="successText"
                  size="small">
                </app-icon>
              </div>

              <div *ngIf="secondaryAmenities.length">
                <div class="divider-dashed"></div>
                <div class="notification-amenities-caption">
                  <span class="link">{{t('notification.amenities.alsoIncluded')}}</span>
                </div>
                <div class="divider-dashed"></div>
              </div>

              <div *ngFor="let amenity of secondaryAmenities; let i = index;"
                class="notification-amenities-record"
                [class.last]="i === secondaryAmenities.length - 1">
                <b>{{ amenity.name }}</b>
                <app-icon
                  name="checkmark"
                  filled="successText"
                  size="small">
                </app-icon>
              </div>

              <div class="divider-dashed"></div>
              <div class="notification-amenities-caption">
                <div>
                  <div class="link uppercased">
                    {{t('notification.amenities.totalSavings')}}
                  </div>
                  <div class="mat-desktop uppercased">
                    {{t('notification.amenities.totalSavingsDescription')}}
                  </div>
                </div>
                <div class="total-savings">{{ (totalSavings | appCurrency) }} !!</div>
              </div>
            </div>
          </div>
        </div>
      </app-notification>
    </div>
  </div>
</ng-container>