<ng-container *transloco="let t">
  <mat-drawer-container
    [class]="'sidebar-panel-' + sidebarWidth"
    [class.only-backdrop]="!template"
    [hasBackdrop]="true">
    <mat-drawer #drawer
      [ngClass]="['bc-' + sidebarBackgroundColor]"
      mode="over"
      [position]="position || 'start'">
      <ng-container *ngIf="template">
        <ng-template [ngTemplateOutlet]="template"></ng-template>
      </ng-container>
    </mat-drawer>
    <mat-drawer-content>
      <app-anchor type="layoutTop"></app-anchor>
      @if (isPackagesInitialized){
      <router-outlet></router-outlet>
      }
    </mat-drawer-content>
  </mat-drawer-container>

  <div [hidden] id="anchor-layout">
    <!--this need to keep in order to save anchor styles from purgeCSS-->
  </div>

  @if (isLoading && !isPackagesInitialized){
  <app-loading-screen
    [section]="loaderSection"
    [resortLogo]="isOnSearchPage ? resortLogo : undefined">
  </app-loading-screen>
  }

  @if (errorString){
  <app-error [errorString]="errorString"></app-error>
  }

  <ng-template #account>
    <app-account-layout class="flex-column flex-1"></app-account-layout>
  </ng-template>

  <ng-template #cart>
    <app-sidebar-layout
      scope="cart"
      #cartSidebar>
      <ng-template #header>
        <div class="sidebar-layout-header">
          <div class="sidebar-layout-header-text">
            <h2>{{t('sidebar.cart.header')}}</h2>
            <app-icon-button
              class="bs-right"
              stroked="iconInactive"
              buttonClass="close-button"
              (click)="cartSidebar.close()"
              name="close">
            </app-icon-button>
          </div>
          <div class="sidebar-layout-subheader">
            <p>{{t('sidebar.cart.subheader.' + currentCartHeaderPostfix)}}</p>
          </div>
        </div>
      </ng-template>
      <ng-template #content>
        <div class="sidebar-scroll-container">
          <app-cart-viewer
            [isOnlyMobileView]="true"
            [isVisibleHeader]="false">
          </app-cart-viewer>
          <app-cart-total></app-cart-total>
        </div>
      </ng-template>
      <ng-template #footer>
        <app-global-booking-button></app-global-booking-button>
      </ng-template>
    </app-sidebar-layout>
  </ng-template>

  <ng-template #hotelFilter>
    <app-sidebar-layout scope="filter">
      <ng-template #content>
        <app-hotel-filter class="sidebar-scroll-container"></app-hotel-filter>
      </ng-template>
    </app-sidebar-layout>
  </ng-template>

  <ng-template #member>
    <app-member-layout-v2></app-member-layout-v2>
  </ng-template>
</ng-container>
