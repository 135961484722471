import { Inject, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { Page, pages } from '@shared/models/common';
import { TenantService } from './tenant.service';
import { DOCUMENT } from '@angular/common';

const {
  results, booking, confirmation,
  search, reservation, verification
} = pages;

const fallbackPage = results;

@Injectable()
export class NavigationService {
  constructor(
    private readonly _router: Router,
    public readonly tenant: TenantService,
    @Inject(DOCUMENT) @Optional() public document: Document,
  ) {
  }

  get isTest() {
    return this.document.location.search.toLowerCase().indexOf('istest=true') > -1;
  }

  get nextPage(): Page {
    const current = this.getCurrentPage();

    const all = {
      [results]: booking,
      [booking]: confirmation,
      [confirmation]: results,

      [search]: reservation,
      [reservation]: verification,
      [verification]: search
    }

    return current ? all[current] : fallbackPage;
  }

  get prevPage(): Page {
    const current = this.getCurrentPage();

    const all = {
      [results]: results,
      [booking]: results,
      [confirmation]: booking,

      [search]: search,
      [reservation]: search,
      [verification]: reservation
    }

    return current ? all[current] : fallbackPage;
  }

  openNext(...params: string[]) {
    this.open(this.nextPage, ...params);
  }

  openPrev(...params: string[]) {
    this.open(this.prevPage, ...params);
  }

  openSearch() {
    switch (this.getCurrentPage()) {
      case pages.booking:
      case pages.confirmation:
        this.open(pages.results);
        break;
      case pages.reservation:
      case pages.verification:
        this.open(pages.search);
        break;
    }
  }

  open(destination: Page, ...params: string[]) {
    this._router.navigate([
      [this.tenant.id, destination, ...params].filter(x => x).join('/')
    ], {
      queryParamsHandling: 'preserve'
    });
  }

  getCurrentPage = (): Page | undefined => {
    const pathParts = this.document.location.pathname.split('/');
    return pathParts.length > 2 ? pathParts[2] as Page : undefined;
  }

  isPage = (page: Page) => page === this.getCurrentPage();
}
