import { NgModule } from '@angular/core';
import { DataService } from './data.service';
import { AddOnService } from './add-on.service';
import { TenantService } from './tenant.service';
import { AddressService } from './address.service';
import { PackageService } from './package.service';
import { PricingService } from './pricing.service';
import { TrackingService } from './tracking.service';
import { NavigationService } from './navigation.service';
import { TravelAgentService } from './travel-agent.service';
import { QueryParamsService } from './query-params.service';
import { ComponentBaseService } from './component-base.service';
import { ComponentsCommunicationService } from './components-communication.service';

@NgModule({
  providers: [
    DataService,
    AddOnService,
    TenantService,
    AddressService,
    PackageService,
    PricingService,
    TrackingService,
    NavigationService,
    TravelAgentService,
    QueryParamsService,
    ComponentBaseService,
    ComponentsCommunicationService,
  ]
})
export class ServiceModule { }
