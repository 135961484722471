import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgImageSliderModule } from 'ng-image-slider';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

import { PipeModule } from '../pipes/pipe.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ServiceModule } from './services/service.module';
import { MaterialModule } from '../material/material.module';
import { TranslocoRootModule } from '../transloco-root.module';

import { DefaultHeadersInterceptor } from './interceptors/default-headers.interceptor';
import { BookingEngineClient } from './api/be-api.generated';

import { InitDirective } from '../directives/init.directive';
import { TargetBlankDirective } from '../directives/target-blank.directive';

import { IconButtonComponent } from './components/button/icon-button/icon-button.component';
import { SelectButtonComponent } from './components/button/select-button/select-button.component';

import { CartTotalComponent } from './components/cart/cart-total/cart-total.component';
import { CartViewerComponent } from './components/cart/cart-viewer/cart-viewer.component';

import { FormComponent } from './components/common/form/form.component';
import { IconComponent } from './components/common/icon/icon.component';
import { AnchorComponent } from './components/common/anchor/anchor.component';
import { InfoLabelComponent } from './components/common/info-label/info-label.component';
import { PaymentCardsComponent } from './components/common/payment-cards/payment-cards.component';
import { SectionTogglerComponent } from './components/common/section-toggler/section-toggler.component';
import { SpecialOfferCaptionComponent } from './components/special-offers/special-offer-caption/special-offer-caption.component';

import { FlightJourneyComponent } from './components/flight/flight-journey/flight-journey.component';

import { ErrorComponent } from './components/layout/error/error.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { NotFoundComponent } from './components/layout/not-found/not-found.component';
import { TwoColumnsComponent } from './components/layout/two-columns/two-columns.component';
import { LoadingIconComponent } from './components/layout/loading-icon/loading-icon.component';
import { HotelFilterComponent } from './components/layout/hotel-filter/hotel-filter.component';
import { LoadingScreenComponent } from './components/layout/loading-screen/loading-screen.component';
import { ModalPanelV2Component } from './components-v2/common/modal-panel/modal-panel.component';
import { SidebarLayoutComponent } from './components/layout/sidebar-layout/sidebar-layout.component';

import { SpecialOfferCaptionPrimaryComponent } from './components/special-offers/special-offer-caption-primary/special-offer-caption-primary.component';
import { SpecialOfferCaptionSecondaryComponent } from './components/special-offers/special-offer-caption-secondary/special-offer-caption-secondary.component';

import { HeaderV2Component } from './components-v2/layout/header/header.component';
import { SelectTabV2Component } from './components-v2/common/select-tab/select-tab.component';
import { RecommendationBarV2Component } from './components-v2/layout/recommendation-bar/recommendation-bar.component';
import { CartViewerV2Component } from './components-v2/cart/cart-viewer/cart-viewer.component';
import { BreadCrumbsV2Component } from './components-v2/common/bread-crumbs/bread-crumbs.component';
import { NonMonetaryV2Component } from './components-v2/cart/non-monetary/non-monetary.component';

import { NotificationComponent } from './components/notification/notification/notification.component';
import { NotificationErrorComponent } from './components/notification/notification-error/notification-error.component';
import { NotificationSignupComponent } from './components/notification/notification-signup/notification-signup.component';
import { NotificationPricesComponent } from './components/notification/notification-prices/notification-prices.component';
import { NotificationTravelComponent } from './components/notification/notification-travel/notification-travel.component';
import { NotificationBookingComponent } from './components/notification/notification-booking/notification-booking.component';
import { NotificationWarningComponent } from './components/notification/notification-warning/notification-warning.component';
import { NotificationWarningsComponent } from './components/notification/notification-warnings/notification-warnings.component';
import { NotificationPassengerComponent } from './components/notification/notification-passenger/notification-passenger.component';
import { NotificationAmenitiesComponent } from './components/notification/notification-amenities/notification-amenities.component';


const components = [
  IconButtonComponent,
  SelectButtonComponent,

  CartViewerComponent,
  CartTotalComponent,
  NonMonetaryV2Component,

  SpecialOfferCaptionComponent,
  SectionTogglerComponent,
  PaymentCardsComponent,
  InfoLabelComponent,
  AnchorComponent,
  IconComponent,
  FormComponent,

  FlightJourneyComponent,

  SidebarLayoutComponent,
  LoadingScreenComponent,
  HotelFilterComponent,
  LoadingIconComponent,
  TwoColumnsComponent,
  NotFoundComponent,
  HeaderComponent,
  FooterComponent,
  ErrorComponent,

  SpecialOfferCaptionPrimaryComponent,
  SpecialOfferCaptionSecondaryComponent,
];

const directives = [
  InitDirective,
  TargetBlankDirective,
];

const notificationComponents = [
  NotificationComponent,
  NotificationErrorComponent,
  NotificationSignupComponent,
  NotificationPricesComponent,
  NotificationTravelComponent,
  NotificationBookingComponent,
  NotificationWarningComponent,
  NotificationWarningsComponent,
  NotificationPassengerComponent,
  NotificationAmenitiesComponent,
];

const componentsV2 = [
  HeaderV2Component,
  SelectTabV2Component,
  ModalPanelV2Component,
  CartViewerV2Component,
  BreadCrumbsV2Component,
  RecommendationBarV2Component,
];

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  TranslocoRootModule,
  ScrollingModule,
  MaterialModule,
  PipeModule,
  NgImageSliderModule,
];

@NgModule({
  declarations: [
    ...components,
    ...directives,
    ...notificationComponents,
    ...componentsV2
  ],
  imports: [
    ServiceModule,
    ...modules,
  ],
  providers: [
    BookingEngineClient,
    SsrCookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultHeadersInterceptor,
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi())
  ],
  exports: [
    ...modules,
    ...components,
    ...directives,
    ...notificationComponents,
    ...componentsV2
  ]
})
export class SharedModule { }
