import { Component } from '@angular/core';
import { ComponentBase } from '@shared/base/component.base';
import { UnavailabilityReasonCode } from '@shared/models/common';
import { BlockOverlayService } from '@shared/services/block-overlay.service';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { ComponentsCommunicationService } from '@shared/services/components-communication.service';
import { PackageService } from '@shared/services/package.service';
import { SearchService } from '@shared/services/search/search.service';

@Component({
  selector: 'app-search-layout-v2',
  templateUrl: './search-layout.component.html',
  styleUrls: ['./search-layout.component.scss']
})
export class SearchLayoutV2Component extends ComponentBase {
  constructor(
    comm: ComponentsCommunicationService,
    blockOverlay: BlockOverlayService,
    services: ComponentBaseService,
    _package: PackageService,
    search: SearchService,
  ) {
    super(services);

    this.pushSub(
      comm.flightsEnabled.subscribe(result => this.isRequestedFlights = result),
      search.setWhenContextChanged(() => this.isRequestedFlights = false),
      _package.setWhenDataChanged(({ roomTypePackages }) => {
        this.nights = _package.periodNights;

        switch (roomTypePackages?.unavailabilityReason?.code as UnavailabilityReasonCode) {
          case 'MinStayPolicy':
            this.minimumStay = roomTypePackages?.unavailabilityReason?.value;
            break;
          default:
            this.minimumStay = undefined;
        }
      })
    );

    blockOverlay.isOpened && blockOverlay.close();
  }

  isRequestedFlights = false;
  minimumStay?: string;
  nights?: number;
}
