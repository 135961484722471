import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AirportTransfer } from '@shared/api/be-api.generated';
import { UnavailabilityReason } from '@shared/models/common';

@Component({
  selector: 'app-airport-transfer-pricing',
  templateUrl: './airport-transfer-pricing.component.html',
  styleUrls: ['./airport-transfer-pricing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AirportTransferPricingComponent {
  @Input() current!: AirportTransfer;
  @Input() unavailabilityReason?: UnavailabilityReason;
}
