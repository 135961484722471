import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/core';
import Logger from 'js-logger';

@Injectable()
export class TransferStateService {
  constructor(
    private _transferState: TransferState
  ) { }

  set<T>(key: string, data: T) {
    Logger.trace('Setting TransferState with key:', key, ', data:', data);
    this._transferState.set(makeStateKey<T>(key), data);
  }

  hasKey(key: string) {
    return this._transferState.hasKey(makeStateKey(key));
  }

  get<T>(key: string, defaultValue?: T) {
    Logger.trace('Reading from TransferState with key:', key);
    const stateKey = makeStateKey<T>(key);
    return this._transferState.hasKey(stateKey)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ? this._transferState.get<T>(stateKey, defaultValue!)
      : defaultValue;
  }
}
