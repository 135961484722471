import { Component } from '@angular/core';

import { ComponentBase } from '@shared/base/component.base';

import { BookingService } from '@booking/services/booking.service';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { QueryParamsService } from '@shared/services/query-params.service';
import { first } from 'rxjs';
import Logger from 'js-logger';

@Component({
  selector: 'app-booking-layout',
  templateUrl: './booking-layout.component.html'
})
export class BookingLayoutComponent extends ComponentBase {
  isBookingInitialized = !this._queryParams.value.cartEmail || this._booking.isInitialized;

  constructor(
    private readonly _booking: BookingService,
    private readonly _queryParams: QueryParamsService,
    services: ComponentBaseService
  ) {
    super(services);
    this._booking.clear();

    const runInititalization = () => this._booking.initialize()
      .subscribe(() => this.isBookingInitialized = true);

    if (this._queryParams.value.cartEmail) {
      this._booking.restoreCartRequest(this._queryParams.value.cartEmail)
        .pipe(first())
        .subscribe({ next: () => Logger.info('Cart restored') })
        .add(() => runInititalization());
    } else {
      runInititalization();
    }
  }
}
