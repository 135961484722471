import { Component, ContentChild, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-select-button',
  templateUrl: './select-button.component.html',
  styleUrls: ['./select-button.component.scss']
})
export class SelectButtonComponent implements OnInit, OnChanges {
  @Input() size: 'big' | 'default' = 'big';
  @Input() disabled!: boolean;
  @Input() text?: string;
  @Input() blocked = false;
  @Input() active!: boolean;
  @ContentChild('caption') captionContent?: TemplateRef<unknown>;


  buttonClass!: string;
  name!: 'checked' | 'unchecked';
  caption!: 'button.selected' | 'button.select';
  activity!: 'active' | '';

  ngOnInit(): void {
    this.build();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const active = changes['active'];
    if (active && active.currentValue !== active.previousValue) {
      this.build();
    }
  }

  build() {
    this.name = this.active ? 'checked' : 'unchecked';
    this.activity = this.active ? 'active' : '';
    this.buttonClass = `select-button select-icon ${this.activity} ${this.size} ${this.blocked ? 'blocked' : ''}`;
    this.caption = this.active ? 'button.selected' : 'button.select';
  }
}
