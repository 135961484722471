<ng-container *transloco="let t">
  <div class="full-screen-container">
    <div class="centered">
      <app-icon
        name="info"
        size="big"
        filled="selected"
        stroked="default">
      </app-icon>
      <div class="section-header">
        <div class="section-header-text text-center">
          {{t('page.notFound.header')}}
        </div>
      </div>
      <div class="section-subheader text-center">
        {{t('page.notFound.subheader')}}
      </div>
    </div>
  </div>
</ng-container>