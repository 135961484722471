import { Component } from '@angular/core';
import { ComponentBase } from '@shared/base/component.base';
import { BookingService } from '@booking/services/booking.service';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { QueryParamsService } from '@shared/services/query-params.service';
import { first } from 'rxjs';
import Logger from 'js-logger';
import { PackageService } from '@shared/services/package.service';
import { MemberService } from '@member/member.service';

@Component({
  selector: 'app-booking-layout-v2',
  templateUrl: './booking-layout.component.html'
})
export class BookingLayoutV2Component extends ComponentBase {
  constructor(
    private readonly _booking: BookingService,
    private readonly _package: PackageService,
    private readonly _queryParams: QueryParamsService,
    private readonly _member: MemberService,
    services: ComponentBaseService
  ) {
    super(services);
    this._booking.clear();

    const runInititalization = () => this.pushSub(
      this._booking.initialize().subscribe(() => this.isBookingInitialized = true));

    if (this._queryParams.value.cartEmail) {
      this.pushSub(
        this._booking.restoreCartRequest(this._queryParams.value.cartEmail).pipe(first()).subscribe({
          next: () => {
            Logger.info('Cart restored');
            runInititalization();
          }
        })
      );
    } else {
      runInititalization();
    }

    this.pushSub(
      _booking.startedVerification.subscribe(() => {
        if (this.profile) {
          _booking.updateVerificationStatus('manageBooking', true, undefined);
        }
      })
    );
  }

  isBookingInitialized = !this._queryParams.value.cartEmail || this._booking.isInitialized;
  bookOnlinePhone = this._package.resort?.mainContactPhone;
  profile = this._member.profile;
}
