import { ChangeDetectionStrategy, Component, ContentChild, HostBinding, Input, TemplateRef } from '@angular/core';
import { ComponentBase } from '@shared/base/component.base';
import { Color, IndentSize, Position } from '@shared/models/common';
import { ComponentBaseService } from '@shared/services/component-base.service';

@Component({
  selector: 'app-modal-panel-v2',
  templateUrl: './modal-panel.component.html',
  styleUrls: ['./modal-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalPanelV2Component extends ComponentBase {
  constructor(
    services: ComponentBaseService,
  ) {
    super(services);
  }

  classNames: string[] = [];

  @ContentChild('header') header?: TemplateRef<unknown>;
  @ContentChild('content') content?: TemplateRef<unknown>;
  @ContentChild('footer') footer?: TemplateRef<unknown>;

  @Input() @HostBinding('class.full-size') isFullSize = true;
  @HostBinding('class') get hostClasses() {
    return ['horizontal-' + this.positionHorizontal, 'vertical-' + this.positionVertical];
  }

  @Input() isActive = false;
  @Input() wrapClass = 'modal-panel-component';
  @Input() positionVertical: Position = 'center';
  @Input() positionHorizontal: Position = 'center';
  @Input() overlayColor?: Color;
  @Input() paddingSize: IndentSize = 'default';

  closeBanner(target: EventTarget | null) {
    if (!(target instanceof Element) || !target.closest('.' + this.wrapClass)) {
      this.hideBanner();
    }
  }

  hideBanner() {
    this.isActive = false;
  }
}
