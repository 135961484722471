<ng-container *transloco="let t">
  <div class="padding-top-half pricing-details">
    <ng-content></ng-content>
    @if(!config.hideButton){
    <div class="flex-end">
      <app-select-button
        [text]="config.text"
        [size]="size"
        [active]="config.active"
        [blocked]="config.blocked"
        [disabled]="config.disabled"
        (click)="onSelectClick()">
      </app-select-button>
    </div>
    }
  </div>
</ng-container>
