<ng-container *transloco="let t">
  @if(isVisible){
  <div class="notification-passenger flex">
    <div class="main-container">
      <app-notification
        [isThreeDots]="false"
        iconName="warning"
        iconSize="big"
        iconPosition="start"
        backgroundColor="notificationBackground"
        borderColor="notificationBorder"
        borderWidth="thick"
        stroked="notificationIcon">
        <div class="custom-html-content" appTargetBlank>
          <div class="notification-passenger-header"
            [innerHTML]="t('notification.passenger.header') | safe: 'html'">
          </div>
          @if(!isOnConfirmationPage){
          <app-icon-button
            class="bs-right notification-passenger-close"
            stroked="iconInactive"
            buttonClass="close-button"
            (click)="toggleVisiblity()"
            name="close">
          </app-icon-button>
          }
          <div class="notification-passenger-text"
            [innerHTML]="t('notification.passenger.text') | safe: 'html'">
          </div>
        </div>
        <div class="notification-passenger-button">
          <a mat-raised-button
            color="primary"
            appTargetBlank
            href="https://enterjamaica.com">
            {{t('notification.passenger.button')}}
          </a>
        </div>
      </app-notification>
    </div>
  </div>
  }
</ng-container>