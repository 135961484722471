import { Component, ViewChild } from '@angular/core';
import { BookingService } from '@booking/services/booking.service';
import { TranslocoService } from '@jsverse/transloco';
import { dateTimeToISO, ExistingFlights as ExistingFlights2, FlightDetails } from '@shared/api/be-api.generated';
import { ReservationVerificationSectionBase } from '@shared/base/reservation-verification-section.base';
import { getOptionsValues } from '@shared/common';
import { FormComponent } from '@shared/components/common/form/form.component';
import { AnchorType, SelectOption } from '@shared/models/common';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { DataService } from '@shared/services/data.service';
import { PackageService } from '@shared/services/package.service';
import { SearchService } from '@shared/services/search/search.service';
import { DateTime } from 'luxon';
import { merge } from 'rxjs';
import { AppCurrencyPipe } from 'src/app/pipes/app-currency.pipe';
import { JourneyOptionsSource, JourneyField, withoutTransferValue, JourneyFormGroups } from './flight-info.common';

@Component({
  selector: 'app-flight-info',
  templateUrl: './flight-info.component.html'
})
export class FlightInfoComponent extends ReservationVerificationSectionBase<JourneyField> {
  isSkipped = !this.isVisible;

  @ViewChild('inboundFC') inFC!: FormComponent<JourneyField>;
  @ViewChild('outboundFC') outFC!: FormComponent<JourneyField>;

  inbound = new JourneyOptionsSource(this._package, this._appCurrencyPipe, this._transloco);
  outbound = new JourneyOptionsSource(this._package, this._appCurrencyPipe, this._transloco);

  journeyFormGroups = new JourneyFormGroups(this._booking.cartRequest, this._package.resort?.airports?.map(item => item.code || ''));
  inForm = this.journeyFormGroups.inForm;
  outForm = this.journeyFormGroups.outForm;

  isInboundTransfersAirportSelected = false;
  isOutboundTransfersAirportSelected = false;

  scope = 'flightInfo';
  anchorType: AnchorType = 'flightInfo';
  anchorNumbers = {
    inbound: 'inbound',
    outbound: 'inbound',
  };

  get isVisible() {
    return !this._package.isFlightSelected && !!this._package.resort?.airportTransfers?.length;
  }

  get isValid() {
    return this.isSkipped || (this.inForm.valid && this.outForm.valid);
  }

  get anchorId() {
    const createAnchor = (journeyType: string, field: JourneyField) =>
      this.getAnchorId(this.anchorType, journeyType, field);

    const inboundTopInvalidField = this.inFC?.getTopInvalidField();
    if (inboundTopInvalidField) {
      return createAnchor(this.anchorNumbers.inbound, inboundTopInvalidField);
    }

    const outboundTopInvalidField = this.outFC?.getTopInvalidField();
    if (outboundTopInvalidField) {
      return createAnchor(this.anchorNumbers.outbound, outboundTopInvalidField);
    }

    return undefined;
  }

  constructor(
    private readonly _appCurrencyPipe: AppCurrencyPipe,
    private readonly _transloco: TranslocoService,
    private readonly _package: PackageService,
    search: SearchService,
    data: DataService,
    booking: BookingService,
    services: ComponentBaseService
  ) {
    super('flightInfo', booking, services);

    this.pushSub(
      search.setWhenContextChanged(({ resort }) => {
        this.inbound.setArrival(resort?.airports)
        this.inbound.setDeparture(data.values.airports, getOptionsValues(this.inbound.origins.arrival));
        this.outbound.setDeparture(resort?.airports);
        this.outbound.setArrival(data.values.airports, getOptionsValues(this.outbound.origins.departure));
      })
    );

    if (!this.isSkipped) {
      this.executeTest(() => this._testEnter());
    }
  }

  private createAutocompleteListeners(form: FormComponent<JourneyField>, { options, origins }: JourneyOptionsSource) {
    if (form?.autocomplete) {
      const { input, search } = form.autocomplete;
      this.pushSub(
        merge(input, search)
          .subscribe(field => {
            const value = form.getFormElement<HTMLInputElement>(field).value?.toLowerCase();
            const getFilteredOptions = (data: SelectOption[]) =>
              data.filter(({ label = '' }) => label.toString().toLowerCase().indexOf(value) > -1);

            switch (field) {
              case 'arrivalAirport': options.arrival = getFilteredOptions(origins.arrival); break;
              case 'departureAirport': options.departure = getFilteredOptions(origins.departure); break;
            }
          })
      );
    }
  }

  private _testEnter(): void {
    this.inbound.setAiportTransfers(this.inbound.origins
      .arrival.find(() => true)?.value?.toString() || '');
    this.inFC.set.flightNumber('fake value');
    this.inFC.setFirstOption('arrivalAirport', this.inbound.origins.arrival);
    this.inFC.selectChange('airportTransfer', this.inbound.options.transfer.find(() => true)?.value?.toString());

    this.outbound.setAiportTransfers(this.outbound.origins
      .departure.find(() => true)?.value?.toString() || '');
    this.outFC.setFirstOption('departureAirport', this.outbound.origins.departure);
    this.outFC.selectChange('airportTransfer', this.outbound.options.transfer.find(() => true)?.value?.toString());
  }

  selectChange(currentField: JourneyField, isInbound: boolean) {
    const source = isInbound ? this.inbound : this.outbound;
    const form = isInbound ? this.inFC : this.outFC;
    const fieldValue = form.get[currentField]<string>();
    const airportCodeField = isInbound ? 'arrivalAirport' : 'departureAirport'

    if (currentField === airportCodeField) {
      source.setAiportTransfers(fieldValue);
    }

    const setDefaultAirportTransfer = () => {
      if (source.options.transfer.length === 1) {
        form.selectChange('airportTransfer', source.options.transfer.find(() => true)?.value?.toString());
      }
    }

    switch (currentField) {
      case 'airportTransfer':
        const transfer = source
          .getAirportTransfer(form.get[airportCodeField]<string>(), fieldValue)

        isInbound
          ? this._package.useInboundAirportTransfer(transfer)
          : this._package.useOutboundAirportTransfer(transfer);
        break;
      case 'arrivalAirport':
        if (isInbound) {
          this.isInboundTransfersAirportSelected = !!form.get.arrivalAirport<string>();
          setDefaultAirportTransfer();
          if (!this.outFC.get.departureAirport()) {

            const option = this.inbound.options.arrival.find(item => item.value === fieldValue);
            if (option?.label) {
              this.outFC.getFormElement<HTMLInputElement>('departureAirport').value = option.label.toString();
              this.outFC.selectChange('departureAirport', option.value?.toString());
            }
          }
        }
        break;
      case 'departureAirport':
        if (!isInbound) {
          this.isOutboundTransfersAirportSelected = !!form.get.departureAirport<string>();
          setDefaultAirportTransfer();
        }
        break;
    }
  }

  skipFlightInfoToggle() {
    this.isSkipped = !this.isSkipped;

    if (this.isSkipped) {
      this._package.useInboundAirportTransfer(undefined);
      this._package.useOutboundAirportTransfer(undefined);
    } else {
      const inbound = this.inbound.getAirportTransfer(
        this.inFC.get.arrivalAirport(),
        this.inFC.get.airportTransfer()
      );

      this._package.useInboundAirportTransfer(inbound);

      const outbound = this.outbound.getAirportTransfer(
        this.outFC.get.departureAirport(),
        this.outFC.get.airportTransfer()
      );
      this._package.useOutboundAirportTransfer(outbound);
    }

    this.updateReservation();
    this._booking.saveCartRequest();
  }

  updateReservation() {
    const defaultGetter = <T>() => <T>undefined;
    const createFlightDetails = (fc: FormComponent<JourneyField>) => {
      const {
        airportTransfer = defaultGetter,
        arrivalAirport = defaultGetter,
        arrivalTime = defaultGetter,
        departureAirport = defaultGetter,
        departureTime = defaultGetter,
        flightNumber = defaultGetter
      } = fc.get;

      return airportTransfer() !== withoutTransferValue
        ? new FlightDetails({
          airportTransferId: airportTransfer() || undefined,
          flightNumber: flightNumber(),
          arrivalAirportCode: arrivalAirport(),
          arrivalDate: dateTimeToISO(arrivalTime<DateTime>() || undefined) || undefined,
          departureAirportCode: departureAirport(),
          departureDate: dateTimeToISO(departureTime<DateTime>() || undefined) || undefined,
        })
        : undefined;
    }

    this._booking.reservationInput.setExistingFlight(this.isSkipped
      ? undefined
      : new ExistingFlights2({
        inboundFlight: createFlightDetails(this.inFC),
        outboundFlight: createFlightDetails(this.outFC)
      }));
  }

  //#region base
  protected override verificationStarted() {
    this.inForm.markAllAsTouched();
    this.outForm.markAllAsTouched();

    this.updateReservation();
  }

  protected override afterViewInit(): void {
    this.createAutocompleteListeners(this.inFC, this.inbound);
    this.createAutocompleteListeners(this.outFC, this.outbound);

    if (this.cartRequest) {
      setTimeout(() => {
        if (this.inFC) {
          const inbound = this.cartRequest?.existingFlights?.inboundFlight;
          const arrivalAirportOption = this.inbound.origins.arrival
            .find(item => item.value === inbound?.arrivalAirportCode);
          if (arrivalAirportOption) {
            this.inbound.setAiportTransfers(arrivalAirportOption?.value?.toString() || '');
            this.inFC.setFirstOption('arrivalAirport', [arrivalAirportOption]);
            this.inFC.selectChange('airportTransfer', this.inbound.options.transfer
              .find(item => item.value === inbound?.airportTransferId)?.value?.toString());
          }
        }

        if (this.outFC) {
          const outbound = this.cartRequest?.existingFlights?.outboundFlight;
          const departureAirportOption = this.outbound.origins.departure
            .find(item => item.value === outbound?.departureAirportCode);
          if (departureAirportOption) {
            this.outbound.setAiportTransfers(departureAirportOption?.value?.toString() || '');
            this.outFC.setFirstOption('departureAirport', [departureAirportOption]);
            this.outFC.selectChange('airportTransfer', this.inbound.options.transfer
              .find(item => item.value === outbound?.airportTransferId)?.value?.toString());
          }
        }
      });
    }
  }

  protected override afterFormValueChanged(formIndex: number): void {
    if ((formIndex === 0 && this.inFC.form.valid) || (formIndex === 1 && this.outFC.form.valid)) {
      this.updateReservation();
    }
  }

  protected override getFormsComponents(): FormComponent<JourneyField>[] {
    return [this.inFC, this.outFC];
  }
  //#endregion
}
