<ng-container *transloco="let t">
  <app-icon-button
    [iconSize]="size"
    [customButtonSize]="true"
    [name]="name"
    [alt]="caption"
    [disabled]="disabled"
    type="raised"
    [buttonClass]="buttonClass"
    [transparent]="!active">
    <div class="caption"
      *ngIf="!captionContent">
      <div class="caption-main">
        {{t(caption)}}
      </div>
      @if (text){
      <div class="caption-sub">
        {{text}}
      </div>
      }
    </div>
    <ng-template
      *ngIf="captionContent"
      [ngTemplateOutlet]="captionContent">
    </ng-template>
  </app-icon-button>
</ng-container>