<ng-container *transloco="let t">
  <div class="guests full-size">
    <div class="section-header">
      <div class="section-header-text">
        {{t('guest.section.header')}}
      </div>
      <app-section-toggler
        #toggler
        [isOnlyForTablet]="false"
        [hidden]="true">
      </app-section-toggler>
    </div>
    <div class="collapsable"
      [class.collapsed]="toggler.isHidden">
      <div class="section-subheader">
        {{t('guest.section.subheader')}}
      </div>
      <app-guest-details
        #adultDetails
        (mainAdultCountryUpdated)="mainAdultCountryUpdated($event)"
        guestType="adult"
        [guestNumber]="1">
      </app-guest-details>
      <app-guest-details
        #adultDetails
        guestType="adult"
        *ngFor="let adultNumber of adults"
        [guestNumber]="adultNumber">
      </app-guest-details>
      <app-guest-details
        #childDetails
        guestType="child"
        *ngFor="let childNumber of children"
        [guestNumber]="childNumber">
      </app-guest-details>
      <div class="special-requirements">
        <div class="label">
          {{t('guest.customerNotes.header')}}
        </div>
        <div class="value">
          <mat-form-field
            class="textarea"
            appearance="outline">
            <textarea matInput
              (blur)="updateReservation()"
              [placeholder]="t('guest.customerNotes.placeholder')"
              [(ngModel)]="customerNotes">
            </textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</ng-container>