<ng-container *transloco="let t">
  <div class="select-option box-shadow position-relative">
    <div class="select-option-photos">
      <app-info-label
        [text]="t('member.banner.rewards')"
        type="memberDefault">
      </app-info-label>
      <img [src]="'memberBanner' | imagePath" alt="Membership Banner">
    </div>
    <div class="select-option-description bc-memberBannerBackground">
      <div class="flex-column gap padding-top">
        <div class="text-1_125-1_125-600">{{t('member.banner.header')}}</div>
        <button mat-raised-button class="select-button next-button w-100 padding-vertical-quarter bold" color="primary"
          (click)="open()">
          {{ t('member.signIn.button') }}
        </button>
        <div class="text-0_75-0_75-500">{{t('member.banner.subheader')}}</div>
      </div>
    </div>
    <div class="select-option-footer bc-memberBannerBackground"></div>
  </div>
</ng-container>