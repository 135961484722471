<ng-container *transloco="let t">
  <div class="flex-column padding-horizontal flex-1">
    <app-form
      #form
      [form]="profileForm"
      scope="member.profile.basic">
      <ng-template #content
        let-text="text"
        let-select="select"
        let-date="date">
        <div class="flex-column">
          @for (field of fields; track $index) {
          <div class="flex-column">
            <ng-container *ngTemplateOutlet="
              fieldsOptions[field]
                ? select
                : dateFields[field]
                  ? date
                  : text;
              context: {
                field,
                options: fieldsOptions[field],
                valueFormat: valueFormats[field]
              }">
            </ng-container>
          </div>
          }
        </div>
      </ng-template>
    </app-form>

    <div class="flex-end gap margin-vertical flex-wrap">
      @if(errorMessage){
      <div class="flex-start error-message margin-right-auto">
        {{errorMessage}}
      </div>
      }
      <button mat-raised-button
        [disabled]="isLoading"
        class="select-button"
        (click)="close()">
        {{t('button.cancel')}}
      </button>
      <button mat-raised-button
        [disabled]=" isLoading"
        color="primary"
        class="select-button"
        (click)="save()">
        @if (isLoading) {
        <app-loading-icon size="button"></app-loading-icon>
        &nbsp;
        }
        {{t('button.apply')}}
      </button>
    </div>
  </div>
</ng-container>