import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Observable } from 'rxjs';
import { MemberAuthService } from './member.auth.service';

@Injectable()
export class MemberInterceptor implements HttpInterceptor {
  constructor(
    private readonly _cookie: SsrCookieService
  ) {
  }

  readonly allowedUrls = ['/member', '/packages', '/booking'];

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.allowedUrls.some(urlPart => request.url.indexOf(urlPart) > -1)) {
      const accessToken = this._cookie.get(MemberAuthService.Token);
      if (accessToken) {
        request = request.clone({
          setHeaders: { ['Authorization']: 'Bearer ' + accessToken }
        });
      }
    }

    return next.handle(request);
  }
}
