<ng-container *transloco="let t">
  <div class="payment-summary flex">
    <div class="content-container">
      <app-confirmation-section #section
        [hasHeaderMargin]="false"
        scope="paymentSummary">
        <app-confirmation-section-record #record
          *ngIf="ratePlanType"
          [isDivider]="{ top:true, bottom: true }"
          position="full">
          <div [ngClass]="[record.htmlClasses.row, record.positions.end]">
            <div [ngClass]="record.htmlClasses.label">
              <app-info-label
                [text]="ratePlanType.name"
                type="ratePlanType">
              </app-info-label>
            </div>
            <div [ngClass]="record.htmlClasses.value">
              {{ratePlanTotal | appCurrency}}
            </div>
          </div>
        </app-confirmation-section-record>
        <app-confirmation-section-record
          #record
          [scope]="section.scope"
          position="full"
          *ngIf="addOns && addOns.length"
          field="addOns">
          <div [ngClass]="[record.htmlClasses.row, record.positions.end]"
            *ngFor="let addOn of addOns; let i = index">
            <div [class.margin-bottom]="i + 1 < addOns.length">
              <ng-container *ngIf="(addOn.quantity || 0) > 1">
                {{addOn.quantity}}
                x
              </ng-container>
              {{addOn.name}}
              <ng-container *ngIf="addOn.ageGroup">
                ({{addOn.ageGroup}} {{t('child.yearOrYears')}})
              </ng-container>
            </div>
            <div [ngClass]="record.htmlClasses.value"
              [class.margin-bottom]="i + 1 < addOns.length">
              {{addOn.totalPrice | appCurrency}}
            </div>
          </div>
        </app-confirmation-section-record>
        <app-confirmation-section-record
          #record
          [scope]="section.scope"
          position="full"
          field="tripProtection"
          *ngIf="tripProtection">
          <div [ngClass]="[record.htmlClasses.row, record.positions.end]">
            <div>
              {{t([section.scope, 'adults'] | join, { value: tripProtectionCount })}}
            </div>
            <div [ngClass]="record.htmlClasses.value">
              {{tripProtectionTotalPrice | appCurrency}}
            </div>
          </div>
        </app-confirmation-section-record>
        <app-confirmation-section-record
          [scope]="section.scope"
          labelWidth="65%"
          position="end"
          field="total">
          {{total | appCurrency}}
        </app-confirmation-section-record>
        <app-confirmation-section-record
          *ngIf="paymentPolicy"
          labelWidth="50%"
          [scope]="section.scope"
          [isDivider]="{}"
          position="end"
          field="paymentPlan">
          {{paymentPolicy.name}}
        </app-confirmation-section-record>
        <app-confirmation-section-record
          [scope]="section.scope"
          *ngIf="paymentPolicy && paymentPolicy.description"
          position="full">
          <div class="margin-top">
            {{paymentPolicy.description}}
          </div>
        </app-confirmation-section-record>
        <app-confirmation-section-record
          #record
          *ngFor="let item of paymentScheduleItems; let i = index;"
          position="full">
          <div [ngClass]="[record.htmlClasses.row, record.positions.full]">
            <div [ngClass]="record.htmlClasses.label">
              {{item.name}}
            </div>
          </div>
          <div [ngClass]="[record.htmlClasses.row, record.positions.end]"
            *ngIf="i !== 0 || ((paid || 0) > 0)">
            <div class="flex">
              <app-info-label
                type="successInfoLabel"
                [text]="t([section.scope, 'paid'] | join)"
                *ngIf="i === 0">
              </app-info-label>
              <ng-container *ngIf="i !== 0">
                {{item.date | appDate: 'type3'}}
              </ng-container>
            </div>
            <div [ngClass]="record.htmlClasses.value">
              <ng-container *ngIf="i === 0">
                {{paid | appCurrency}}
              </ng-container>
              <ng-container *ngIf="i !== 0">
                {{item.amount | appCurrency}}
              </ng-container>
            </div>
          </div>
          <div *ngIf="i === 0 && paid !== item.amount"
            [ngClass]="[record.htmlClasses.row, record.positions.end]">
            <div class="flex">
              <app-info-label
                class="uppercased"
                textColor="default"
                type="textError"
                [text]="t([section.scope, 'unpaid'] | join)">
              </app-info-label>
              &nbsp;
              {{item.date | appDate: 'type3'}}
            </div>
            <b class="color-textError">
              {{ ((item.amount || 0) - (paid || 0)) | appCurrency }}
            </b>
          </div>
          <div [ngClass]="[record.htmlClasses.row, record.positions.full]"
            *ngIf="i === 0 && paid !== item.amount">
            <app-notification-failed-payment-part
              [noPaddingAround]="true"
              iconName="alert"
              backgroundColor="alertBackground"
              textColor="alertText"
              borderColor="alertBorder">
            </app-notification-failed-payment-part>
          </div>
        </app-confirmation-section-record>
        <app-confirmation-section-record
          [isDivider]="{}"
          position="full">
          <div class="text-center">
            {{t([section.scope, 'agreement'] | join)}}
          </div>
        </app-confirmation-section-record>
      </app-confirmation-section>
    </div>
  </div>
</ng-container>