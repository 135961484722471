<ng-container *transloco="let t">
  <div class="flex-column padding-top">
    <div class="block-header">
      <div class="block-header-text">
        {{t('room.list')}}
      </div>
      <div class="block-header-navigation">
        <app-icon-button
          (click)="scroll(true)"
          iconSize="big"
          buttonSize="big"
          name="left"
          stroked="iconStroke"
          filled="iconFill">
        </app-icon-button>
        <app-icon-button
          (click)="scroll(false)"
          iconSize="big"
          buttonSize="big"
          name="right"
          stroked="iconStroke"
          filled="iconFill">
        </app-icon-button>
      </div>
    </div>
    <div class="flex-start-start overflow-auto"
      #options>
      @for(selectConfig of selectConfigs; track $index;){
      @if($index === memberBannerIndex && profile && isOnSearchPageV2){
      <app-member-option-banner-v2></app-member-option-banner-v2>
      }
      <app-select-option-v2
        [config]="selectConfig"
        [sidebarContext]="{ template, position: 'end', width: 'big'  }"
        [recommendation]="$index === 0 ? recommendation : undefined">
        <ng-template #footer>
          <app-pricing-details-v2
            [config]="pricingConfigs[selectConfig.id]"
            (selectClick)="onSelectClick(all[selectConfig.id])">
            <app-package-pricing
              [priceFormat]="priceFormat"
              [currentRoomType]="all[selectConfig.id].roomType"
              [currentPackage]="all[selectConfig.id].package"
              [isActive]="pricingConfigs[selectConfig.id].active">
            </app-package-pricing>
          </app-pricing-details-v2>
        </ng-template>
      </app-select-option-v2>
      <ng-template #template>
        <app-sidebar-layout>
          <ng-template #content>
            <app-package-details
              [currentRoomType]="all[selectConfig.id].roomType"
              [currentPackage]="all[selectConfig.id].package">
            </app-package-details>
          </ng-template>
          <ng-template #footer>
            <app-pricing-details-v2
              size="big"
              [config]="pricingConfigs[selectConfig.id]"
              (selectClick)="onSelectClick(all[selectConfig.id])">
              <app-package-pricing
                [priceFormat]="priceFormat"
                [currentRoomType]="all[selectConfig.id].roomType"
                [currentPackage]="all[selectConfig.id].package"
                [isActive]="pricingConfigs[selectConfig.id].active">
              </app-package-pricing>
            </app-pricing-details-v2>
          </ng-template>
        </app-sidebar-layout>
      </ng-template>
      }
    </div>
  </div>
</ng-container>