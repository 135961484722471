import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Country, IMembershipProfile, MembershipProfile } from '@shared/api/be-api.generated';
import { ComponentBase } from '@shared/base/component.base';
import { FormComponent } from '@shared/components/common/form/form.component';
import { SelectOption } from '@shared/models/common';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { MemberService } from '@member/member.service';
import { dateFormat } from 'src/app/formats/date-time';

type ProfileField = keyof IMembershipProfile;

@Component({
  selector: 'app-member-contact-v2',
  templateUrl: './member-contact.component.html'
})
export class MemberContactV2Component extends ComponentBase {
  constructor(
    private readonly _member: MemberService,
    service: ComponentBaseService
  ) {
    super(service);
    this.pushSub(
      _member.getCountries().subscribe(result => {
        this.countries = result;
        this.fieldsOptions = {
          country: this.countries.map(item => ({ label: item.name, value: item.twoLetterCode } as SelectOption))
        };
        this.init();
      })
    );
  }

  @Output() closed = new EventEmitter();
  @ViewChild('form') fc!: FormComponent<ProfileField>;
  dateFields: Partial<Record<ProfileField, boolean>> = { dob: true };
  valueFormats: Partial<Record<ProfileField, string>> = { dob: dateFormat.type1 };
  fieldsOptions!: Partial<Record<ProfileField, SelectOption[]>>;
  fields: ProfileField[] = ['phone', 'email', 'address1', 'address2', 'city', 'state', 'zip', 'country'];
  disabledFields: Partial<Record<ProfileField, boolean>> = { 'email': true };
  required: Partial<Record<ProfileField, boolean>> = { phone: true };
  profile = this._member.profile || new MembershipProfile({});
  profileForm!: UntypedFormGroup;
  errorMessage?: string;
  isLoading = false;
  countries: Country[] = [];

  init() {
    const getFormControl = (key: ProfileField) =>
      new UntypedFormControl(this.profile[key], this.required[key] ? Validators.required : []);

    const formControls = this.fields
      .map(key => ({ [key]: getFormControl(key) }))
      .reduce((item, all) => ({ ...item, ...all }), {});

    this.profileForm = new UntypedFormGroup(formControls);
  }

  save() {
    this.profileForm.markAllAsTouched();
    if (!this.isLoading && this.profileForm.valid) {
      this.isLoading = true;

      this._member.update(new MembershipProfile({ ...this.profile, ...this.fc.asObject })).subscribe({
        next: () => this.close(),
        error: ex => this._member.getMembershipError(ex),
        complete: () => this.isLoading = false
      });
    }
  }

  close() {
    this.closed.emit();
  }
}
