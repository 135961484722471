import { Component, HostBinding, ViewChild } from '@angular/core';
import { ComponentBase } from '@shared/base/component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { MemberService } from '@member/member.service';
import { FormComponent } from '@shared/components/common/form/form.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BreadCrumbsItem } from '@shared/components-v2/common/bread-crumbs/bread-crumbs.component';

type SupportField = 'subject' | 'body';

@Component({
  selector: 'app-member-support-v2',
  templateUrl: './member-support.component.html'
})
export class MemberSupportV2Component extends ComponentBase {
  constructor(
    private readonly _member: MemberService,
    service: ComponentBaseService
  ) {
    super(service);
  }

  @HostBinding('class') hostClasses = ['w-100', 'flex-stretch-stretch', 'h-100-min'];
  @ViewChild('form') fc!: FormComponent<SupportField>;

  breadCrumbs: BreadCrumbsItem[] = [{ caption: 'caption' }];

  isLoading = false;
  isSuccessSend = false;
  errorMessage?: string;

  readonly fields: SupportField[] = ['subject', 'body'];
  readonly supportForm = new UntypedFormGroup(this.fields
    .map(key => ({ [key]: new UntypedFormControl(undefined, Validators.required) }))
    .reduce((item, all) => ({ ...item, ...all }), {}));

  send() {
    this.supportForm.markAllAsTouched();

    if (!this.isLoading && this.fc.form.valid) {
      this.isLoading = true;
      this.errorMessage = undefined;

      this._member.sendSupportEmail(this.fc.get.subject(), this.fc.get.body()).subscribe({
        next: () => {
          this.isSuccessSend = true;
        },
        error: ex => {
          this.errorMessage = this._member.getMembershipError(ex);
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    }
  }
}
