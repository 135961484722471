<ng-container *transloco="let t">
  <div class="select-tab flex-between-center gap-quarter">
    @for (key of keys; track key) {
    <div class="select-tab-item padding flex"
      [class.cursor-pointer]="key !== selectedKey"
      [class.bold]="key === selectedKey"
      [class.selected]="key === selectedKey"
      (click)="selectKey(key)">
      {{ t(keyPrefix + key) }}
    </div>
    }
  </div>
  <div class="select-tab-content"
    [@selectTabContentChange]="isAnimating">
    <ng-template *ngTemplateOutlet="content; context: { selectedKey }"></ng-template>
  </div>
</ng-container>