import { Component } from '@angular/core';

const signIn = 'signIn';
const register = 'register';
const memberOptionsKeys = { signIn, register } as const;
export type MemberOptionKey = typeof memberOptionsKeys[keyof typeof memberOptionsKeys];

@Component({
  selector: 'app-member-start-v2',
  templateUrl: './member-start.component.html'
})
export class MemberStartV2Component {
  memberOptionsKeys = memberOptionsKeys;
  avaliableMemberOptionKeys: MemberOptionKey[] = Object.values(memberOptionsKeys);
  selectedMemberOptionKey: MemberOptionKey = signIn;

  selectTab = (option?: MemberOptionKey) => {
    if (option && option != this.selectedMemberOptionKey) {
      this.selectedMemberOptionKey = option;
    }
  }
}
