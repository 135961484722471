<ng-container *transloco="let t">
  <ng-template #dividerTemplate>
    <div class="divider-dotted"></div>
  </ng-template>

  <ng-template #value>
    <ng-content></ng-content>
  </ng-template>

  <ng-container *ngIf="isDivider?.top"
    [ngTemplateOutlet]="dividerTemplate">
  </ng-container>

  <div [ngClass]="[htmlClasses.row, position]"
    [class.margin-top]="isSpace?.top"
    [class.margin-bottom]="isSpace?.bottom"
    [class.gap]="position === positions.full && isGap">
    <ng-container *ngIf="field; else value">
      <div [ngClass]="htmlClasses.label"
        [innerHtml]="t([scope, field] | join)"
        [style.min-width]="labelWidthPositions.includes(position) ? labelWidth : defaultLabelWidth">
      </div>
      <div [ngClass]="htmlClasses.value">
        <ng-container [ngTemplateOutlet]="value"></ng-container>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="isDivider?.bottom"
    [ngTemplateOutlet]="dividerTemplate">
  </ng-container>
</ng-container>