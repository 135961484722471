<ng-container *transloco="let t">
  <div class="flex-column bc-default padding w-100">
    <app-bread-crumbs-v2
      class="padding-top"
      [items]="breadCrumbs"
      section="member.activity">
    </app-bread-crumbs-v2>
    <div class="text-1-1_5-400 margin-vertical">
      {{t('member.activity.description')}}
    </div>
    @if(isLoading){
    <div class="flex-column gap margin-top">
      <app-loading-icon size="button"> </app-loading-icon>
      <div class="flex text-1-1-500 color-selected">
        {{t('member.activity.loading')}}
      </div>
    </div>
    }
    @else {
    <div class="flex text-1-1-500 color-selected">
      {{t('member.activity.nothing')}}
    </div>
    }

  </div>
</ng-container>
