import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ComponentBase } from '@shared/base/component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { SidebarService } from '@shared/services/sidebar/sidebar.service';
import { TrackingService } from '@shared/services/tracking.service';
import { MemberProfile } from '@shared/api/cl-api.generated';

@Component({
  selector: 'app-booking-button',
  templateUrl: './booking-button.component.html',
  styleUrls: ['./booking-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookingButtonComponent extends ComponentBase {
  constructor(
    private readonly _sidebar: SidebarService,
    private readonly _tracking: TrackingService,
    services: ComponentBaseService
  ) {
    super(services);
  }

  @HostBinding('class.padding-top-less') withoutPadding = true;
  @Output() bookingStarted = new EventEmitter();
  @Input() profile?: MemberProfile;
  @Input() simple = false;
  loyaltyEnabled = this.services.tenant.loyaltyEnabled;

  startBooking() {
    this.services.navigation.openNext();
    this._sidebar.close();
    this.bookingStarted.emit();
    this._tracking.beginCheckout();
  }
}
