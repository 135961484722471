export const defaultAdultsCount = 2;
export const defaultStopsCount = 2;
export const totalStopsCount = 4;
export const maxCountAddonsToPurchase = 10;
export const maximumNightsToProcess = 100;
export const perPageFlights = 2;
export const usaCode = 'US'
export const topCountriesTwoLetterCodes: (string | undefined)[] = [usaCode, 'GB'];
export const defaultDaysSearchStart = 64;
export const defaultDaysSearchPeriod = 14;
export const tripProtectionCategory = 'INS';
export const isMembershipEnabled = false;
export const zipcodeCountries = [usaCode];
export const jamaicaAirportsCodes = ['OCJ', 'KIN', 'MBJ'];

export const imageFolder = '/assets/images/';
export const getImagePath = (imageName: string) => imageFolder + imageName;

export const imagesPath = {
  noImage: getImagePath('no-image.svg'),
  loader: getImagePath('loader.webp'),
  memberRewards: getImagePath('member-rewards.png'),
  memberBanner: getImagePath('member-banner.png'),
  logocBreeze: getImagePath('logo.svg'),
  logoCouples: getImagePath('couples-logo.svg')
};
