<ng-container *transloco="let t">
  <div class="page-container">
    <div class="page-content">
      <app-header-v2 #header></app-header-v2>
      <div class="page-section">
        <div class="flex">
          <div class="main-container">
            <div class="section">
              <div class="section-header">
                <div class="section-header-text">
                  {{t('page.booking.header')}}
                </div>
                <div class="section-header-buttons-container fw-500">
                  {{bookOnlinePhone}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex"
        *ngIf="!isBookingInitialized">
        <div class="main-container">
          <div class="content-container spaceless">
            <div>{{t('notification.booking.checkAbandonedCart')}}</div>
          </div>
        </div>
      </div>
      <app-two-columns
        *ngIf="isBookingInitialized"
        rightColumnMaxWidth="half">
        <ng-template #leftColumn>
          <div class="under-column header-less">
            @if (profile){
            <app-member-info-v2 [isShort]="false" class="hide-if-empty"></app-member-info-v2>
            }
            @else{
            <app-manage-booking></app-manage-booking>
            }

            <app-notification-prices class="hidden-tablet-min padding-less"></app-notification-prices>

            <div class="hidden-tablet-min space-default">
              <div class="cart-container flex-column">
                <app-cart-viewer-v2></app-cart-viewer-v2>
                <app-cart-total></app-cart-total>
              </div>
            </div>

            <app-guests></app-guests>
            <app-flight-info class="hide-if-empty"></app-flight-info>
            <app-protect-vacation class="hide-if-empty"></app-protect-vacation>
            <app-payment-plan></app-payment-plan>
            <app-review-booking></app-review-booking>
          </div>
        </ng-template>
        <ng-template #rightColumn>
          <div class="cart-container flex-column transparent padding-less header-less">
            <app-notification-prices class="hidden-tablet-max page-section"></app-notification-prices>
            <div class="divider-dotted"></div>
            <app-cart-viewer-v2></app-cart-viewer-v2>
            <app-cart-total></app-cart-total>
          </div>
        </ng-template>
      </app-two-columns>
    </div>
    <app-footer></app-footer>
  </div>
</ng-container>