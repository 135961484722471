import { Component, ContentChild, HostBinding, Input, TemplateRef } from '@angular/core';
import { ComponentBase } from '@shared/base/component.base';
import { CarouselImage, WithId } from '@shared/models/common';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { SidebarService } from '@shared/services/sidebar/sidebar.service';
import { RecommendationBarSettings } from '@shared/components-v2/layout/recommendation-bar/recommendation-bar.component';
import { SidebarContext } from '@shared/services/sidebar/sidebar.sm';

export class SelectOptionConfig {
  constructor(
    public id: string,
    public caption?: string,
    public description?: string,
    public images: WithId<CarouselImage>[] = []
  ) {
    this.active = true;
    this.disabled = false;
  }

  active?: boolean
  disabled?: boolean;
}

@Component({
  selector: 'app-select-option-v2',
  templateUrl: './select-option.component.html',
  styleUrls: ['./select-option.component.scss'],
})
export class SelectOptionV2Component extends ComponentBase {
  @ContentChild('footer') footer?: TemplateRef<unknown>;
  @Input() sidebarContext?: Partial<SidebarContext>;
  @Input() recommendation?: RecommendationBarSettings;
  @Input() config!: SelectOptionConfig;
  @Input() @HostBinding('class.padding-right') paddingRight = true;

  priceFormat = this.services.tenant.priceFormat.main;

  constructor(
    private readonly _sidebar: SidebarService,
    services: ComponentBaseService
  ) {
    super(services);
  }

  openDetails() {
    this.sidebarContext && this._sidebar.open(this.sidebarContext);
  }
}
