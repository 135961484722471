<ng-template #defaultCancelButtonTemplate>
  <button mat-button [color]="color" (click)="cancelClickHandler()">{{cancelLabel}}</button>
</ng-template>
<ng-template #defaultOkButtonTemplate>
  <button mat-button [disabled]="invalidSelection" [color]="color" (click)="okClickHandler()">{{okLabel}}</button>
</ng-template>

<mat-dialog-content>
  <div class="root">
    <mat-toolbar [color]="color" class="header">
      <div class="placeholder"></div>
      <div class="time-frame">
        <span class="time fixed-font-size" [ngClass]="{'select': this.viewType === 'hours' && 'active' }"
          (click)="editHours()">
          {{ twoDigits(formattedHours) }}
        </span>
        <span class="fixed-font-size">:</span>
        <span class="time fixed-font-size" [ngClass]="{ 'select': this.viewType === 'minutes' && 'active' }"
          (click)="editMinutes()">
          {{ twoDigits(minutes) }}
        </span>
      </div>
      @if(mode === '12h'){
      <div class="ampm">
        <span class="time" [ngClass]="{ 'select': isPm }" (click)="setPm()">{{postMeridiemAbbreviation | uppercase }}</span>
        <span class="time" [ngClass]="{ 'select': !isPm }" (click)="setAm()">{{anteMeridiemAbbreviation | uppercase }}</span>
      </div>
      }
      @else{
      <div class="placeholder"></div>
      }
    </mat-toolbar>
    <div class="body">
      <mat-clock
        [allowed12HourMap]="allowed12HourMap"
        [allowed24HourMap]="allowed24HourMap"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [color]="color"
        [viewType]="viewType"
        [mode]="mode"
        [formattedHours]="formattedHours"
        [minutes]="minutes"
        (changeEvent)="handleClockChange($event)"
        (unavailableSelection)="handleUnavailableSelection()"
        [formattedValue]="viewType === 'minutes' ? minutes : formattedHours"
        [isPm]="isPm"
        (invalidMeridiem)="invalidMeridiem()"
        (mouseup)="handleClockChangeDone($event)"
        (clearInvalidMeridiem)="clearInvalidMeridiem()"
        (touchend)="handleClockChangeDone($event)"
        (invalidSelection)="invalidSelectionHandler($event)">
      </mat-clock>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>

  <ng-container
    *ngTemplateOutlet="cancelButtonTemplate || defaultCancelButtonTemplate; context: { label: cancelLabel, $implicit: cancelClickHandler }">
  </ng-container>
  <ng-container
    *ngTemplateOutlet="okButtonTemplate || defaultOkButtonTemplate; context: { label: okLabel, $implicit: okClickHandler }">
  </ng-container>
</mat-dialog-actions>