import { AfterViewInit, Component, HostBinding, Input, ViewChild } from '@angular/core';
import { PackageService } from '@shared/services/package.service';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { Airport, AirportTransfer, Resort } from '@shared/api/be-api.generated';
import { SearchService } from '@shared/services/search/search.service';
import { ComponentBase } from '@shared/base/component.base';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { SelectOptionConfig } from '@search/components-v2/shared/select-option/select-option.component';
import { createCarouselImages } from '@shared/common';
import { PricingDetailsConfig } from '@search/components-v2/shared/pricing-details/pricing-details.component';

@Component({
  selector: 'app-airport-transfer-select-options',
  templateUrl: './airport-transfer-select-options.component.html',
  styleUrls: ['./airport-transfer-select-options.component.scss']
})
export class AirportTransferSelectOptionsComponent extends ComponentBase implements AfterViewInit {
  constructor(
    private readonly _package: PackageService,
    private readonly _search: SearchService,
    services: ComponentBaseService
  ) {
    super(services);

    this.pushSub(
      this._package.setWhenDataChanged(() => this.update())
    );
  }

  @HostBinding('class.hide-if-empty') hideIfEmpty = true;
  @ViewChild('options') options!: CdkVirtualScrollViewport;
  @Input() hideHeader = false;

  transfers: AirportTransfer[] = [];
  sortedTransfers: Record<string, AirportTransfer> = {};
  pricingConfigs: Record<string, PricingDetailsConfig> = {};

  arrivalAirport?: Airport;
  inboundAirportTransfer?: AirportTransfer;
  outboundAirportTransfer?: AirportTransfer;

  itemSize = 300;

  selectConfigs: SelectOptionConfig[] = [];
  resort?: Resort;

  ngAfterViewInit() {
    if (this.isBrowser) {
      setTimeout(() => {
        const options = this.options?.elementRef?.nativeElement as HTMLElement;
        const option = options?.querySelector('app-select-option-v2');
        if (option) {
          this.itemSize = option.clientWidth;
        }
      });
    }
  }

  update() {
    const {
      airportTransfers,
      inboundAirportTransfer: inbound,
      outboundAirportTransfer: outbound,
      unavailabilityReason,
      resort
    } = this._package;

    if (this.transfers !== airportTransfers) {
      this.transfers = airportTransfers;
      this.sortedTransfers = [
        ...this.transfers.filter(t => !t.price),
        ...this.transfers.filter(t => t.price).sort((t1, t2) =>
          (t1.price || 0) > (t2.price || 0) ? 1 : -1)]
        .reduce((result, item) => ({ ...result, [item.id || '']: item }), {})
    }

    if (resort?.resortId !== this.resort?.resortId) {
      this.resort = resort;
      this.selectConfigs = [];
      this.pricingConfigs = {};
    }

    Object.values(this.sortedTransfers).forEach(transfer => {
      const id = transfer.id;
      if (!id) {
        return;
      }

      let selectConfig = this.selectConfigs.find(item => item.id === id);
      if (!selectConfig) {
        selectConfig = new SelectOptionConfig(id, transfer.name, transfer.description, createCarouselImages(transfer));
        this.selectConfigs.push(selectConfig);
      }
      selectConfig.disabled = !!unavailabilityReason;

      let pricingConfig = this.pricingConfigs[id];
      if (!pricingConfig) {
        pricingConfig = new PricingDetailsConfig(id);
        this.pricingConfigs[id] = pricingConfig;
      }

      pricingConfig.active = id === inbound?.id || id == outbound?.id;
      pricingConfig.disabled = selectConfig.disabled || false;
    });
  }
}
