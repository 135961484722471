<ng-container *transloco="let t">
  <div class="flex-column bc-default padding w-100">
    <app-bread-crumbs-v2
      class="padding-top"
      [items]="breadCrumbs"
      section="member.rewards">
    </app-bread-crumbs-v2>
    <div class="text-1-1_5-400 margin-vertical">
      {{t('member.rewards.description')}}
    </div>
    @if(isLoading){
    <div class="flex-column gap margin-top">
      <app-loading-icon size="button">
      </app-loading-icon>
      <div class="flex text-1-1-500 color-selected">
        {{t('member.rewards.loading')}}
      </div>
    </div>
    }
    @for (level of levels; track $index) {
    <div class="margin-half padding-horizontal"
      [class.border]="level.code !== currentLevel"
      [class.border-selected-double]="level.code === currentLevel">
      <div class="flex-between">
        <div class="text-1-1-500"
          [class.color-selected]="level.code === currentLevel">
          {{level.name}} ({{level.code}})
        </div>
        @if(level.imagePath){
        <div>
          <img [src]="level.imagePath" [alt]="level.code">
        </div>
        }
        @else {
        <app-icon circle="memberDefault">
          <span class="text-1_5-1_5-500">{{$index + 1}}</span>
        </app-icon>
        }
      </div>

      @if(level.code && benefits[level.code]){
      <div [class.margin-bottom]="benefits[level.code].isAnyBenefit">
        <i>
          @if(benefits[level.code].monetaryDiscount){
          <div>
            <b>-{{benefits[level.code].monetaryDiscount | appCurrency}}</b> discount per stay!
          </div>
          }
          @if(benefits[level.code].percentageDiscount){
          <div>
            <b>-{{benefits[level.code].percentageDiscount}}%</b> discount per stay!
          </div>
          }
          @if(benefits[level.code].resortCreditAmount){
          <div>
            <b>+{{benefits[level.code].resortCreditAmount | appCurrency}}</b> resort credit!
          </div>
          }
          @for (perk of benefits[level.code].perks; track $index) {
          <div>
            {{perk}}
          </div>
          }
        </i>
      </div>
      }
    </div>
    }
  </div>
</ng-container>