import { Injectable } from '@angular/core';
import { concatMap, debounceTime, map, Observable, of } from 'rxjs';
import { AddressSearchInput, AddressSelectOption, buildLabel, toAddressOption } from '@shared/models/address';
import { UsAutocompleteLookup, usClient } from '@shared/models/address';
import { InAutocompleteLookup, inClient } from '@shared/models/address';
import { TranslocoService } from '@jsverse/transloco';
import { TenantService } from './tenant.service';

@Injectable()
export class AddressService {
  currentCountry?: string;

  readonly usCountry = 'USA';
  readonly entriesPostfix = this._transloco.translate('address.entriesPostfix');
  readonly noCountryOption: AddressSelectOption = {
    label: this._transloco.translate('address.error.noCountry'),
    value: 0,
    isDisabled: true
  };

  readonly noRecordsOption: AddressSelectOption = {
    label: this._transloco.translate('address.error.noResults'),
    value: 0,
    isDisabled: true,
    isError: true
  };

  readonly requestFailedOption: AddressSelectOption = {
    label: this._transloco.translate('address.error.requestFailed'),
    value: 0,
    isDisabled: true,
    isError: true
  };

  private readonly _search = {
    us: (search: string, selected?: string): Promise<UsAutocompleteLookup | undefined> =>
      usClient(this._tenant.settings).send(new UsAutocompleteLookup(search, selected)),
    in: (country: string, search: string): Promise<InAutocompleteLookup | undefined> =>
      inClient(this._tenant.settings).send(new InAutocompleteLookup(search, country))
  }

  constructor(
    private readonly _tenant: TenantService,
    private readonly _transloco: TranslocoService
  ) { }

  getAddressOptions(valueChanges: Observable<unknown>, selected?: AddressSelectOption) {
    return valueChanges.pipe(
      debounceTime(0),
      concatMap(query => typeof query === 'string' && query && this.currentCountry ?
        this.search({ query, selected }) : of(undefined)))
      .pipe(map(data => this.currentCountry ? data || [] : [this.noCountryOption]))
  }

  search({ country, selected, query }: Partial<AddressSearchInput>): Promise<AddressSelectOption[]> {
    if (!country) {
      country = this.currentCountry;
    }

    if (!country) {
      return new Promise(resolve => resolve([this.noCountryOption]));
    }

    const selectedText = selected?.usSuggestion
      ? buildLabel.us(selected.usSuggestion)
      : undefined;

    if (!query) {
      query = selectedText;
    }

    if (!query) {
      return new Promise(resolve => resolve([]));
    }

    const resultPromise = country === this.usCountry
      ? this._search.us(query, selectedText)
      : this._search.in(country, query);

    return resultPromise
      .then(data => {
        const result = data?.result?.map(item =>
          toAddressOption(item, this.entriesPostfix)) || [];

        return result && result.length ? result : [this.noRecordsOption];
      })
      .catch(() => [this.requestFailedOption])
  }
}
