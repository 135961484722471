import { Component, Input } from '@angular/core';
import { PricingDetailsConfig } from '@search/components-v2/shared/pricing-details/pricing-details.component';
import { Airport, AirportTransfer } from '@shared/api/be-api.generated';
import { ComponentBase } from '@shared/base/component.base';
import { CarouselImage, WithId } from '@shared/models/common';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { PackageService } from '@shared/services/package.service';
import { SearchService } from '@shared/services/search/search.service';

@Component({
  selector: 'app-airport-transfer-details',
  templateUrl: './airport-transfer-details.component.html'
})
export class AirportTransferDetailsComponent extends ComponentBase {
  @Input() current!: AirportTransfer;
  @Input() pricingConfig!: PricingDetailsConfig;
  @Input() images!: WithId<CarouselImage>[];

  arrivalAirport?: Airport;
  inbound?: AirportTransfer;
  outbound?: AirportTransfer;

  constructor(
    services: ComponentBaseService,
    private readonly _package: PackageService,
    private readonly _search: SearchService
  ) {
    super(services);
    this._setData();

    this.pushSub(
      _package.setWhenDataChanged(() => this._setData())
    );
  }

  private _setData() {
    this.inbound = this._package.inboundAirportTransfer;
    this.outbound = this._package.outboundAirportTransfer;
    this.arrivalAirport = this._search.state.context.arrivalAirport;
  }

  selectAsInbound() {
    this._package.selectInboundAirportTransfer(
      this.inbound?.id !== this.current.id
        ? this.current
        : undefined
    );
  }

  selectAsOutbound() {
    this._package.selectOutboundAirportTransfer(
      this.outbound?.id !== this.current.id
        ? this.current
        : undefined);
  }
}
