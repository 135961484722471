<ng-container *transloco="let t">
  <div class="member-signin">
    <app-form #form
      scope="member.signIn"
      [form]="formGroup">
      <ng-template #content let-text="text">
        <div class="email-value">
          <ng-container *ngTemplateOutlet="text; context: {
            field: 'email',
            disabled: isLoading || !!successMessage
           }"></ng-container>
        </div>
        @if(!isForgotPassword){
        <div class="password-value">
          <ng-container *ngTemplateOutlet="text; context: {
              field: 'password',
              type: 'password',
              isSubLabel: true,
              disabled: isLoading
            }"></ng-container>
        </div>
        }
      </ng-template>
    </app-form>
    <div class="flex-column">
      <div class="text-0_875-1_25-500 margin-top link-text-primary-underlined cursor-pointer"
        (click)="toggleForgotPassword()">
        @if(isForgotPassword) {
        {{t('member.forgotPassword.question.after')}}
        }
        @else{
        {{t('member.forgotPassword.question.before')}}
        }
      </div>
      @if(!isForgotPassword){
      <b class="margin-top">{{t('member.reward.question')}}</b>
      <b class="margin-top"
        (click)="openRegister()">
        <span class="link-underlined">{{t('member.register.today')}}</span>&nbsp;{{t('member.reward.andSave')}}
      </b>
      <ng-container *ngTemplateOutlet="rewardBullets"></ng-container>
      }

      <!-- <app-reward-bullets-v2></app-reward-bullets-v2> -->
    </div>
    <div class="flex border-top padding margin-top-double margin-horizontal-minus full-width-plus-padding-double">
      @if (isForgotPassword){
      <button color="primary"
        (click)="forgotPassword()"
        [disabled]="isLoading || !!successMessage"
        mat-raised-button
        class="select-button w-100">
        @if (isLoading) {
        <app-loading-icon size="button"></app-loading-icon>
        &nbsp;
        }
        {{t('member.forgotPassword.button.' + (isResetPassword ? 'process': successMessage ? 'after' : 'before') )}}
      </button>
      }@else{
      <button color="primary"
        (click)="signIn()"
        [disabled]="isLoading"
        mat-raised-button
        class="select-button w-100">
        @if (isLoading) {
        <app-loading-icon size="button"></app-loading-icon>
        &nbsp;
        }
        {{t('member.signIn.button')}}
      </button>
      }

    </div>
    @if (errorMessage) {
    <div class="flex w-100">
      <div class="error-message text-0_875-0_875-600">
        {{ errorMessage }}
      </div>
    </div>
    }
    @if (successMessage){
    <div class="flex w-100">
      <div class="text-1-1_25-500 color-successText text-center"
        [innerHTML]="t('member.forgotPassword.success')">
      </div>
    </div>
    }
  </div>
</ng-container>