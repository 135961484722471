<ng-container *transloco="let t">
  <div class="flex-center-start full-size cursor-pointer"
    (click)="closeBanner($event.target)">
    <div class="main-container flex-end">
      <div [ngClass]="[bannerClass]">
        <div class="banner flex-column bc-memberBannerBackground padding box-shadow cursor-default">
          <div class="rewards-image flex w-100">
            <app-icon
              name="stars"
              size="big"
              filled="memberDefault">
            </app-icon>
            <app-icon
              name="loyalty"
              size="custom"
              filled="textPrimary"
              class="w-50"
              filledRect="memberDefault">
            </app-icon>
            <app-icon
              name="stars"
              size="big"
              filled="memberDefault">
            </app-icon>
          </div>
          <div class="text-1_125-1_125-600 text-center">
            {{ t('member.banner.header') }}
          </div>
          <div class="banner-button margin-vertical">
            <button mat-raised-button
              color="primary"
              class="select-button w-100"
              (click)="signIn()">
              {{t('member.signIn.button' )}}
            </button>
          </div>
          <div class="text-0_75-0_75-500 text-center">
            {{ t('member.banner.subheader') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>