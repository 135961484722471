<div class="page-container">
  <div class="page-content"
    [class.with-rate-plan-type-switcher]="header.availableRatePlanType">
    <app-header #header></app-header>
    <div class="under-header"></div>
    <app-search-bar></app-search-bar>
    <div class="under-search-bar" *ngIf="isRequestedFlights"></div>
    <app-notification-warnings class="page-section"></app-notification-warnings>
    <!--<app-notification-signup class="page-section"></app-notification-signup>-->
    <app-room-viewer></app-room-viewer>
    <app-flight-select></app-flight-select>
    <app-airport-transfer-select-options></app-airport-transfer-select-options>
    <app-notification-amenities></app-notification-amenities>
    <app-addon-select></app-addon-select>
    <app-cart-summary></app-cart-summary>
  </div>
  <app-footer></app-footer>
  <app-fixed-buttons></app-fixed-buttons>

  <ng-container *transloco="let t">
    <app-modal-panel-v2
      class="hide-if-empty"
      [isActive]="!!minimumStay"
      positionHorizontal="center"
      positionVertical="center"
      overlayColor="modalBackground">
      <ng-template #header>
        <div class="text-1_75-1_75-600 color-required">
          {{t('unavailability.minStay.header')}}
        </div>
      </ng-template>
      <ng-template #content>
        <div class="text-1-1-500"
          [innerHtml]="t('unavailability.minStay.message', { current: nights, required: minimumStay })">
        </div>
      </ng-template>
    </app-modal-panel-v2>
  </ng-container>
</div>