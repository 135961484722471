/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { BlockOverlayService } from '@shared/services/block-overlay.service';
import { distinctUntilChanged, map } from 'rxjs';
import { assign } from 'xstate';
import { StateMachineBootstrapperService } from '../state/state-machine-bootstrapper.service';
import sidebarSM, { SidebarContext, SidebarTemplates } from './sidebar.sm';
import { SidebarPanelWidth, SidebarPosition } from '@shared/models/common';
import { Color } from '@shared/models/common';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private readonly _sidebarMachine = this._smFactory.bootstrapMachine(sidebarSM.withConfig({
    actions: {
      openTemplate: assign((_, newContext) => ({ ...newContext })),
      updateTemplate: assign((_, newContext) => ({ ...newContext })),
      closeTemplate: assign((_) => ({ template: undefined, position: undefined, width: undefined, backgroundColor: 'default' })),
      openOverlay: () => this._blockOverlay.open(),
      closeOverlay: () => this._blockOverlay.close(),
    }
  }));

  context$ = this._sidebarMachine.state$.pipe(
    map(state => state.context),
    distinctUntilChanged()
  );

  templates: SidebarTemplates = {};

  constructor(
    private readonly _blockOverlay: BlockOverlayService,
    private readonly _smFactory: StateMachineBootstrapperService,
  ) { }

  open = (context: Partial<SidebarContext>) => {
    return this._sidebarMachine.state.value == 'opened'
      ? this._sidebarMachine.send({ type: 'UPDATE_TEMPLATE', ...context })
      : this._sidebarMachine.send({ type: 'OPEN_TEMPLATE', ...context });
  }


  close = () => this._sidebarMachine.state.context.template
    && this._sidebarMachine.send({ type: 'ClOSE_TEMPLATE' })

  setTemplate = (templates: SidebarTemplates) =>
    this.templates = { ...this.templates, ...templates };

  openAccount = () => this.open({
    template: this.templates['account'],
    position: 'start',
    width: 'default'
  });

  openCart = () => this.open({
    template: this.templates['cart'],
    position: 'end',
    width: 'default'
  });

  openHotelFilter = () => this.open({
    template: this.templates['hotelFilter'],
    position: 'end',
    width: 'default'
  });

  openMember = (width: SidebarPanelWidth = 'default', position: SidebarPosition = 'end', backgroundColor: Color = 'default') => this.open({
    template: this.templates['member'],
    position,
    width,
    backgroundColor
  });

  closeOnClickBackdrop() {
    this._blockOverlay.openedChange
      .subscribe(isOpen => {
        if (!isOpen) {
          this.close();
        }
      });
  }
}
