import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';

import { DateTime } from 'luxon';

import { convertToDateTime, GuestDetails, TravelCompanion } from '@shared/api/be-api.generated';
import { arrayFrom, createFormControl, createUntypedFormGroup } from '@shared/common';
import { AnchorType, Gender, genderValues, SelectOption } from '@shared/models/common';
import { AddressSelectOption, CountrySelectOption } from '@shared/models/address';

import { AnimationComponentBase } from '@shared/base/animation-component.base';
import { FormComponent } from '@shared/components/common/form/form.component';

import { genderChange } from '@shared/consts/animations';

import { BookingService } from '@booking/services/booking.service';
import { AddressService } from '@shared/services/address.service';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { PackageService } from '@shared/services/package.service';
import { SearchService } from '@shared/services/search/search.service';
import { TranslocoService } from '@jsverse/transloco';
import { usaCode, zipcodeCountries } from '@shared/consts/common';
import { MemberService } from '@member/member.service';

const currentYear = DateTime.now().year;

const guestFields = [
  'firstName', 'middleName', 'lastName',
  'year', 'month', 'day',
  'phone', 'country',
  'address', 'street', 'city', 'state', 'postcode',
  'gender'
] as const;

export type GuestField = typeof guestFields[number];
type DobOptions = Record<string, SelectOption[]>;
const month = arrayFrom(12, index => ({
  label: DateTime.local(currentYear, index + 1, 1).toFormat('MMMM'),
  value: String(index + 1).padStart(2, '0')
}))

@Component({
  selector: 'app-guest-details',
  templateUrl: './guest-details.component.html',
  animations: [genderChange]
})
export class GuestDetailsComponent extends AnimationComponentBase implements OnChanges, AfterViewInit {
  @Input() guestNumber!: number;
  @Input() guestType!: 'adult' | 'child';
  @Output() mainAdultCountryUpdated: EventEmitter<CountrySelectOption> = new EventEmitter();
  @ViewChild('form') fc!: FormComponent<GuestField>;

  guestForm!: UntypedFormGroup;
  isMainAdult!: boolean;
  isAdult!: boolean;
  isContactInfoRequired!: boolean;
  isLoadingAddress = false;

  guestCart?: GuestDetails;

  isPostcode = true;

  name: GuestField[] = ['firstName', 'middleName', 'lastName'];
  dob: GuestField[] = ['year', 'month', 'day'];

  genderValues = genderValues;
  genders = Object.keys(genderValues) as Gender[];
  animatingStateGender = { male: false, female: false };

  dobOptions!: DobOptions;

  readonly anchorType: AnchorType = 'guestDetailsError';
  get topErrorAnchorId() {
    const topInvalidField = this.fc.getTopInvalidField();
    return topInvalidField
      ? this.getAnchorId(this.anchorType, this.guestType, this.guestNumber, topInvalidField)
      : undefined;
  }

  countries: CountrySelectOption[] = [];
  countriesOptions: SelectOption[] = []
  addressOptions: AddressSelectOption[] = [];
  lastAddressOptions: AddressSelectOption[] = [];

  isFlightSelected = this._package.isFlightSelected;

  isValidDob = true;
  dobErrorFields: string[] = [];
  firstDobErrorField?: GuestField;

  travellers: TravelCompanion[] = [];

  get isCountry() {
    return this.isFlightSelected || this.isMainAdult;
  }

  get isDOB() {
    return this.isFlightSelected || !this.isAdult;
  }

  get guestIndex() {
    return this.guestType === 'adult'
      ? this.guestNumber - 1
      : this._search.getTotalAdults() + this.guestNumber - 1;
  }

  constructor(
    private readonly _transloco: TranslocoService,
    private readonly _booking: BookingService,
    private readonly _package: PackageService,
    private readonly _address: AddressService,
    private readonly _search: SearchService,
    private readonly _member: MemberService,
    services: ComponentBaseService
  ) {
    super(services);
    super.pushSub(
      this.initTravellers(),
      this._member.onMemberChanged(() => {
        this.initTravellers();
        this.initMemberProfile();
      }),
      this._booking.setWhenInitialized(({ countries = [] }) => {
        this.countries = countries.map(({ name: label, twoLetterCode: value, threeLetterCode }) =>
          ({ label, value, threeLetterCode }));
        this.countriesOptions = [...this.countries];
        this.initMemberProfile();
      }),
    );
  }

  initTravellers() {
    this.travellers = [];

    return this._member.profile && this._member.loadTravelers().subscribe(travellers => {
      this.travellers = travellers;
      this._setPersonsOptions();
    });
  }

  initMemberProfile() {
    if (this._member.profile && this.isMainAdult && this.countries.length) {
      this.setFromMemberProfile();
    }
  }

  ngAfterViewInit() {
    this._setAutocomplete();

    if (this.services.ssrHelper.isBrowser) {
      setTimeout(() => {
        if (this.isCountry) {
          this._resetCountry();
        }

        if (this.isDOB) {
          this._setDays();
        }

        this.initMemberProfile();
      });

      this.updateReservation();
    }
  }

  ngOnChanges() {
    this.isAdult = this.guestType === 'adult';
    this.isMainAdult = this.guestNumber === 1 && this.isAdult;
    this.isContactInfoRequired = this.isMainAdult;
    this._setDobOptions();
    this._setCartGuest();
    this.guestForm = this._createFormGroup();
  }

  //#region public
  searchAddress(selected?: AddressSelectOption) {
    const query = selected?.usSuggestion ? undefined : this.fc.get.address<string>();

    if (this.isLoadingAddress) {
      return;
    }

    this.isLoadingAddress = true;
    this._address.search({ query, selected })
      .then(results => {
        this.addressOptions = results;
        const validResults = results.filter(item => !item.isDisabled && !item.isError)
        if (validResults.length) {
          this.lastAddressOptions = validResults;
        }
      })
      .finally(() => {
        this.isLoadingAddress = false;
        this.fc.getFormElement('address').click();
      });
  }

  searchCountry() {
    const input = this.fc.getFormElement<HTMLInputElement>('country').value;
    // if full match, show all values
    this.countriesOptions = !input || this.countries.some(country => country.label === input)
      ? this.countries
      : this.countries.filter(({ label }) =>
        (label?.toString() ?? '')
          .toLowerCase()
          .indexOf(input?.toLowerCase()) > -1);
  }

  selectChange(currentField: GuestField) {
    switch (currentField as GuestField) {
      case 'month':
      case 'year':
        this._setDays();
        this._validateDob();
        break;
      case 'day':
        this._validateDob();
        break;
      case 'country':
        const selectedCountryValue = this.fc.get.country<string>();
        const option = this.countries.find(country => country.value === selectedCountryValue);
        this._address.currentCountry = option?.threeLetterCode;
        this.addressOptions = [];
        this.isPostcode = !zipcodeCountries.includes(selectedCountryValue);
        this._emitMainAdultCountryChange(option);
        break;
      default: break;
    }
  }

  genderChange(currentGender: Gender) {
    this.fc.markAsTouched('gender');
    this.fc.set.gender(genderValues[currentGender]);
    this._animateCustom(isActive => this.animatingStateGender[currentGender] = isActive);
  }

  fixCountryAutocomplete() {
    let valueOrLabel = this.fc.get.country && this.fc.get?.country<string>()?.toLowerCase();
    const getCountryOptionByLabel = () => this.countries.find(item => item.label?.toString()?.toLowerCase() === valueOrLabel);
    if (valueOrLabel) {
      if (valueOrLabel === 'usa') {
        valueOrLabel = 'united states';
      }
      const option = getCountryOptionByLabel();
      if (option) {
        this.fc.set.country(option.value);
        this._emitMainAdultCountryChange(option);
      }
    }
  }

  setDefaultCountry(newOption: CountrySelectOption) {
    if (newOption && !this.isMainAdult && this.isCountry && !this.fc.get.country()) {
      this._setCountry(newOption);
    }
  }

  startVerification() {
    //this.fixCountryAutocomplete();

    this.guestForm.markAllAsTouched();
    this.updateReservation();
  }

  updateReservation() {
    this._validateDob();

    if (!this.guestForm.valid) {
      return;
    }

    const getter = () => undefined;

    const { firstName, lastName, middleName = getter, gender } = this.fc.get;

    const { country = getter, phone = getter } = this.fc.get;
    const { city = getter, state = getter, street = getter, postcode = getter } = this.fc.get;
    const { day = getter, month = getter, year = getter } = this.fc.get;

    const now = DateTime.now()
    const dob = DateTime.fromObject({
      day: day() || now.day,
      month: month() || now.month,
      year: year() || now.year
    });

    const { fromDate } = this._search.state.context;
    const age = !this.isAdult && fromDate
      ? Math.floor(fromDate.diff(dob, ['years']).years)
      : undefined;

    const guestDetails = new GuestDetails({
      age, // should be used to say it's a child
      // email: getValue('email'), // another section will collect this
      firstName: firstName(),
      middleName: middleName(),
      lastName: lastName(),
      country: country(),
      phone: phone(),
      dateOfBirth: this.isDOB
        ? convertToDateTime(`${year()}-${month()}-${day()}`)
        : undefined,
      gender: gender(),

      city: city(),
      postcode: postcode(),
      state: state(),
      street: street()
    });

    if (this.isAdult) {
      this._booking.reservationInput.setAdultDetails(this.guestNumber, guestDetails);
    } else {
      this._booking.reservationInput.setChildDetails(this.guestNumber, guestDetails);
    }
  }

  //#endregion

  //#region private
  private _setCartGuest() {
    const guestIndex = this.guestIndex;

    this.guestCart = this._booking.cartRequest?.guests?.find((_, index) => index === guestIndex);
  }

  private _setDays() {
    const month = Number(this.guestForm.controls['month'].value);
    const year = Number(this.guestForm.controls['year'].value);
    const previousValue = Number(this.guestForm.controls['day'].value);

    if (month >= 0) {
      const daysInMonth = new Date(year || currentYear, month, 0).getDate();

      this.dobOptions['day'] = arrayFrom(daysInMonth, index => ({
        label: index + 1,
        value: String(index + 1).padStart(2, '0')
      }));

      if (previousValue && previousValue <= daysInMonth) {
        const day = this.dobOptions['day'].find(item => item.value == previousValue)
        this.fc.set.day(day?.value);
      }
    }
  }

  private _createFormGroup() {
    const { firstName, lastName, middleName, dateOfBirth, city, country, gender, state, street, phone, postcode } = this.guestCart || {};

    const controls: Record<GuestField, UntypedFormControl | undefined> = {
      firstName: createFormControl(firstName),
      middleName: this.isFlightSelected ? new UntypedFormControl(middleName) : undefined,
      lastName: createFormControl(lastName),
      year: this.isDOB ? createFormControl(String(dateOfBirth?.year)) : undefined,
      month: this.isDOB ? createFormControl(String(dateOfBirth?.month).padStart(2, '0')) : undefined,
      day: this.isDOB ? createFormControl(String(dateOfBirth?.day).padStart(2, '0')) : undefined,
      phone: this.isContactInfoRequired ? new UntypedFormControl(phone, control => !control.value?.trim()
        ? { phoneInvalid: true }
        : null
      ) : undefined,
      country: this.isCountry
        ? createFormControl(country, (control) => !this.countries?.some(country => country.value === control.value)
          ? { countryNotExists: true }
          : null)
        : undefined,
      address: this.isMainAdult ? new UntypedFormControl([street, city, state, postcode].join(', ')) : undefined,
      street: this.isMainAdult ? createFormControl(street) : undefined,
      city: this.isMainAdult ? createFormControl(city) : undefined,
      state: this.isMainAdult ? createFormControl(state) : undefined,
      postcode: this.isMainAdult ? createFormControl(postcode) : undefined,
      gender: new UntypedFormControl(gender, [Validators.required])
    };

    return createUntypedFormGroup(controls);
  }

  private _setAutocomplete() {
    const { closed, search, selected, input } = this.fc.autocomplete;

    super.pushSub(
      closed.subscribe(currentField => {
        switch (currentField) {
          case 'address':
            this.addressOptions = this.addressOptions
              .filter(option => option.isDisabled && !option.isError);
            break;
        }
      }),
      selected.subscribe(({ field: currentField, selected }) => {
        switch (currentField) {
          case 'address':
            const addressSelected = selected as AddressSelectOption;
            const us = addressSelected.usSuggestion;
            const international = addressSelected.inSuggestion;
            if (us) {
              if (us.entries > 1) {
                this.searchAddress(addressSelected);
              }
              else {
                this.fc.set['street']([us?.streetLine, us?.secondary].filter(part => part).join(' '));
                this.fc.set['city'](us?.city);
                this.fc.set['state'](us?.state);
                this.fc.set['postcode'](us?.zipcode);
              }
            }
            else if (international) {
              this.fc.set['street'](international.street);
              this.fc.set['city'](international.locality);
              this.fc.set['state'](international.administrativeArea);
              this.fc.set['postcode'](international.postalCode);
            }

            break;
        }
      }),
      search.subscribe(currentField => {
        switch (currentField) {
          case 'address': this.searchAddress(); break;
          case 'country': this.searchCountry(); break;
        }
      }),
      input.subscribe(currentField => {
        switch (currentField) {
          case 'country':
            const value = this.fc.getFormElement<HTMLInputElement>('country').value;
            if (value.toLocaleLowerCase() === 'usa') {
              this.fc.set['country'](usaCode);
              this.fc.getFormElement<HTMLInputElement>('country').value =
                this.countries.find(country => country.value == usaCode)?.label?.toString() || value;
              this.selectChange('country');
            }
            this.fixCountryAutocomplete();
            this.searchCountry();
            break;
        }
      })
    );
  }

  private _setDobOptions() {
    this.dobOptions = this.isAdult ? this._getAdultDobOptions() : this._getChildDobOptions();
  }

  private _getAdultDobOptions(): DobOptions {
    return {
      month,
      days: [],
      year: arrayFrom(100, index => ({
        label: (currentYear - index - 16).toString(),
        value: (currentYear - index - 16).toString()
      }))
    };
  }

  private _getChildDobOptions(): DobOptions {
    return {
      month,
      days: [],
      year: arrayFrom(17, index => ({
        label: (currentYear - index).toString(),
        value: (currentYear - index).toString()
      }))
    };
  }

  private _validateDob() {
    const dobControls = this.dob
      .map(field => ({ control: this.guestForm.get(field), field }));

    this.isValidDob = dobControls
      .map(({ control }) => !control?.touched || !!control.value)
      .reduce((part1, part2) => part1 && part2, true);

    const allInvalid = dobControls
      .filter(({ control }) => control && !control?.value);

    this.dobErrorFields = allInvalid
      .map(item => this._transloco.translate('guest.' + item?.field));

    this.firstDobErrorField = allInvalid.find(() => true)?.field;
  }

  private _emitMainAdultCountryChange(newOption?: CountrySelectOption) {
    if (this.isMainAdult && newOption) {
      this.mainAdultCountryUpdated.emit(newOption);
    }
  }

  private _setCountry(newOption?: CountrySelectOption) {
    if (newOption) {
      this.fc.set.country(newOption.value);
      this.fc.getFormElement<HTMLInputElement>('country').value = newOption.label?.toString() || 'country';
      this.selectChange('country');
    }
  }

  private _resetCountry() {
    const country = this.fc.get.country<string>();
    if (country) {
      this._setCountry(this.countries.find(item => item.value === country));
    }
  }
  //#endregion

  //#region test
  testEnterDetails() {
    const createValue = (value: string) => [this.guestNumber, value].join('');

    const setValue = (field: GuestField, text?: string | number | DateTime) =>
      this.fc.set[field](text || createValue(field));

    const setOption = (field: GuestField, option: SelectOption) =>
      setValue(field, option.value);

    if (this.isContactInfoRequired) {
      //setText('email', this.adultNumber + 'email@email.email');
      setValue('phone');
    }

    if (this.isCountry) {
      this._setCountry(this.countries.find(c => c.value === 'UA')
        || this.countries.find(c => c.value === usaCode));
    }

    if (this.isMainAdult) {
      // this.fc.getFormElement<HTMLInputElement>('address').value = createValue('address');
      // setValue('address');

      setValue('street', '36/5 Shchyretska St.');
      setValue('city', 'Lviv');
      setValue('state', 'Lvivska Oblast');
      setValue('postcode', '79071');
    }

    if (this.isFlightSelected) {
      setValue('middleName');
    }

    setValue('firstName');
    setValue('lastName');

    if (this.isFlightSelected) {
      if (this.isAdult) {
        setOption('year', { label: '1990', value: '1990' });
      }
      setOption('month', { label: '1', value: '01' });
      this._setDays();
      setOption('day', { label: '1', value: '01' });
    }

    setValue('gender', genderValues.male);
  }
  //#endregion

  //#region Member
  personsOptions: SelectOption[] = [];

  private _setPersonsOptions() {
    if (this._member.profile) {
      const travellers = this.travellers;
      //for now member will be used as first default
      //const { firstName, lastName, memberId } = this._member.profile;

      this.personsOptions = [
        { label: this._transloco.translate('guest.addNew'), value: undefined },
        //{ label: firstName + ' ' + lastName, value: memberId },
        ...travellers.map(item => ({ label: item.name, value: item.id }))
      ];
    }
  }

  setPerson(option?: SelectOption) {
    const value = option?.value;
    if (value === this._member.profile?.memberId) {
      return this.setFromMemberProfile();
    } else {
      const item = this.travellers.find(traveller => traveller.id === Number(value));
      if (item) {
        return this.setFromTraveller(item);
      }
    }

    this.setForm({});
  }

  setFromTraveller(traveller: TravelCompanion) {
    const dob = traveller.dob ? convertToDateTime(traveller.dob) : undefined;
    this.setForm({
      firstName: traveller.name?.split(' ').shift(),
      lastName: traveller.name?.split(' ').splice(1).join(' '),
      day: dob?.day,
      month: dob?.month,
      year: dob?.year,
      phone: traveller.phone,
      gender: Number(traveller.gender) || undefined
    });
  }

  setFromMemberProfile() {
    const profile = this._member.profile;
    if (profile) {
      const dob = profile.dob ? convertToDateTime(profile.dob) : undefined;
      this.setForm({
        firstName: profile.firstName,
        lastName: profile.lastName,
        city: profile.city,
        country: profile.country,
        // gender: ???
        // middleName:
        day: dob?.day,
        month: dob?.month,
        year: dob?.year,
        phone: profile.phone,
        postcode: profile.zip,
        state: profile.state,
        street: profile.address1
      });
    }
  }

  setForm(data: Partial<Record<GuestField, (string | number | DateTime)>>) {
    const setValue = (field: GuestField, data?: string | number | DateTime) =>
      this.fc.set[field](data);

    const setOption = (field: GuestField, option: SelectOption) =>
      setValue(field, option.value);

    if (this.isContactInfoRequired) {
      setValue('phone', data.phone);
    }

    if (this.isCountry) {
      this._setCountry(this.countries.find(c => c.value === data.country));
    }

    if (this.isMainAdult) {
      setValue('street', data.street);
      setValue('city', data.city);
      setValue('state', data.state);
      setValue('postcode', data.postcode);
    }

    if (this.isFlightSelected) {
      setValue('middleName');
    }

    setValue('firstName', data.firstName);
    setValue('lastName', data.lastName);

    if (this.isFlightSelected) {
      if (this.isAdult) {
        setOption('year', { label: data.year?.toString(), value: data.year?.toString() });
      }

      const month = this.dobOptions['month'].find(item => item.value == data.month)
      if (month) {
        setOption('month', month);
        this._setDays();
        const day = this.dobOptions['day'].find(item => item.value == data.day);
        setValue('day', day?.value?.toString());
      } else {
        setValue('month', undefined);
        setValue('day', undefined);
      }
    }

    setValue('gender', data.gender);
  }
  //#endregion
}
