<ng-container *transloco="let t">
  <app-sidebar-layout
    #sidebar>
    <ng-container
      *ngIf="current">
      <ng-template #header>
        <div class="sidebar-layout-header">
          <div class="sidebar-layout-header-text">
            <h2>{{current.name}}</h2>
            <app-icon-button
              class="bs-right"
              stroked="iconInactive"
              buttonClass="close-button"
              (click)="sidebar.close()"
              name="close">
            </app-icon-button>
          </div>
        </div>
      </ng-template>
      <ng-template #content>
        <div class="sidebar-content sidebar-scroll-container">
          <div class="sidebar-carousel-images-container">
            <app-gallery
              [imagePopup]="false"
              [images]="images">
            </app-gallery>
          </div>
          <p>{{current.description}}</p>
          <div class="flex-column gap">
            <div>{{t('airportTransfer.label')}}</div>
            <div class="flex-between gap-half cursor-pointer"
              *ngIf="arrivalAirport"
              (click)="selectAsInbound()">
              <app-icon
                name="checked"
                size="default"
                [class.active]="current.id === inbound?.id"
                [class.bc-selected]="current.id === inbound?.id"
                class="select-button select-icon padding-less">
              </app-icon>
              {{t('airportTransfer.fromAirportToHotel', { value: arrivalAirport.name })}}
            </div>
            <div class="flex-between gap-half cursor-pointer"
              *ngIf="arrivalAirport"
              (click)="selectAsOutbound()">
              <app-icon
                name="checked"
                size="default"
                [class.active]="current.id === outbound?.id"
                [class.bc-selected]="current.id === outbound?.id"
                class="select-button select-icon padding-less">
              </app-icon>
              {{t('airportTransfer.fromHotelToAirport', { value: arrivalAirport.name })}}
            </div>
            <div [innerHTML]="t('airportTransfer.note')"></div>
          </div>
        </div>
      </ng-template>
      <ng-template #footer>
        <div class="pricing-container">
          <app-pricing-details-v2
            [config]="pricingConfig">
            <app-airport-transfer-pricing
              [current]="current">
            </app-airport-transfer-pricing>
          </app-pricing-details-v2>
        </div>
      </ng-template>
    </ng-container>
  </app-sidebar-layout>
</ng-container>