import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { ThemeService } from '@shared/services/theming/theme.service';
import Logger from 'js-logger';


@Injectable({
  providedIn: 'root'
})
class PermissionsService {
  private _tenantId = '';
  constructor(
    private readonly _theme: ThemeService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const tenantId = route.params['tenantId'];
    if (!this._tenantId) {
      Logger.debug('Activating tenant #', tenantId);
      this._tenantId = tenantId;
      this._theme.setThemeForTenant(tenantId);
    }

    return true;
  }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(PermissionsService).canActivate(next, state);
}
