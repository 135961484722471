import { Component, HostBinding } from '@angular/core';
import { ComponentBase } from '@shared/base/component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { MemberService } from '@member/member.service';
import { MemberLevelBenefit, MembershipLevel } from '@shared/api/be-api.generated';
import { BreadCrumbsItem } from '@shared/components-v2/common/bread-crumbs/bread-crumbs.component';

class MemberLevelBenefitView extends MemberLevelBenefit {
  constructor(data: MemberLevelBenefit) {
    super(data);

    this.isAnyBenefit = !!data.monetaryDiscount || !!data.percentageDiscount || !!data.resortCreditAmount || !!data.perks?.length;
  }

  isAnyBenefit = false;
}

@Component({
  selector: 'app-member-rewards-v2',
  templateUrl: './member-rewards.component.html'
})
export class MemberRewardsV2Component extends ComponentBase {
  constructor(
    private readonly _member: MemberService,
    service: ComponentBaseService
  ) {
    super(service);
    this.init();
  }

  @HostBinding('class') hostClasses = ['w-100', 'flex-stretch-stretch', 'h-100-min'];
  levels: MembershipLevel[] = [];
  benefits: Record<string, MemberLevelBenefitView> = {};
  isLoading = true;
  errorMessage?: string;
  breadCrumbs: BreadCrumbsItem[] = [{ caption: 'caption' }];
  currentLevel = this._member.profile?.memberLevel;

  init() {
    this.pushSub(
      this._member.getMembershipLevels().subscribe({
        next: ({ levels = [], benefits = [] }) => {
          this.levels = levels;
          benefits.forEach(benefit => benefit.memberLevel
            && (this.benefits[benefit.memberLevel] = new MemberLevelBenefitView(benefit)));
        },
        error: ex => this._member.getMembershipError(ex),
        complete: () => this.isLoading = false
      })
    );
  }
}
