<ng-container *transloco="let t">
  @if(nonMonetary.length){
  @for (item of nonMonetary; track $index) {
  <div class="flex-start text-0_875-1_5-500 gap-half">
    <span>
      @if (item.amount) {
      <span class="color-textSuccessPrice fw-600">
        {{item.amount | appCurrency}}&nbsp;
      </span>
      }
      {{item.text}}
    </span>
    @if (item.membershipKind) {
    <app-info-label
      class="member-bonus"
      [text]="t('member.bonus.singular')"
      type="memberDefault">
    </app-info-label>
    }
  </div>
  }
  }
</ng-container>