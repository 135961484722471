<div class="flex-column"
  [class.flex-100]="isEditMode"
  *ngIf="!isHidden">

  @if(isEditMode){
  <div class="flex-1">
    <ng-template [ngTemplateOutlet]="editor"></ng-template>
  </div>
  }
  @else {
  <div class="flex-start gap cursor-pointer"
    (click)="clickViewText()">
    <span class="link">{{viewText}}</span>
    <app-icon name="next"
      size="small"
      stroked="selected">
    </app-icon>
  </div>
  }
</div>