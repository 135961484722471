import { Component, ContentChild, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { selectTabContentChange } from '@shared/consts/animations';

@Component({
  selector: 'app-select-tab-v2',
  templateUrl: './select-tab.component.html',
  styleUrls: ['./select-tab.component.scss'],
  animations: [selectTabContentChange]
})
export class SelectTabV2Component<T extends string> implements OnChanges {
  @Input() keys: T[] = [];
  @Input() scope!: 'member';
  @Input() selectedKey?: T;
  @Output() selectedKeyChange = new EventEmitter<T>();
  @ContentChild('content') content!: TemplateRef<unknown>;
  readonly subScope = 'select';
  keyPrefix!: string;
  isAnimating = false;

  ngOnChanges(_: SimpleChanges): void {
    this.keyPrefix = [this.scope, this.subScope].join('.') + '.';
    this.selectKey(this.selectedKey ?? this.keys.find(() => true), true);
  }

  selectKey(key?: T, force = false) {
    if (!this.isAnimating && (key !== this.selectedKey || force)) {
      this.selectedKey = key;
      this.selectedKeyChange.emit(key);

      this.isAnimating = !this.isAnimating;
      setTimeout(() => this.isAnimating = !this.isAnimating, 500);
    }
  }
}
