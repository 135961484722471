<ng-container *transloco="let t">
  <div class="flex-column bc-default padding w-100">
    <app-bread-crumbs-v2
      class="padding-top"
      [items]="breadCrumbs"
      section="member.bookings">
    </app-bread-crumbs-v2>
    <div class="text-1-1_5-400 margin-vertical">
      {{t('member.bookings.description')}}
    </div>
    @if(isLoading){
    <div class="flex-column gap margin-top">
      <app-loading-icon size="button">
      </app-loading-icon>
      <div class="flex text-1-1-500 color-selected">
        {{t('member.bookings.loading')}}
      </div>
    </div>
    }
    @else if(!bookings.length) {
    <div class="flex text-1-1-500 color-selected">
      {{t('member.bookings.nothing')}}
    </div>
    }

    @for (booking of bookings; track $index) {
    <div class="padding-horizontal"
      [class.border-left]="!isOpened[$index]"
      [class.border-selected-left]="isOpened[$index]">
      <div class="flex-start gap-quarter cursor-pointer"
        (click)="toggleOpen($index)">
        <app-icon circleSize="middle"
          [circle]="isOpened[$index] ? 'selected' : 'textInactive'">
          <span class="color-default text-1-1-500">{{isOpened[$index] ? '-' : '+'}}</span>
        </app-icon>
        <div class="text-1_125-1_125-500 padding-vertical"
          [class.color-selected]="isOpened[$index]"
          [class.color-textInactive]="!isOpened[$index]">
          <i>{{booking.nights | nights}}</i>
        </div>
      </div>
      <div class="flex-between gap flex-wrap text-1-1-500 margin-top-half collapsable"
        [class.collapsed]="!isOpened[$index]">
        <div class="flex-column gap">
          <div>
            <div class="text-0_75-0_75-400 color-formControl">Booked at: </div>
            <div class="text-1-1-500">{{booking.bookingDate | appDate: 'type4'}}</div>
          </div>
          <div>
            <div class="text-0_75-0_75-400 color-formControl">Booking number: </div>
            <div>{{booking.bookingNumber}}</div>
          </div>
        </div>

        <div>
          <div class="text-0_75-0_75-400 color-formControl">Arrival:</div>
          <div class="flex-between">
            <app-icon
              size="middle"
              filled="textPrimary"
              name="arrival">
            </app-icon>
            <div class="text-1-1-500">
              {{booking.arrivalDate | appDate: 'type3'}}
            </div>
          </div>
          <div class="text-0_75-0_75-400 color-formControl margin-top">Departure:</div>
          <div class="flex-between">
            <app-icon
              size="middle"
              filled="textPrimary"
              name="departure">
            </app-icon>
            <div class="text-1-1-500">
              {{booking.departureDate | appDate: 'type3'}}
            </div>
          </div>
        </div>
        <div class="flex-column gap">
          <div>
            <div class="text-0_75-0_75-400 color-formControl">Resort:</div>
            <div class="flex-between">
              <app-icon
                size="middle"
                filled="textPrimary"
                name="resort">
              </app-icon>
              <div class="text-1-1-500">{{booking.resortName}}</div>
            </div>
          </div>
          <div>
            <div class="text-0_75-0_75-400 color-formControl">Room:</div>
            <div class="flex-between">
              <!-- <app-icon
                          size="middle"
                          filled="textPrimary"
                          name="resort">
                        </app-icon> -->
              <div class="text-1-1-500">{{booking.roomTypeCaption}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    }
  </div>
</ng-container>
