<ng-container *transloco="let t">
  <div class="search-bar"
    id="search-bar"
    [class.short-view]="isShortViewClass"
    [style.background-color]="backgroundColor"
    [class.flights-included]="ratePlanType?.flightsIncluded">
    <div class="mat-drawer-backdrop"
      [class.mat-drawer-shown]="departureAirportSelect.airportSettings.isActive">
    </div>
    <div class="collapsed-container hidden-mobile-min cursor-pointer"
      [@searchBarCollapsedChange]="!isOpenedShortView"
      (click)="toggleCollapse()">
      <div class="flex">
        <div class="flex">
          <app-icon-button
            name="search"
            stroked="textPrimary"
            iconSize="default"
            filled="default">
          </app-icon-button>
        </div>
        <div class="flex-column gap-quarter"
          *ngIf="dateRange.value.start && dateRange.value.end">
          <div class="flex-start"><b>{{resort?.name}}</b></div>
          <div class="flex-start fw-500">
            {{dateRange.value.start | appDate: 'type7'}} - {{dateRange.value.end | appDate: 'type7'}}
            <ng-container *ngIf="isFlightsIncluded">, {{departureAirport?.name}}</ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="main-container"
      [@searchBarCollapsedChange]="isCollapsed">
      <div class="resorts-container">
        <ng-container [ngTemplateOutlet]="hotelSelect"></ng-container>
      </div>

      <div class="airports-switcher-container hidden-mobile-min"
        *ngIf="ratePlanWithFlights">
        <ng-container [ngTemplateOutlet]="airportSwitcher"></ng-container>
      </div>
      <div class="airports-container"
        [hidden]="!isFlightsIncluded">
        <app-airport-select
          #departureAirportSelect
          [airport]="departureAirport"
          [airports]="departureAirports"
          [showFirstByDefault]="false"
          [isFullWidthMobile]="true"
          iconSize="default"
          type="departure"
          (selectChange)="onAirportSelect($event, true)">
        </app-airport-select>
      </div>
      <div class="range-container"
        [@airportsVisibilityChange]="isAnimateDatePicker">
        <ng-container [ngTemplateOutlet]="range"></ng-container>
      </div>
      <div class="buttons-container flex-1 flex-end gap">
        <ng-container [ngTemplateOutlet]="buttons"></ng-container>
      </div>
    </div>
  </div>
  <div class="bottom-container hidden-mobile-max">
    <div class="flex-between">
      <div class="flex-start airports-switcher-container">
        <ng-container *ngTemplateOutlet="airportSwitcher"></ng-container>
      </div>
    </div>
  </div>

  <ng-container #templates>
    <ng-template #hotelSelect>
      <mat-form-field>
        <mat-select
          #select
          class="hotel-select"
          [disabled]="!isOnSearchPage"
          [compareWith]="compareResort"
          [value]="resort"
          (selectionChange)="resortChanged($event.value)">
          <mat-select-trigger>
            <div class="flex-start">
              <app-icon
                size="middle"
                filled="textPrimary"
                name="resort">
              </app-icon>
              <div class="three-dots">{{select.value.name}}</div>
            </div>
          </mat-select-trigger>
          <mat-option
            *ngFor="let resort of resorts; trackBy: 'id' | createTrackBy"
            [value]="resort">
            {{resort.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>
    <ng-template #range>
      <div class="p-inputgroup">
        <mat-form-field
          *ngIf="dateRange">
          <mat-date-range-input
            [rangePicker]="picker"
            [formGroup]="dateRange"
            (click)="picker.open()">
            <input
              id="startDate"
              formControlName="start"
              readonly
              matStartDate
              [placeholder]="t('page.search.startDate')">
            <input
              id="endDate"
              formControlName="end"
              readonly
              matEndDate
              [placeholder]="t('page.search.endDate')">
          </mat-date-range-input>
          <mat-datepicker-toggle
            matPrefix
            [for]="picker">
            <app-icon matDatepickerToggleIcon
              class="padding-less"
              size="middle"
              name="calendar">
            </app-icon>
          </mat-datepicker-toggle>
          <mat-date-range-picker #picker>
          </mat-date-range-picker>
        </mat-form-field>
      </div>
    </ng-template>
    <ng-template #airportSwitcher>
      <app-icon
        (click)="toggleIsFlightsIncluded()"
        name="checked"
        size="default"
        [class.active]="isFlightsIncluded"
        [class.bc-background]="isFlightsIncluded"
        class="checkbox-icon">
      </app-icon>
      <div class="text">
        <span class="cursor-pointer" (click)="toggleIsFlightsIncluded()">{{t(isFlightsIncluded ? 'flight.added': 'flight.add')}}</span>
        <span class="saved break-spaces" *ngIf="!isFlightsIncluded">{{t('flight.saveOn')}}</span>
      </div>
    </ng-template>
    <ng-template #buttons>
      <button mat-raised-button
        class="select-button search padding-horizontal-double hidden-mobile-min"
        (click)="toggleCollapse()">
        {{t('button.close')}}
      </button>
      <button mat-raised-button
        color="primary"
        [disabled]="isLoading"
        class="select-button search padding-horizontal-double apply"
        (click)="apply()">
        @if (isLoading) {
        <app-loading-icon size="button"></app-loading-icon>
        &nbsp;
        }
        {{t('button.search')}}
      </button>
    </ng-template>
  </ng-container>
</ng-container>