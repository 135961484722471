import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Package } from '@shared/api/be-api.generated';
import { WithId } from '@shared/models/common';
import { AppCurrencyPipe } from './app-currency.pipe';

@Pipe({ name: 'packageDiscount' })
export class PackageDiscountPipe implements PipeTransform {
  constructor(
    private readonly _appCurrencyPipe: AppCurrencyPipe,
    private readonly _transloco: TranslocoService
  ) { }

  transform(currentPackage: WithId<Package> | Package | undefined, discountValue = 0) {
    if (currentPackage || discountValue) {

      if (currentPackage) {
        const { basePrice = 0, finalPrice = 0 } = currentPackage;

        if (basePrice && finalPrice) {
          discountValue = Math.floor(basePrice - finalPrice);
        }
      }

      if (discountValue > 1) {
        return [
          this._appCurrencyPipe.transform(discountValue),
          this._transloco.translate('price.off')
        ].join(' ')
      }
    }

    return '';
  }
}
