import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ConfirmationService } from '@confirmation/services/confirmation.service';
import { buildResortAddress, createCarouselImages } from '@shared/common';
import { DataService } from '@shared/services/data.service';

@Component({
  selector: 'app-need-help',
  templateUrl: './need-help.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NeedHelpComponent {
  booking = this._confirmation.values.bookingDetails;

  organizationName = this._data.tradingName;
  resort = this._data.values.resorts
    .find(resort => resort.resortId === this.booking.accommodation?.resortId);
  resortAddress = buildResortAddress(this.resort?.address);
  resortImages = createCarouselImages(this.resort);
  firstResortImageSrc = this.resortImages?.find(() => true)?.image;

  phone = this.resort?.phone;
  email = this.resort?.email;

  constructor(
    private readonly _confirmation: ConfirmationService,
    private readonly _data: DataService
  ) {
  }
}
