<ng-container *transloco="let t">
  @if(!unavailabilityReason){
  <div [ngClass]="['addon-pricing', size]">
    <div class="header"
      [class.with-price]="price !== undefined && !unavailabilityReason"
      *ngIf="currentAddOn">
      <div class="header-default uppercased">
        {{addOnType | addOnType}}
      </div>
      <div class="price-final"
        *ngIf="price && price > 0">
        {{price | appCurrency}}
      </div>
      <div class="price-final color-textSuccessPrice"
        *ngIf="price === 0">
        {{t('price.free')}}
      </div>
    </div>
    <div class="price"
      *ngIf="currentAddOn && !unavailabilityReason">
      <div class="age-groups" *ngIf="isAgeGroupsAddOn; else singlePriceTemplate">
        <div>
          <ng-container *ngIf="addOnsToPurchase.length">
            <span class="link" (click)="change()">
              {{t('button.change')}}
            </span>
          </ng-container>
        </div>
      </div>
      <ng-template #singlePriceTemplate>
        <div class="price-counter">
          <app-icon-button
            name="minus"
            filled="default"
            buttonSize="default"
            [disabled]="countToPurchase <= 1 || !!unavailabilityReason"
            [stroked]="(countToPurchase > 1 && !unavailabilityReason) ? 'selected': 'border'"
            alt="decrease"
            (click)="decrease()">
          </app-icon-button>
          <span class="price-counter-text">{{countToPurchase}}</span>
          <app-icon-button
            name="plus"
            filled="default"
            buttonSize="default"
            [disabled]="countToPurchase >= maxCountAddonsToPurchase || !!unavailabilityReason"
            [stroked]="(countToPurchase < maxCountAddonsToPurchase && !unavailabilityReason) ? 'selected': 'border'"
            alt="increase"
            (click)="increase()">
          </app-icon-button>
        </div>
      </ng-template>
    </div>
    <!-- @if (unavailabilityReason){
    <div class="price">
      <div class="not-found">
        {{t('price.' + unavailabilityReason.code , { value: unavailabilityReason.value})}}
      </div>
    </div>
    } -->
  </div>
  }
</ng-container>