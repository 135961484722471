import { TranslocoService } from '@jsverse/transloco';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

export type FilterGroupItem = {
  captionKey?: string;
  caption?: string;
  value?: string;
  capitalized?: boolean;
};

@Component({
  selector: 'app-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterGroupComponent implements OnChanges {
  @Input() maxDefaultVisibleItemsCount = 5;
  @Input() items: FilterGroupItem[] = [];
  @Input() scope!: string;
  @Input() selectedItem?: FilterGroupItem;
  @Input() selectedItems?: FilterGroupItem[];
  @Output() selectedChanged = new EventEmitter<FilterGroupItem>();
  @Output() selectedItemsChanged = new EventEmitter<FilterGroupItem[]>();
  @Input() isFirst = false;
  @Input() isCheckbox? = false;

  @Input() translateKeyPrefix!: string;

  visibleItems: FilterGroupItem[] = [];

  areAllAvailable = false;
  isItemsExpanded = false;

  constructor(private _transloco: TranslocoService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items'] && this.scope && this.translateKeyPrefix) {
      this._updateItems();
      this.areAllAvailable = this.items.length <= this.maxDefaultVisibleItemsCount;
      this._setVisibleItems();
    }
  }

  private _setVisibleItems() {
    this.visibleItems = this.areAllAvailable || this.isItemsExpanded
      ? this.items
      : this.items.filter((_, index) => this.maxDefaultVisibleItemsCount > index);
  }

  selectItem(item: FilterGroupItem) {
    if (this.isCheckbox) {
      this.selectedItems = this.selectedItems || [];
      if (this.selectedItems.map(selected => selected.value).includes(item.value)) {
        this.selectedItems = this.selectedItems.filter(selected => selected.value !== item.value);
      } else {
        this.selectedItems.push(item);
      }
      this.selectedItemsChanged.emit(this.selectedItems);
    } else {
      this.selectedChanged.emit(item);
    }
  }

  toggleIsItemsExpand() {
    this.isItemsExpanded = !this.isItemsExpanded;
    this._setVisibleItems();
  }

  private _updateItems() {
    this.items.forEach(item => item.caption = item.caption
      || this._transloco.translate([this.translateKeyPrefix, this.scope, item.captionKey].join('.')));
  }
}
