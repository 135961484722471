<ng-container *transloco="let t">
  <div class="manage-booking flex-column gap">
    <div class="manage-booking-email">
      <div class="section">
        <div class="section-header">
          <div class="section-header-text">
            {{t('manageBooking.section.header')}}
            <app-anchor type="manageBookingError"></app-anchor>
          </div>
        </div>
        <div class="section-subheader">
          {{t('manageBooking.section.subheader')}}
        </div>
      </div>
      <app-form
        #form
        (nextChanged)="apply()"
        [form]="manageBookingForm"
        scope="manageBooking">
        <ng-template #content let-text="text">
          <div class="email-value">
            <ng-container *ngTemplateOutlet="text; context: { field: 'email' }"></ng-container>
          </div>
          <div class="loyaltyId-value"
            [hidden]="!isVisibleLoyalty">
            <ng-container *ngTemplateOutlet="text; context: { field: 'loyaltyId' }"></ng-container>
          </div>
        </ng-template>
      </app-form>
    </div>
    <div class="account-prompt flex-start gap"
      *ngIf="false | isMemberhsipEnabled">
      <div class="account-prompt-text">
        {{t('manageBooking.account.prompt')}}
      </div>
      <div class="account-prompt-link link"
        (click)="openAccount()">
        {{t('manageBooking.account.signIn')}}
      </div>
    </div>
  </div>
</ng-container>