import { Injectable } from '@angular/core';
import { AreAllImplementationsAssumedToBeProvided, interpret, Interpreter, MissingImplementationsError, ResolveTypegenMeta, State, TypegenDisabled } from 'xstate';
import { BaseActionObject, EventObject, NoInfer, ServiceMap, StateConfig, StateMachine, StateSchema, Typestate } from 'xstate/lib/types';
import { TransferStateService } from '../transfer-state.service';
import { SsrHelperService } from '../ssr-helper.service';
import { StateMachineContainer } from './state-machine-container';
import { deserializeContext } from './state.mapping';

const transferStatePrefix = 'SM_';

interface MachineBootstrapOptions {
  runActionsUponHidration?: boolean;
  doNotPersistInSsr?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class StateMachineBootstrapperService {

  constructor(
    private readonly _universalRenderHelper: SsrHelperService,
    private readonly _transferState: TransferStateService
  ) { }

  /**
   * Interprets state machine and tries to restore it's state from persistate state if available.
   * This could happen if interpreter has been executed during SSR.
   * @param machine State machine to interpret.
   * @param options Machine bootstrap options.
   * @returns Wrapped state machine interpreter.
   */
  bootstrapMachine<TContext, TStateSchema extends StateSchema, TEvent extends EventObject, TTypestate extends Typestate<TContext>, TAction extends BaseActionObject = BaseActionObject, TServiceMap extends ServiceMap = ServiceMap, TResolvedTypesMeta = ResolveTypegenMeta<TypegenDisabled, NoInfer<TEvent>, TAction, TServiceMap>>(
    machine: AreAllImplementationsAssumedToBeProvided<TResolvedTypesMeta> extends true
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ? StateMachine<TContext, TStateSchema, TEvent, TTypestate, any, any, TResolvedTypesMeta>
      : MissingImplementationsError<TResolvedTypesMeta>,
    options?: MachineBootstrapOptions
  ) {
    const { id: machineId } = machine as StateMachine<TContext, TStateSchema, TEvent, TTypestate, TAction, TServiceMap, TResolvedTypesMeta>;

    const persistedState = this.getPersistedStateIfExists<TContext, TEvent>(machineId);
    if (!options?.runActionsUponHidration && persistedState) {
      persistedState.actions = [];
    }

    let interpreter = interpret(machine);
    interpreter = this.addListeners(interpreter, options);
    interpreter = interpreter.start(persistedState || undefined);
    return new StateMachineContainer(interpreter, persistedState != null);
  }

  private getPersistedStateIfExists<TContext, TEvent extends EventObject>(machineId?: string) {
    if (!machineId) {
      return null;
    }

    const key = this.getTransferKey(machineId);
    const persistedState = this._transferState.get<string | null>(key, null);
    return persistedState
      ? State.create(JSON.parse(persistedState, function (key, value) {
        if (key === 'context') {
          return deserializeContext(value, machineId);
        }
        return value;
      }) as StateConfig<TContext, TEvent>)
      : null;
  }

  private getTransferKey(machineId: string) {
    return `${transferStatePrefix}${machineId}`;
  }

  private addListeners<TContext, TStateSchema extends StateSchema, TEvent extends EventObject, TTypestate extends Typestate<TContext>, TResolvedTypesMeta>(
    interpreter: Interpreter<TContext, TStateSchema, TEvent, TTypestate, TResolvedTypesMeta>,
    optoins?: MachineBootstrapOptions
  ) {
    if (this._universalRenderHelper.isServer && !optoins?.doNotPersistInSsr) {
      const key = this.getTransferKey(interpreter.id);
      interpreter = interpreter.onTransition((state) => {
        this._transferState.set(key, JSON.stringify(state.toJSON()));
      });
    }

    return interpreter;
  }
}
