<ng-container *transloco="let t">
  <div class="notification-signup"
    *ngIf="false | isMemberhsipEnabled">
    <div class="main-container">
      <app-notification
        backgroundColor="default"
        borderWidht="thick"
        borderColor="highlighted">
        <ng-template #badge>
          <app-info-label
            [text]="t('member.plural')"
            type="highlighted">
          </app-info-label>
        </ng-template>
        <ng-template #content>
          <div class="notification-signup-content">
            <div class="text-main">
              {{t('notification.signup.main')}}
            </div>
            <div class="text-addition"
              [class.hidden-tablet-max]="!isOnlyMobileView"
              [class.hidden-desktop-max]="isOnlyMobileView">
              {{t('notification.signup.addition')}}
            </div>
            <div class="text-link link"
              (click)="openAccount()">
              {{t('notification.signup.link')}}
            </div>
          </div>
        </ng-template>
      </app-notification>
    </div>
  </div>
</ng-container>