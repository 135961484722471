import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { ComponentBase } from '@shared/base/component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { MemberService } from '@member/member.service';
import { SidebarService } from '@shared/services/sidebar/sidebar.service';

@Component({
  selector: 'app-sign-in-banner-v2',
  templateUrl: './sign-in-banner.component.html',
  styleUrls: ['./sign-in-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInBannerV2Component extends ComponentBase {
  @HostBinding('class.hidden') isHidden = !this.services.tenant.loyaltyEnabled || !!this._member.profile;
  readonly bannerClass = 'sing-in-banner';

  constructor(
    private readonly _sidebar: SidebarService,
    private readonly _member: MemberService,
    services: ComponentBaseService,
  ) {
    super(services);
    this.pushSub(_member.onMemberChanged(({ profile }) => !!profile && this.hideBanner()));
  }

  signIn() {
    this._sidebar.openMember();
    this.hideBanner();
  }

  closeBanner(target: EventTarget | null) {
    if (!(target instanceof Element) || !target.closest('.' + this.bannerClass)) {
      this.hideBanner();
    }
  }

  hideBanner() {
    this.isHidden = true;
  }
}
