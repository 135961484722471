import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TimeWindowAsArray } from '@shared/api/be-api.generated';
import { TimeWindow, IFlightSearch } from '@shared/api/be-api.generated';

import { defaultStopsCount, totalStopsCount } from '@shared/consts/common';

import { PackageService } from '@shared/services/package.service';
import { SearchService } from '@shared/services/search/search.service';

import { DataService } from '@shared/services/data.service';
import { FilterGroupItem } from '@search/components/shared/filter-group/filter-group.component';
import { QueryParamsService } from '@shared/services/query-params.service';
import { ComponentBase } from '@shared/base/component.base';
import { PackagesSortType } from '@shared/models/common';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { asFilterItems, asFilterItemsFromSet, FlightFilterGroup, FlightFilterScope, flightFilterScopes, FlightFilterSelectValueDecider } from './flight-filter';
import { TranslocoService } from '@jsverse/transloco';

// [0, 1, 2] expects
const departureWindows = TimeWindowAsArray;
const arrivalWindows = TimeWindowAsArray;
const getValueFromCabinTypes = (cabinTypes: string[]) => cabinTypes.join('|');
const getCabinTypesFromValue = (cabinTypesValue: string) => cabinTypesValue.split('|');

@Component({
  selector: 'app-flight-filter',
  templateUrl: './flight-filter.component.html',
  styleUrls: ['./flight-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlightFilterComponent extends ComponentBase {
  readonly translateKeyPrefix = 'flight.filter'
  groups: FlightFilterGroup[] = [];

  groupedCabinTypes: { caption: string, codes: ('Y' | 'S' | 'F' | 'P' | 'C' | 'J')[] }[] = [
    { caption: 'economy', codes: ['S', 'Y'] },
    { caption: 'business', codes: ['P', 'J'] },
    { caption: 'first', codes: ['C', 'F'] },
    // { caption: 'First/Bussines', codes: ['P', 'J', 'C', 'F'] }
  ];

  constructor(
    private readonly _transloco: TranslocoService,
    private readonly _package: PackageService,
    private readonly _search: SearchService,
    private readonly _data: DataService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _queryParams: QueryParamsService,
    services: ComponentBaseService
  ) {
    super(services);
    super.pushSub(
      this._data.setWhenInitialized(_ => this._setFilterGroups()),
      this._queryParams.routeChanged.subscribe(_ => this._setFilterGroups())
    );
  }

  reset() {
    this._search.patchFlight({
      airline: undefined,
      arrivalTimeWindow: undefined,
      cabinType: undefined,
      stops: undefined,
      departureTimeWindow: undefined,
    });

    const sortGroup = this.groups.find(item => item.scope === 'byPrice');
    if (sortGroup) {
      this._package.sortPackagesByPrice(sortGroup.items.find(() => true)?.value as PackagesSortType);
    }

    setTimeout(() => this._setFilterGroups());
  }

  select(scope: FlightFilterScope, item: FilterGroupItem) {
    const flightSearch: Partial<IFlightSearch> = {};
    const numberValue = <T extends number>() => item.value ? +item.value as T : undefined;
    switch (scope) {
      case 'byPrice':
        this._package.sortPackagesByPrice(item.value as PackagesSortType);
        break;
      case 'byStops':
        flightSearch.stops = numberValue();
        break;
      case 'byDepartTime':
        flightSearch.departureTimeWindow = numberValue<TimeWindow>();
        break;
      case 'byArrivalTime':
        flightSearch.arrivalTimeWindow = numberValue<TimeWindow>();
        break;
      case 'byCabinType':
        //flightSearch.cabinType = item.value;
        flightSearch.cabinTypes = item.value ? getCabinTypesFromValue(item.value) : undefined;
        break;
      case 'byAirline':
        flightSearch.airline = item.value;
        break;
    }

    this._patchFlightSearch(flightSearch);
  }

  selectItems(scope: FlightFilterScope, items: FilterGroupItem[]) {
    const flightSearch: Partial<IFlightSearch> = {};
    switch (scope) {
      case 'byCabinType':
        flightSearch.cabinTypes = items
          .map(item => item.value?.toString())
          .filter(code => code) as string[];
        break;
    }

    this._patchFlightSearch(flightSearch);
  }

  private _patchFlightSearch(flightSearch: Partial<IFlightSearch>) {
    // is anything to update
    if (Object.keys(flightSearch).length) {
      this._search.patchFlight(flightSearch);
    }
  }

  private _createFilterGroups(): FlightFilterGroup[] {
    const {
      flightSearch: {
        airline, arrivalTimeWindow, cabinTypes, departureTimeWindow, stops,
      } = {}
    } = this._search.state.context;

    const { airlines } = this._data.values;

    const { sortOptions: sortCompareFuncMap } = this._package;
    const { sortType } = this._queryParams.value;

    const anyOption = () => ({ captionKey: 'any', value: undefined } as FilterGroupItem);

    const getStopsTranslateKey = (captionKey?: string) =>
      'flight.' + (captionKey === '0'
        ? 'nonStop'
        : captionKey === '1'
          ? 'stop'
          : 'stops');

    const itemsValues: Record<FlightFilterScope, FilterGroupItem[]> = {
      byPrice: asFilterItemsFromSet(Object.keys(sortCompareFuncMap)),
      byStops: asFilterItems(totalStopsCount)
        .map(({ captionKey, value }) => ({
          value,
          caption: this._transloco.translate(getStopsTranslateKey(captionKey), { count: captionKey })
        })),
      byDepartTime: [anyOption(), ...asFilterItemsFromSet(departureWindows)],
      byArrivalTime: [anyOption(), ...asFilterItemsFromSet(arrivalWindows)],
      // byCabinType: [anyOption(), ...allCabinTypes.map(({ code: value = '', name: caption }) =>
      //   ({ caption, value, capitalized: true }))] || [anyOption()],
      byCabinType: [anyOption(), ...this.groupedCabinTypes.map(({ codes, caption }) =>
      ({
        caption: this._transloco.translate(['cabinType', caption].join('.')),
        value: getValueFromCabinTypes(codes),
        capitalized: true
      }))],
      byAirline: [anyOption(), ...airlines.map(({ code: value = '', displayText: caption }) =>
      ({
        caption,
        value,
        capitalized: true
      }))] || [anyOption()]
    };

    const selectedValuesDeciders: FlightFilterSelectValueDecider = {
      byPrice: item => !sortType || item.value === sortType,
      byStops: item => (stops !== undefined && item.value === stops?.toString())
        || (stops === undefined && item.value === defaultStopsCount.toString()),
      byDepartTime: item => item.value === departureTimeWindow?.toString(),
      byArrivalTime: item => item.value === arrivalTimeWindow?.toString(),
      // byCabinType: item => item.value === cabinType,
      byCabinType: item => !item.value && !cabinTypes?.length === !item.value
        || (!!item.value && !!cabinTypes?.length && item.value === getValueFromCabinTypes(cabinTypes)),
      byAirline: item => item.value === airline,
    };

    // uncomment items to have them as checkbox options
    const selectedItemsValuesDeciders: FlightFilterSelectValueDecider = {
      // byCabinType: item => !cabinTypes || !cabinTypes.length
      //   (!!item.value && cabinTypes?.includes(item.value))
    }

    return flightFilterScopes.map((scope => ({
      scope,
      items: itemsValues[scope],
      isCheckbox: Object.keys(selectedItemsValuesDeciders).includes(scope),
      selectedItem: itemsValues[scope]?.find(selectedValuesDeciders[scope] || (() => true)),
      selectedItems: itemsValues[scope]?.filter(selectedItemsValuesDeciders[scope] || (() => false)),
    })));
  }

  private _setFilterGroups() {
    this.groups = this._createFilterGroups();
    this._cdr.markForCheck();
  }
}
