<app-sidebar-layout
  #addOnDetailsLayout>
  <ng-container>
    <ng-template #header>
      <div class="sidebar-layout-header">
        <div class="sidebar-layout-header-text">
          <h2>{{currentAddOn.name}}</h2>
          <app-icon-button
            class="bs-right"
            stroked="iconInactive"
            buttonClass="close-button"
            (click)="addOnDetailsLayout.close()"
            name="close">
          </app-icon-button>
        </div>
      </div>
    </ng-template>
    <ng-template #content>
      <div class="sidebar-content sidebar-scroll-container">
        <div class="sidebar-carousel-images-container">
          <app-gallery
            [imagePopup]="false"
            [images]="images">
          </app-gallery>
        </div>
        <div class="custom-html-content"
          appTargetBlank
          [innerHTML]="currentAddOn.description | safe: 'html'">
        </div>
      </div>
    </ng-template>
    <ng-template #footer>
      @if(pricing){
      <div>
        <ng-container *ngTemplateOutlet="pricing">
        </ng-container>
      </div>
      }
    </ng-template>
  </ng-container>
</app-sidebar-layout>
