<ng-container *transloco="let t">
  <div class="flex-start gap-half">
    <app-icon size="big" name="home" stroked="formControl"></app-icon>

    @for (item of items; track $index) {
    <app-icon size="small" name="next" stroked="formControl"></app-icon>
    <span [ngClass]="itemClass"
      (click)="item.action && item.action()">
      @if(section){
      {{t(section + '.' + item.caption)}}
      }@else{
      {{t(item.caption)}}
      }
    </span>
    }
  </div>
</ng-container>