<ng-container *transloco="let t">
  <mat-toolbar class="main-header"
    [style.background-color]="resort?.resortId === 'acde1ce6-cf48-4569-af00-64848cd58d78' ? '#000000': ''">
    <div class="main-container">
      <!-- *ngIf="isOnSearchPage || isOnConfirmationPage" -->
      <!-- <div class="logo-row">
      <div class="resort-logo hidden-tablet-min flex">
        <ng-container
          [ngTemplateOutlet]="resortLogo">
        </ng-container>
      </div>
    </div> -->
      <div class="first-row">
        <div class="menu-button">
          <app-icon-button
            buttonClass="bc-transparent menu-button"
            iconSize="small"
            stroked="default"
            [transparent]="false"
            (click)="openProfileMenu()"
            name="menu">
          </app-icon-button>
        </div>
        <div class="selects-container flex">
          <div class="resort-logo-v2"
            (click)="toSearchV1()">
            <ng-container
              [ngTemplateOutlet]="resortLogo">
            </ng-container>
          </div>
        </div>
        <div class="guest-details">
          @if(profile){
          <div class="flex gap-quarter cursor-pointer hidden-tablet-max"
            (click)="openMember()">
            <app-info-label
              [text]="t('member.caption')"
              type="memberDefault">
            </app-info-label>
            &nbsp;
            <span class="color-default">{{memberName}}</span>
          </div>
          <div class="flex hidden-tablet-min">
            <app-icon-button
              (click)="openMember()"
              name="user"
              alt="user"
              size="default"
              stroked="textPrimary"
              filled="memberDefault">
            </app-icon-button>
          </div>
          }
          @else if(loyaltyEnabled && isOnSearchPage) {
          <span class="cursor-pointer color-default"
            (click)="openMember()">
            {{ t('member.signIn.button') }}
          </span>
          }
          @if(isOnSearchPage){

          <app-icon-button
            (click)="openGuestDetails()"
            name="users"
            alt="guests"
            type="raised"
            filled="textPrimary"
            buttonSize="small"
            stroked="default"
            [customButtonSize]="true"
            [transparent]="false"
            buttonClass="guest-detail-button border-less custom">
            <span class="guest-count">{{guestsCount}}</span>
          </app-icon-button>

          }
          @if(!profile && (isOnBookingPage || isOnConfirmationPage)){
          <app-icon-button
            name="lock"
            alt="guests"
            type="raised"
            filled="default"
            buttonSize="default"
            buttonClass="guest-detail-button border-less lock"
            [customButtonSize]="true"
            [transparent]="false">
          </app-icon-button>
          }
        </div>
      </div>
    </div>
  </mat-toolbar>
</ng-container>
<ng-template #resortLogo>
  <img loading="lazy" [src]="resortLogoImageURL" *ngIf="resortLogoImageURL" alt="Logo" />
</ng-template>