@if(isInitialized){

<div class="page-container">
  <div class="page-content page-narrow">
    <app-header-v2 class="page-default"></app-header-v2>
    <app-notification-booking
      [bookingId]="bookingId"
      [isBookingCancelled]="isBookingCancelled"
      [activeResortPhone]="activeResortPhone"
      class="page-section">
    </app-notification-booking>

    @if(bookingId)
    {
    <app-notification-passenger
      *ngIf="!isBookingCancelled"
      class="page-section hide-if-empty">
    </app-notification-passenger>
    <app-notification-failed-payment-part
      class="page-section hide-if-empty"
      iconName="warning"
      backgroundColor="warningBackground"
      textColor="warningText"
      borderColor="warningBorder">
    </app-notification-failed-payment-part>
    <app-notification-travel
      class="page-section"
      [safetyProtocols]="safetyProtocols">
    </app-notification-travel>
    <app-booking-email class="page-section"></app-booking-email>
    <app-booking-summary></app-booking-summary>
    <app-room-summary></app-room-summary>
    <app-flight-summary class="hide-if-empty"></app-flight-summary>
    <app-addon-summary class="hide-if-empty"></app-addon-summary>
    <app-protect-vacation-summary class="hide-if-empty"></app-protect-vacation-summary>
    <app-payment-summary></app-payment-summary>
    <app-agreement-conditions></app-agreement-conditions>
    <app-need-help></app-need-help>
    }
  </div>
  <app-footer></app-footer>
</div>
}
@else {
<app-loading-screen section="confirmation"></app-loading-screen>
}