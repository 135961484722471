<ng-container *transloco="let t">
  <div class="flex-column bc-default padding w-100">
    <app-bread-crumbs-v2
      class="padding-top"
      [items]="breadCrumbs"
      section="member.support">
    </app-bread-crumbs-v2>
    <div class="text-1-1-500 margin-vertical">
      {{t('member.support.description')}}
    </div>
    <app-form
      #form
      [form]="supportForm"
      scope="member.support">
      <ng-template #content let-text="text">
        <div class="flex-column">
          <ng-container *ngTemplateOutlet="text; context: {
            field: 'subject',
            disabled: isLoading || isSuccessSend
          }"></ng-container>
          <ng-container *ngTemplateOutlet="text; context: {
            field: 'body',
            type: 'textarea',
            disabled: isLoading || isSuccessSend
          }"></ng-container>
        </div>
      </ng-template>
    </app-form>

    <div class="flex-end gap margin-vertical flex-wrap">
      @if (errorMessage) {
      <div class="flex-start error-message margin-right-auto">
        {{errorMessage}}
      </div>
      }
      @if (isSuccessSend) {
      <div class="flex-start color-selected fw-600 break-all margin-right-auto">
        {{t('member.support.success')}}
      </div>
      }
      <!-- <button mat-raised-button
        [disabled]="isLoading"
        class="select-button"
        (click)="close()">
        {{t('button.cancel')}}
      </button> -->
      <button mat-raised-button
        [disabled]=" isLoading || isSuccessSend"
        color="primary"
        class="select-button"
        (click)="send()">
        @if (isLoading) {
        <app-loading-icon size="button"></app-loading-icon>
        &nbsp;
        }
        @if (isSuccessSend) {
        {{t('member.support.sent')}}
        }
        @else {
        {{t('button.send')}}
        }
      </button>
    </div>
  </div>
</ng-container>