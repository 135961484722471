import Logger from 'js-logger';
import { AppEnvironment } from './environment-type';

export const environment: AppEnvironment = {
  baseUrl: 'https://booking.uat.c-breez.com',
  baseApiUrl: 'https://booking-api.uat.c-breez.com',
  productUrl: 'https://c-breez.com',

  production: true,
  isTest: true,
  minLogLevel: Logger.INFO,

  tracking: {
    enabled: true,
  },

  tenants: {
    'test': {
      loyalty: true,
      membershipProvider: 'cendyn',
      abandonedCart: true,
      smartyApiKey: '153588772584256836',
      logo: 'https://2486634c787a971a3554-d983ce57e4c84901daded0f67d5a004f.ssl.cf1.rackcdn.com/couples-resorts/media/couples-horiz-resorts_gray_80k-6410835b58b2f.png',
      priceFormat: {
        'main': 'prpn',
        'button': 'totalRounded',
        'mainFlights': 'totalRounded'
      }
    },
    'sunset': {
      loyalty: true,
      abandonedCart: true,
      secondarySpecialOffersView: true,
      smartyApiKey: '153588772584256836',
      logo: 'https://thepalmsjamaica.com/images/Sunset-at-the-plams_negril_dec_2019_black-v2.png',
    }
  }
};
