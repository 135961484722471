<ng-container *transloco="let t">
  <div class="booking-button">
    <app-icon-button
      name="lock"
      alt="cart.button.book"
      filled="default"
      [customButtonSize]="true"
      (click)="startBooking()"
      [transparent]="false"
      iconSize="big"
      buttonClass="select-button active w-100"
      type="raised">
      <div class="div">
        {{t('cart.button.book')}}
      </div>
    </app-icon-button>
    <app-payment-cards></app-payment-cards>
    @if(memberId){
    <app-member-info-v2></app-member-info-v2>
    }
    @else if(loyaltyEnabled) {
    <div class="text-0_875-1-400 flex">
      {{t('cart.subheader.loyalty')}}
    </div>
    }
  </div>
</ng-container>
