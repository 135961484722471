import { Component, ElementRef, ViewChild } from '@angular/core';
import { Package, RatePlanType, Resort, RoomType } from '@shared/api/be-api.generated';
import { ComponentBase } from '@shared/base/component.base';
import { createCarouselImages, scrollHorizontally } from '@shared/common';
import { RecommendationBarSettings } from '@shared/components-v2/layout/recommendation-bar/recommendation-bar.component';
import { WithId } from '@shared/models/common';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { MemberService } from '@member/member.service';
import { PackageService } from '@shared/services/package.service';
import { SelectOptionConfig } from '@search/components-v2/shared/select-option/select-option.component';
import { PricingDetailsConfig } from '@search/components-v2/shared/pricing-details/pricing-details.component';

@Component({
  selector: 'app-room-select',
  templateUrl: './room-select.component.html',
  styleUrls: ['./room-select.component.scss'],
})
export class RoomSelectComponent extends ComponentBase {
  @ViewChild('options') options!: ElementRef;
  readonly memberBannerIndex = 1;
  profile = this._member.profile;
  loyaltyEnabled = this.services.tenant.loyaltyEnabled;
  recommendation?: RecommendationBarSettings;
  resort?: Resort;
  ratePlanType?: RatePlanType;
  selectConfigs: SelectOptionConfig[] = [];
  all: Record<string, WithId<{ roomType: RoomType, package?: WithId<Package> }>> = {};
  pricingConfigs: Record<string, PricingDetailsConfig> = {};
  priceFormat = this.services.tenant.priceFormat.main;

  constructor(
    private readonly _package: PackageService,
    private readonly _member: MemberService,
    services: ComponentBaseService
  ) {
    super(services);
    this.setRecommendationBarSettings()

    super.pushSub(
      this._package.setWhenDataChanged(() => this.update()),
      this._member.onMemberChanged(({ profile }) => {
        this.profile = profile;
        this.setRecommendationBarSettings();
      })
    );
  }

  scroll(toLeft: boolean) {
    scrollHorizontally(toLeft, this.options, Object.keys(this.all).length);
  }

  setRecommendationBarSettings() {
    this.recommendation = this.isOnSearchPageV2 && this._package.selectedPackage && this.loyaltyEnabled ? {
      backgroundColor: this.profile ? 'memberDefault' : 'selected',
      textColor: this.profile ? 'textPrimary' : 'default',
      textKey: 'package.bestOffer'
    } : undefined;
  }

  update() {
    const { roomTypesPackagesOptions, resort, unavailabilityReason, ratePlanType } = this._package;
    if (this.resort?.resortId !== resort?.resortId
      || this.ratePlanType?.ratePlanTypeId !== ratePlanType?.ratePlanTypeId) {
      this.resort = resort;
      this.ratePlanType = ratePlanType;
      this.selectConfigs = [];
      this.pricingConfigs = {};
    }

    this.all = roomTypesPackagesOptions
      .reduce((result, item) => ({ ...result, [item.roomType.roomTypeId || '']: item }), {});

    Object.values(this.all).forEach(item => {
      const id = item.roomType?.roomTypeId;
      if (!id) { return; }

      //#region select
      const roomType = item.roomType;
      let selectConfig = this.selectConfigs.find(item => item.id === roomType.roomTypeId);
      if (!selectConfig) {
        selectConfig = new SelectOptionConfig(id, roomType.caption, roomType.description, createCarouselImages(roomType))
        this.selectConfigs.push(selectConfig);
      }
      selectConfig.disabled = !item.package;
      selectConfig.active = this._package.isActiveRoomType(roomType) && !!item.package;
      //#endregion

      //#region pricing
      let pricingConfig = this.pricingConfigs[id];
      if (!pricingConfig && roomType.roomTypeId) {
        pricingConfig = new PricingDetailsConfig(id);
        this.pricingConfigs[roomType.roomTypeId] = pricingConfig;
      }
      pricingConfig.active = this._package.roomType?.roomTypeId === id;
      pricingConfig.disabled = !!unavailabilityReason || !item.package;
      pricingConfig.blocked = pricingConfig.active;
      //#endregion
    });

    this.setRecommendationBarSettings()
  }

  onSelectClick(item: { roomType: RoomType, package?: WithId<Package> }) {
    if (item.roomType && item.package) {
      item.roomType.roomTypeId === this._package.roomType?.roomTypeId
        ? this._package.selectPackage(item.package)
        : this._package.selectRoomType(item.roomType);
    }
  }
}
