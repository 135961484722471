import { NgModule } from '@angular/core';

import { JoinPipe } from './join.pipe';
import { SafePipe } from './safe.pipe';
import { NightsPipe } from './nights.pipe';
import { AirportPipe } from './airport.pipe';
import { ImagePathPipe } from './image.pipe';
import { AppDatePipe } from './app-date.pipe';
import { IconPathPipe } from './icon-path.pipe';
import { PosistionPipe } from './position.pipe';
import { CabinTypePipe } from './cabin-type.pipe';
import { AddOnTypePipe } from './addon-type.pipe';
import { FlightTimePipe } from './flight-time.pipe';
import { AppCurrencyPipe } from './app-currency.pipe';
import { FormatPricePipe } from './format-price.pipe';
import { CreateTrackByPipe } from './create-track-by.pipe';
import { FlightDurationPipe } from './flight-duration.pipe';
import { PackageDiscountPipe } from './package-discount.pipe';
import { IsMembershipEnabledPipe } from './is-membership-enabled.pipe';
import { SpecialOfferDiscountPipe } from './special-offer-discount.pipe';
import { IsFormControlRequiredPipe } from './is-form-control-required.pipe';
import { TranslateFormComponentTextPipe } from './translate-form-component-text.pipe';

const pipes = [
  JoinPipe,
  SafePipe,
  NightsPipe,
  AirportPipe,
  AppDatePipe,
  IconPathPipe,
  PosistionPipe,
  CabinTypePipe,
  AddOnTypePipe,
  ImagePathPipe,
  FlightTimePipe,
  AppCurrencyPipe,
  FormatPricePipe,
  CreateTrackByPipe,
  FlightDurationPipe,
  PackageDiscountPipe,
  IsMembershipEnabledPipe,
  SpecialOfferDiscountPipe,
  IsFormControlRequiredPipe,
  TranslateFormComponentTextPipe
];

@NgModule({
  declarations: pipes,
  providers: pipes,
  exports: pipes
})
export class PipeModule { }
