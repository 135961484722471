import { TemplateRef } from '@angular/core';
import { Color, PropType, SidebarPanelWidth, SidebarPosition } from '@shared/models/common';
import { createMachine } from 'xstate';
import { machines } from '../state/state.mapping';

export interface SidebarContext {
  position: SidebarPosition;
  template: TemplateRef<unknown>;
  width: SidebarPanelWidth;
  backgroundColor: Color;
}

type SidebarEvents = { type: 'OPEN_TEMPLATE' } & Partial<SidebarContext>
  | { type: 'UPDATE_TEMPLATE' } & Partial<SidebarContext>
  | { type: 'ClOSE_TEMPLATE' };

export type SidebarEventsType = PropType<SidebarEvents, 'type'>;
export type SidebarTemplates = Partial<Record<'account' | 'cart' | 'hotelFilter' | 'member', TemplateRef<unknown>>>

const sidebarSM = createMachine({
  predictableActionArguments: false,
  id: machines.sidebar.id,
  tsTypes: {} as import('./sidebar.sm.typegen').Typegen0,
  schema: {
    events: {} as SidebarEvents,
    context: {} as SidebarContext
  },
  context: {} as SidebarContext,
  initial: 'idle',
  states: {
    idle: {
      on: {
        OPEN_TEMPLATE: {
          target: 'opened',
          actions: ['openTemplate', 'openOverlay']
        }
      }
    },
    opened: {
      on: {
        ClOSE_TEMPLATE: {
          target: 'idle',
          actions: ['closeTemplate', 'closeOverlay']
        },
        UPDATE_TEMPLATE: {
          target: 'opened',
          actions: ['updateTemplate', 'openOverlay']
        }
      }
    },
  }
});

export default sidebarSM;
