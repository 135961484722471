<ng-container *transloco="let t">
  <div class="payment-plan"
    *ngIf="paymentPolicies?.length">
    <div class="section-header">
      <div class="section-header-button">
        <app-icon
          name="lock"
          alt="C-Breez"
          filled="textPrimary">
        </app-icon>
      </div>
      <div class="section-header-text">
        {{t('paymentPlan.section.header')}}
        <app-anchor type="paymentPlanError"></app-anchor>
      </div>
      <app-section-toggler #toggler></app-section-toggler>
      <div class="section-header-buttons-container hidden-desktop-max">
        <app-payment-cards></app-payment-cards>
      </div>
    </div>
    <div class="collapsable"
      [class.collapsed]="toggler.isHidden">
      <div class="section-subheader wrap">
        <div class="section-subheader-text"
          *ngIf="!isTouched || activePolicy; else error">
          <b>{{t('paymentPlan.section.subheader1')}}</b>
        </div>
        <ng-template #error>
          <div class="section-subheader-text">
            <mat-error class="error-message bold">
              {{t('paymentPlan.error.required')}}
            </mat-error>
          </div>
        </ng-template>
        <div class="section-subheader-text">
          {{t('paymentPlan.section.subheader2')}}
        </div>
      </div>
      <div class="payment-plan-content">
        <div class="payment-plan-policy cursor-pointer"
          [class.bc-optionBackground]="policy.paymentPolicyId === activePolicy?.paymentPolicyId"
          *ngFor="let policy of paymentPolicies; trackBy: 'paymentPolicyId' | createTrackBy"
          (click)="select(policy)">
          <div class="policy-header"
            [class.bc-background]="policy.paymentPolicyId !== activePolicy?.paymentPolicyId">
            <app-icon
              name="checked"
              size="default"
              [class.active]="policy.paymentPolicyId === activePolicy?.paymentPolicyId"
              class="checkbox-icon">
            </app-icon>
            {{policy.name}}
          </div>
          <div class="policy-description collapsable"
            [class.collapsed]="policy.paymentPolicyId !== activePolicy?.paymentPolicyId">
            <div class="text block-description">
              {{policy.description}}
            </div>
            <div class="price-breakdown">
              <div class="breakdown-header bc-background">
                <div *ngFor="let item of ['name', 'date', 'amount', 'outstanding']"
                  [ngClass]="item">
                  {{t('paymentPlan.priceBreakdown.' + item)}}
                </div>
              </div>
              <div class="breakdown-row bc-default"
                *ngFor="let item of scheduleItems; trackBy: 'number' | createTrackBy">
                <div class="name">{{item.name}}</div>
                <div class="date">{{item.date | appDate: 'type2'}}</div>
                <div class="amount">{{item.amount | appCurrency}}</div>
                <div class="outstanding">{{item.outstandingAmount | appCurrency}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="allowed-cards hidden-desktop-min">
          <div>
            <div class="header">
              {{t('paymentPlan.label.card')}}
            </div>
            <app-payment-cards></app-payment-cards>
          </div>
          <div class="payment-note">
            {{t('paymentPlan.label.paymentNote')}}
          </div>
        </div>
        <div class="payment-plan-policy-cancellation"
          *ngIf="cancellationPolicy">
          <div class="policy-header">
            {{cancellationPolicy.name}}
          </div>
          <div class="policy-descripton block-description">
            {{cancellationPolicy.description}}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>