<ng-container *transloco="let t">
  <div class="flex-column flex-1 full-size">
    <div class="flex-1">
      <app-form
        #form
        (nextChanged)="apply()"
        [form]="promoCodeForm"
        scope="promoCode">
        <ng-template #content let-text="text">
          <ng-container *ngTemplateOutlet="text; context: {
            field: 'value'
          }"></ng-container>
        </ng-template>
      </app-form>
    </div>
    <div class="bc-default flex-end gap">
      <button mat-raised-button
        (click)="cancel()">
        {{t('button.cancel')}}
      </button>
      <button mat-raised-button
        color="primary"
        (click)="apply()">
        {{t('button.apply')}}
      </button>
    </div>
  </div>
</ng-container>