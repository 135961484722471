import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { NonMonetaryDiscount } from '@shared/api/be-api.generated';
import { ComponentBase } from '@shared/base/component.base';

@Component({
  selector: 'app-non-monetary-v2',
  templateUrl: './non-monetary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NonMonetaryV2Component extends ComponentBase {
  @HostBinding('class.hide-if-empty') hideIfempty = true;
  @Input() nonMonetary: NonMonetaryDiscount[] = [];
}
