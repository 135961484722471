<ng-container *transloco="let t">
  <div class="flex-column bc-default padding border-top text-1-1_5-600">
    <div class="flex margin-vertical-half text-center">
      <app-icon
        name="lock"
        alt="C-Breez"
        filled="textPrimary">
      </app-icon>
      &nbsp;
      <span [innerHtml]="t('footer.top')"></span>
    </div>
    <div class="flex margin-vertical-half flex-column-mobile-max text-center text-0_875-1_25-500">
      <span [innerHtml]="t('footer.middle')"></span>
      <a href="https://www.c-breez.com/"
        aria-label="C-Breez"
        appTargetBlank>
        <app-icon
          size="custom"
          [height]="1"
          scope="images"
          name="logo"
          alt="C-Breez">
        </app-icon>
      </a>
    </div>
    <!-- <div class="footer-middle">
      &nbsp;
      <a href="https://www.c-breez.com/privacy-policy"
        class="link-underlined"
        [attr.aria-label]="t('footer.privacy')"
        appTargetBlank>
        {{t('footer.privacy')}}
      </a>
    </div> -->
    <div class="flex margin-vertical-half text-center text-0_75-1_25-500 flex-wrap"
      [innerHtml]="t('footer.bottom', { value: currentYear })">
    </div>
  </div>
</ng-container>