import { Component } from '@angular/core';
import { cartPriceChange } from '@shared/consts/animations';
import { AnimationComponentBase } from '@shared/base/animation-component.base';
import { PricingService } from '@shared/services/pricing.service';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { TenantService } from '@shared/services/tenant.service';
import { AddOnService } from '@shared/services/add-on.service';

@Component({
  selector: 'app-cart-total',
  templateUrl: './cart-total.component.html',
  animations: [cartPriceChange]
})
export class CartTotalComponent extends AnimationComponentBase {
  total = 0;
  saved = 0;
  memberSaved = 0;

  isCouplesTenant = this._tenant.isCouplesTenant;
  isTripProtection = this._addon.isTripProtectionPurchased;

  constructor(
    private readonly _addon: AddOnService,
    private readonly _pricing: PricingService,
    private readonly _tenant: TenantService,
    services: ComponentBaseService
  ) {
    super(services);
    super.pushSub(this._pricing.setWhenDataChanged(({ saved, total, memberSaved }) => {
      this.saved = saved;
      this.total = total;
      this.memberSaved = memberSaved;
      this.isCouplesTenant = this._tenant.isCouplesTenant;
      this.isTripProtection = this._addon.isTripProtectionPurchased;

      this._animate();
    }));
  }
}
