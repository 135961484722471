<ng-container *transloco="let t">
  <div class="special-offer"
    [class.is-selected]="isSelected">
    <div class="special-offer-text">
      <div class="flex-start-start gap-half">
        @if(discount){
        <div class="discount-value no-wrap">{{discount}}</div>
        }
        <span>{{name}}</span>
      </div>
      <!-- <span *ngIf="currentSpecialOffer?.notes && isVisibleNotes">*</span> -->
      <app-info-label
        [text]="t('package.nonRefundable')"
        type="highlighted"
        *ngIf="isNonRefundable">
      </app-info-label>
    </div>
    <div class="special-offer-notes"
      #notesContainer
      [class.expanded]="isNotesExpanded"
      *ngIf="isVisibleNotes && notes">
      {{notes}}
    </div>
    <div class="special-offer-notes-toggler link"
      *ngIf="isVisibleNotes && notes"
      (click)="toggleIsExpandedNotes($event)">
      {{t('button.moreDetails')}}
    </div>
    <div class="special-offer-notes-toggler link"
      *ngIf="isNotesExpanded"
      (click)="toggleIsExpandedNotes($event)">
      {{t('button.readLess')}}
    </div>
  </div>
</ng-container>