import { BookingResponse, Country, MemberLevelBenefit, MembershipLevel, TravelCompanion } from '@shared/api/be-api.generated';

export class MemberInfo {
  travellers?: TravelCompanion[]
  bookings?: BookingResponse[];
  countries?: Country[]; // can be shared
  levels?: MembershipLevel[]; // can be shared
  benefits?: MemberLevelBenefit[];// can be shared

  authChanged() {
    this.travellers = undefined;
    this.bookings = undefined;
  }
}
