import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { DateTime, Interval } from 'luxon';

import { DataService } from './data.service';
import { SearchService } from './search/search.service';
import { BookingEngineClient, NullableDatePeriod } from '@shared/api/be-api.generated';
import { BookingEngineHtmlContent, BookingEngineHtmlContentCode, IBookingEngineHtmlContent } from '@shared/api/be-api.generated';

@Injectable({
  providedIn: 'root'
})
export class HtmlContentService {
  private _content: BookingEngineHtmlContentView[] = [];
  private _allLoaded = false;

  constructor(
    private readonly _data: DataService,
    private readonly _search: SearchService,
    private readonly _apiClient: BookingEngineClient,
  ) {
    this._data.setWhenInitialized(({ settings }) => this._update(settings.htmlContent));
  }

  get({ code, onLoaded, skipFullLoad, position, filter }: GetHtmlContentInput) {
    return this._search.setWhenContextChanged(({ resort, fromDate, toDate }) => {
      const getApplicable = () => {
        const currentFilter = filter || {
          resortsIds: [resort?.resortId || ''],
          travelPeriod: new NullableDatePeriod({ start: fromDate, finish: toDate }),
        };

        currentFilter.code = code;
        currentFilter.position = position || currentFilter.position;

        return this._content.filter(item => item.isApplicable(currentFilter)).map(item => item.stringContent);
      }

      if (skipFullLoad || this._allLoaded) {
        onLoaded(getApplicable());
      }
      else {
        this._apiClient.getHtmlContent(this._data.tenant.id).pipe(catchError(() => [])).subscribe(result => {
          this._update(result);
          onLoaded(getApplicable());
        });
      }
    });
  }

  //#region private
  private _update(htmlContent?: BookingEngineHtmlContent[]) {
    htmlContent?.forEach(newItem => {
      const currentItem = this._content.find(item =>
        item.code === newItem.code && item.position === newItem.position);
      if (currentItem) {
        currentItem.content = newItem.content;
      } else {
        this._content.push(new BookingEngineHtmlContentView(newItem));
      }
    });
  }
  //#endregion
}

export type GetHtmlContentInput = {
  code: BookingEngineHtmlContentCode;
  skipFullLoad?: boolean; // skip additional load, and search only in initial set
  onLoaded: (content: string[]) => void;
  position?: number
  filter?: IBookingEngineHtmlContent
};


export class BookingEngineHtmlContentView extends BookingEngineHtmlContent {
  constructor(item: BookingEngineHtmlContent) {
    super(item);
  }

  isApplicable({ code, travelPeriod, resortsIds, position }: IBookingEngineHtmlContent = {}) {
    const currentStart = travelPeriod?.start || DateTime.now();
    const currentFinish = travelPeriod?.finish || DateTime.now();

    const allowed = Interval.fromDateTimes(this.travelPeriod?.start || currentStart, this.travelPeriod?.finish || currentFinish);
    const current = Interval.fromDateTimes(currentStart, currentFinish);

    return this.code === code &&
      (!position || this.position === position) &&
      allowed.intersection(current)?.isValid &&
      (!this.resortsIds?.length || !resortsIds?.length || this.resortsIds.some(itemId => resortsIds.some(id => id === itemId)));
  }

  get stringContent() {
    return this.content || '';
  }
}
