import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BookingService } from '@booking/services/booking.service';
import { createFormControl } from '@shared/common';
import { FormComponent } from '@shared/components/common/form/form.component';
import { ReservationVerificationSectionBase } from '@shared/base/reservation-verification-section.base';
import { SidebarService } from '@shared/services/sidebar/sidebar.service';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { TenantService } from '@shared/services/tenant.service';

const fields = ['email', 'loyaltyId'] as const;
type Field = typeof fields[number];

@Component({
  selector: 'app-manage-booking',
  templateUrl: './manage-booking.component.html'
})
export class ManageBookingComponent extends ReservationVerificationSectionBase<Field> {
  @ViewChild('form') fc!: FormComponent<Field>;
  manageBookingForm = new UntypedFormGroup({
    email: createFormControl(this.cartRequest?.email, Validators.email),
    loyaltyId: new UntypedFormControl(this.cartRequest?.loyaltyId)
  } as Record<Field, UntypedFormControl>);

  anchorId = this.getAnchorId('manageBookingError');
  get isValid() {
    return this.manageBookingForm.valid;
  }

  isVisibleLoyalty = this._tenant.settings.loyalty;

  constructor(
    private readonly _tenant: TenantService,
    private readonly _sidebar: SidebarService,
    _booking: BookingService,
    services: ComponentBaseService
  ) {
    super('manageBooking', _booking, services);
    this.executeTest(() => this.fc.set.email('fake@email.fake'));
  }

  protected override afterViewInit() {
    this.updateReservation();
  }

  apply() {
    this.scrollTo('reviewBooking');
  }

  openAccount() {
    this._sidebar.openAccount();
  }

  updateReservation() {
    if (this.manageBookingForm.valid) {
      const email = this.fc.get.email<string>();
      const loyaltyId = this.isVisibleLoyalty ? this.fc.get.loyaltyId<string>() : undefined;
      this._booking.reservationInput.setLoyalty(email, loyaltyId);
    }
  }

  //#region base
  protected override getFormsComponents(): FormComponent<Field>[] {
    return [this.fc];
  }

  protected override afterFormValueChanged() {
    this.updateReservation();
  }

  protected override verificationStarted() {
    this.updateReservation();
    this.fc.form.markAllAsTouched();
  }
  //#endregion
}
