<ng-container *transloco="let t">
  <div class="centered">
    <app-icon-button
      name="search"
      iconSize="big"
      [customButtonSize]="true"
      filled="default"
      stroked="selected"
      (click)="reload()">
    </app-icon-button>
    <div class="section-header">
      <div class="section-header-text text-center">
        {{t('error.header')}}
      </div>
    </div>
    <div class="section-subheader">
      {{t('error.subheader')}}
    </div>
    <button mat-raised-button
      class="padding-horizontal"
      color="primary"
      (click)="reload()">
      {{t('error.button')}}
    </button>
  </div>
</ng-container>