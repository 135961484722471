import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Package, RatePlanType, RoomType } from '@shared/api/be-api.generated';
import { packagePriceChange } from '@shared/consts/animations';
import { AnimationComponentBase } from '@shared/base/animation-component.base';
import { PriceFormat, Size, UnavailabilityReason, WithId, defaultPriceFormat } from '@shared/models/common';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { QueryParamsService } from '@shared/services/query-params.service';
import { FormatPricePipe } from 'src/app/pipes/format-price.pipe';
import { SearchService } from '@shared/services/search/search.service';
import { PackageService } from '@shared/services/package.service';

@Component({
  selector: 'app-package-pricing',
  templateUrl: './package-pricing.component.html',
  styleUrls: ['./package-pricing.component.scss'],
  animations: [packagePriceChange]
})
export class PackagePricingComponent extends AnimationComponentBase implements OnChanges {
  @Input() currentPackage?: WithId<Package>;
  @Input() currentRoomType?: RoomType;

  @Input() size: Size = 'default';
  @Input() priceFormat?: PriceFormat;
  @Input() isActive!: boolean;
  @Input() hideWhenUnavailable = true;

  ratePlanType?: RatePlanType;
  periodNights!: number;
  guestsCount!: number;
  roomsCount!: number;

  basePrice!: number;
  finalPrice!: number;
  memberDiscount?: number;

  currentPriceFormat!: PriceFormat;
  notDisplayFormats: PriceFormat[] = ['total', 'totalRounded'];

  isLoading = false;
  unavailabilityReason?: UnavailabilityReason;

  constructor(
    private readonly _formatPrice: FormatPricePipe,
    private readonly _queryParams: QueryParamsService,
    private readonly _package: PackageService,
    services: ComponentBaseService,
    search: SearchService,
  ) {
    super(services);
    this._handleChangePriceFormat();
    this.pushSub(
      search.loading$.subscribe(isLoading => this.isLoading = isLoading),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isActive = changes['isActive']
    const hasBecomeActive = isActive?.previousValue !== undefined
      && isActive.previousValue !== isActive.currentValue
      && isActive.currentValue;

    const currentPackage = changes['currentPackage'];
    const isPriceChanged = !!currentPackage?.previousValue
      && !!currentPackage.currentValue?.finalPrice
      && currentPackage.currentValue?.finalPrice !== currentPackage.previousValue?.finalPrice;

    this.unavailabilityReason = this._package.getUnavailabilityReason(this.currentRoomType?.roomTypeId);

    this.ratePlanType = this._package.ratePlanType;
    this.periodNights = this._package.periodNights;
    this.roomsCount = this._package.roomsCount;
    this.guestsCount = this._package.guestsCount;

    this._setPriceFormat();

    if (isPriceChanged || hasBecomeActive) {
      this._animate();
    }
  }

  private _handleChangePriceFormat() {
    this._queryParams.routeChanged.subscribe(() => this._setPriceFormat());
  }

  private _setPriceFormat() {

    this.currentPriceFormat =
      (this.services.tenant.isTestTenant && this._queryParams.value.priceFormat) // first from query
      || (!this.ratePlanType?.flightsIncluded && this.priceFormat) // from settings, for now, flights will be always total
      || (this.ratePlanType?.flightsIncluded && this.services.tenant.priceFormat.mainFlights) // rounded for flights
      || defaultPriceFormat; // or default

    if (this.currentPackage) {
      const { finalPrice = 0, basePrice = 0, memberDiscount = 0 } = this.currentPackage;
      const { finalPricePerRoomPerNight = 0, basePricePerRoomPerNight = 0, memberDiscountPerRoomPerNight = 0 } = this.currentPackage;

      const setPriceByPricingPipe = () => {
        this.basePrice = this._formatPrice
          .getPriceByFormat(basePrice, this.periodNights, this.guestsCount, this.roomsCount, this.currentPriceFormat);

        this.finalPrice = this._formatPrice
          .getPriceByFormat(finalPrice, this.periodNights, this.guestsCount, this.roomsCount, this.currentPriceFormat);

        this.memberDiscount = Math.ceil(memberDiscount || 0);
      }

      switch (this.currentPriceFormat) {
        case 'prpn':
          if (basePricePerRoomPerNight) {
            this.basePrice = Math.ceil(basePricePerRoomPerNight || 0);
            this.finalPrice = Math.ceil(finalPricePerRoomPerNight || 0);
            this.memberDiscount = Math.ceil(memberDiscountPerRoomPerNight || 0);
          } else {
            setPriceByPricingPipe();
          }
          break;
        default:
          setPriceByPricingPipe();
          break;
      }
    } else {
      this.finalPrice = 0;
      this.basePrice = 0;
      this.memberDiscount = 0;
    }
  }
}
