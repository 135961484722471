import { Component, ContentChild, Input, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormComponent } from '@shared/components/common/form/form.component';
import { MemberService } from '@member/member.service';
import { TenantService } from '@shared/services/tenant.service';
import { MemberOptionKey } from '../member-start/member-start.component';
import { ApiException } from '@shared/api/be-api.generated';

@Component({
  selector: 'app-member-signin-v2',
  templateUrl: './member-signin.component.html'
})
export class MemberSignInV2Component {
  constructor(
    private readonly _tenant: TenantService,
    private readonly _member: MemberService,
  ) { }

  @Input() selectTab?: (option: MemberOptionKey) => void;
  @ViewChild('form') form!: FormComponent<'email' | 'password'>;
  formGroup = new FormGroup({
    email: new FormControl<string | null | undefined>(this._tenant.defaultMember?.email, [Validators.required, Validators.email]),
    password: new FormControl<string | null | undefined>(this._tenant.defaultMember?.password, [Validators.required, Validators.minLength(8)]),
  });

  @ContentChild('rewardBullets') rewardBullets!: TemplateRef<unknown>;

  successMessage?: string;
  errorMessage?: string;
  isLoading = false;
  isResetPassword = false;
  isForgotPassword = false;

  cendynLoginErrors = ['WrongPassword', 'UserNotExist'];

  signIn() {
    if (this.isLoading || !this.formGroup.valid) {
      return;
    }

    this.setLoading({ loading: true });

    this._member.signIn(this.form.get.email<string>().trim(), this.form.get.password()).subscribe({
      error: (error: ApiException) => this.setLoading({
        error: this._member.getMembershipError(error, {
          'member.signIn.error.loginOrPassword': response =>
            this.cendynLoginErrors.some(item => response.indexOf(item) > -1)
        })
      })
    });
  }

  openRegister() {
    this.selectTab && this.selectTab('register');
  }

  toggleForgotPassword() {
    this.isForgotPassword = !this.isForgotPassword;
  }

  forgotPassword() {
    if (this.isLoading || !this.formGroup.controls.email.valid) {
      return;
    }

    this.setLoading({ loading: true, resetPassword: true })
    this.formGroup.markAsUntouched();

    this._member.forgotPassword(this.form.get.email<string>().trim()).subscribe({
      error: (error: ApiException) => this.setLoading({
        error: this._member.getMembershipError(error)
      }),
      next: () => this.setLoading({
        success: 'member.forgotPassword.succcess'
      })
    });
  }

  setLoading(data: { loading?: boolean, resetPassword?: boolean, error?: string, success?: string }) {
    this.isLoading = !!data.loading;
    this.errorMessage = data.error;
    this.successMessage = data.success;
    this.isResetPassword = !!data.resetPassword;
  }
}
